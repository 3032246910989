
<div class = "notifications" style="position: fixed; background: black; display: flex; border: 1px solid white;border-radius:5px">
	<b-card class="card mb-3" style="background: black; ; " body-class="scroll-wrap">
		<template #header><code class="mb-0" style="font-size:20px;">Notifications</code></template>
		<div style="width: 800px">
			<b-card v-if="notifications.length===0"  class="card-container">
				<code>
					No notifications to display
				</code>
			</b-card>
			<div v-else v-for="(notification, index) in notifications" v-bind:key="index"  class="card-container">
				<b-card>
					<template #header id="statusHeader">
						<code style="color: white;">Message</code>
						<i class="far fa-times fa-1x" style="float:right; margin-left:10px; color: #E93820" @click="notifications=notifications.filter((r, index_)=> index_ !== index)"></i>
					</template>
					<code style="color: white;">
						{{notification.data}}
					</code>
				</b-card>
			</div>
		</div>
	</b-card>
</div>
