import * as Sentry from "@sentry/vue";

export default({ app, router }) => {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false, blockAllMedia: false }),
        ],
        trackComponents: true,
        tracesSampleRate: 0.1,

        // Capture Replay for all sessions
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
}