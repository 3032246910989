
<div style="margin-top: 50%;">
	<div class="card border-secondary mb-3" style="width: 100%; height: 100%;">
		<div class="card-body">
			<h5 class="card-title" >Sign in to your account</h5>
			<form class="login" @submit.prevent="login">
				<input required v-model="email" type="email" placeholder="Email" class="form-control"/>
				<input required v-model="password" type="password" placeholder="Password" :class="getPasswordClass()"/>
				<div v-if="!isCorrect" class="invalid-feedback">Sorry, password or email is incorrect</div>
				<button @click="login()" type="submit" class="btn btn-outline-info btn-sm">Login</button>
			</form>
		</div>
	</div>
</div>
