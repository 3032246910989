
<div>
	<slot :Confirm="RequestConfirmation" :awaiting="awaiting" :context="context"></slot>

	<b-popover
		ref="popover"
		:target="ref"
		:show.sync="visible"
		triggers="manual"
		:title="title"
		:placement="placement"
		boundary="viewport"
		no-fade
	>
		{{ warning }}

		<div class="d-flex mt-2">
			<!-- Confirm -->
			<b-button size="sm" variant="outline-warning" class="mr-1" @click="Confirm">
				<span>Confirm</span>
			</b-button>

			<!-- Confirm -->
			<b-button size="sm" variant="outline-success" @click="Deny">
				<span>Cancel</span>
			</b-button>
		</div>
	</b-popover>
</div>
