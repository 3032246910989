<template>
<div id="app">
	<div class="navbar navbar-expand-lg navbar-dark bg-dark">
		<div class="container">
			<div style="display: flex; flex-direction: column; height:42px;width:100px;">
				<a href="/dashboard" class="navbar-brand" style="margin-left:-10px;">
					Supervisor
				</a>
				<div  style="margin-top: -10px">
					<loading-progress
						v-if="isLoading"
						:indeterminate="true"
						:counter-clockwise="true"
						:hide-background="true"
						fillDuration="1"
						style="height: 0px; width:0px; margin-top:-200px;"
						size="100"
					/>
				</div>
			</div>
			<button
				class="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarResponsive"
				aria-controls="navbarResponsive"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon" />
			</button>

			<div id="navbarResponsive" class="collapse navbar-collapse">
				<ul v-if="isLoggedIn" class="navbar-nav">
					<li class="nav-item">
						<span class="nav-link">
							<b>Welcome {{this.username}}!</b>
						</span>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/dashboard">Dashboard</router-link>
					</li>
					<li class="nav-item">
						<router-link v-if="(isAdmin) || (isChecker)" class="nav-link" to="/scatter">ReID</router-link>
					</li>
					<li class="nav-item">
						<router-link v-if="isAdmin" class="nav-link" to="/reidreview">Review</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/playground">Playground</router-link>
					</li>
					
					<li class="nav-item">
						<span class="nav-link" @click="logout">Logout</span>
					</li>
				</ul>
			</div>
		</div>
		<div class = "container" style="display: flex; flex-direction: row; justify-content:flex-end;" >
			<div id="navbox" :class="{updated: newNotification}" @click="toggleNotifications=!toggleNotifications;toggledNotificationType='Notifications';newNotification=false"
				style="display: flex;flex-direction: row;height: 42px;width: 40px;justify-items: center; user-select: none;">
				<div class="collapse navbar-collapse" style="margin-left: 12px">
					<ul v-if="isLoggedIn" class="navbar-nav">
						<li class="nav-item" >
							<i id="notifications" class = "far fa-bell fa-1x" style="color:grey;"></i>
						</li>
					</ul>
				</div>
			</div>
			<div
				id="navbox"
				@click="toggleNotifications=!toggleNotifications;toggledNotificationType='Jobs';"
				style="display: flex;flex-direction: row;height: 42px;width: 40px;justify-items: center; user-select: none;"
				v-if="organisation==='black'"
			>
				<div class="collapse navbar-collapse" style="margin-left: 12px">
					<ul v-if="isLoggedIn" class="navbar-nav">
						<li class="nav-item" >
							<i id="notifications" class = "far fa-running fa-1x" style="color:grey;"></i>
						</li>
					</ul>
				</div>
			</div>
			<Notifications v-show="toggledNotificationType==='Notifications' && toggleNotifications" :newNotifications="[]"/>
		</div>
	</div>

	<div style="padding-left:40px;min-height:80vh;width: 99vw;">
		<div v-if="!isLoggedIn" id="nav">
			<div style="width:350px; margin-left:36%;">
				<Login  />
			</div>
		</div>
		<router-view v-else :store="$store" :username="username" @setIsLoading="setIsLoading"/>
	</div>
</div>
</template>

<script>
import Vue from 'vue';
import { dataAPI } from "./http-common";
import Login from "./components/session/Login.vue"

import 'vue-progress-path/dist/vue-progress-path.css';
import 'vue-context/dist/css/vue-context.css';
import 'vuejs-noty/dist/vuejs-noty.css'

import VueProgress from 'vue-progress-path';
import Notifications from '@/components/dashboard/Notifications.vue';
import Jobs from '@/components/dashboard/Jobs.vue';
import * as Sentry from '@sentry/vue'

Vue.use(VueProgress, {
	defaultShape: 'line',
})

export default {
	name: 'App',

	components: {
		Login,
		Notifications,
		Jobs
	},

	data: () => ({
		username: '',
		isLoading: false,
		toggleNotifications: false,
		newNotification: false,
		toggledNotificationType: ''
	}),

	computed : {
		isLoggedIn() { return this.$store.getters.isLoggedIn },
		isAdmin() { return localStorage.getItem('is_admin') === "true" }, // TODO: use Vuex state for these properties,
		isChecker() { return localStorage.getItem('is_checker') === "true" }, // localStorage is not reactive
		organisation() { return localStorage.getItem('organisation')},
		isIdle() { return this.$store?.state?.idleVue?.isIdle },
	},
	watch: {
		isIdle(newVal){
			this.$socket.emit('idleStatus', {
				email: `${this.username}@${localStorage.getItem('organisation')}`,
				status: (newVal===true)?'inactive':'active',
				timestamp: Date.now()
			});
		}
	},
	sockets: {
		connect()
		{
			console.log('%cSocket Connected ', 'color: green;');
		},

		broadcast(data)
		{
			// receive broadcast event
			if(this.isLoggedIn){

				this.newNotification = true;

				if(data?.info?.state==='timedOut'){
					const {taskUuid} = data.info;
					const currentRoute = this.$route.path;
					// if user is viewing a task while timeout event is sent, redirect to dashboard
					if(currentRoute.match(/taskUuid\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/)[0]===`taskUuid/${taskUuid}`){
						this.$router.push('/dashboard');
					}
				}
			}
		},
	},

	methods: {
		dbSelected(newVal)
		{
			console.log(newVal);
		},

		joinRoom(roomID){
			if(this.isLoggedIn){
				console.log(`%cJoined Room: ${roomID}`, 'color: green;')
				this.$socket.emit('join', {roomID: roomID, auth: localStorage.getItem('token')});
			}
		},

		async logout()
		{
			this.$socket.close();
			await this.$store.dispatch('logout');
			window.location="/"
		},

		setIsLoading(value){
			this.isLoading = value;
		},

		showNotifications(){
			this.toggleNotifications=true;
		},
	},
	mounted(){
		if(this.isLoggedIn){
			const organisation = localStorage.getItem('organisation');
			// TODO: use Vuex state for this
			if (localStorage.getItem('username') !== undefined)
			{
				this.username = localStorage.getItem('username');
			}

			Sentry.setUser({ id: `${this.username}` });
			this.$store.dispatch('Initialise');

			this.$root.$on('setIsLoading', (value) => {
				this.isLoading = value;
			})
			this.$root.$on('showNotifications', this.showNotifications());
			this.toggleNotifications=false;

			this.joinRoom(organisation);

		}
		else{
			this.$socket.close();
		}
	},
	created(){
		dataAPI.interceptors.response.use(undefined, ({ response }) => {
			if (response.status === 401 && this.isLoggedIn)
			{
				this.logout()
			} else
			{
				throw err;
			}
		})
	},
}
</script>

<style scoped>
.copyright {
	margin-top:8em;
	margin-right:3em;
	font-size:8px;
	float:right;
}


#navbox:hover {
	opacity: 0.8;
	background-color: white;
}

#content-wrap {
	padding-bottom: 2.5rem;	/* Footer height */
}


@keyframes glowing {
	0%,100% {
		box-shadow: 0 0 0 #2ba805;
	}
	50% {
		background-color: #49e819;
		box-shadow: 0 0 10px #49e819;
	}
}
.updated {
	animation: glowing 1300ms infinite;
}
/* .overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.274);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}
.modal {
	width: 500px;
	box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
	border-radius: 4px;
	background-color: white;
}
.modal__title {
	color: red;
	/* @apply flex items-center justify-between p-3 font-bold; */


/* .vue-progress-path path {
  stroke-width: 12;
}

.vue-progress-path .progress {
  stroke: red;
}


.vue-progress-path .background {
  stroke: #edd;
} */

</style>
