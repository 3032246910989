<template>
<div style="display: contents">
	<AgentCreationModal id="agent-creation-modal"/>

	<b-card class="card mb-3 h-100" style="background-color: transparent;">

		<b-overlay :show="loading" variant="transparent" :opacity="1" blur="1px">
			<!-- Tools -->
			<div class="tools">
				<b-button variant="outline-success" v-b-tooltip.hover="'Export to CSV'" @click="ExportTableAsCSV(agentsTable, 'agents')">
					<i class="fas fa-download fa-1x"></i>
				</b-button>
				<b-button variant="outline-success" v-b-tooltip.hover="'Refresh table'" @click="RefreshAgents">
					<i class="fas fa-sync fa-1x"></i>
				</b-button>
				<b-button variant="outline-info" @click="ShowCreationModal">
					<i class="fas fa-plus fa-1x mr-1"></i> Generate Agents
				</b-button>
				<b-button v-if="searchText" variant="outline-warning" @click="searchText=''">Clear Search</b-button>

				<b-input
					id="agent-search"
					type="text"
					placeholder="Search..."
					required
					autocomplete="off"
					v-model="searchText"
				/>
			</div>

			<!-- Agents -->
			<b-table
				striped
				hover
				sort-icon-right
				selectable
				select-mode="single"
				:items="agentsTable"
				:fields="fieldsToShow"
				:filter="searchText"
				:filterIncludedFields="[]"
				:tbody-tr-class="HighlightLoggedInAgents"
				@row-selected="SelectAgent"
				sticky-header="700px"
				style="overflow-y: scroll;"
			/>
		</b-overlay>

		<template #footer>
			<small v-if="loading">Loading...</small>
			<small class="text-muted" v-else>
				Updated {{new Date(now - lastUpdateTime).getMinutes()}} minute(s) ago
			</small>
		</template>
	</b-card>

	<AgentManager
		v-if="selectedAgent"
		:agent="selectedAgent"
	/>
</div>
</template>


<script>
import { BButton, BCard, BFormInput as BInput, BOverlay, BTable, VBTooltip } from 'bootstrap-vue'
import AgentManager from './AgentManager.vue'
import AgentCreationModal from './AgentCreationModal.vue';
import { ExportTableAsCSV } from '@/utils/files'

export default {
	name: 'dashboard-agent-view',

	props: {
	},

	components: {
		BButton,
		BCard,
		BInput,
		BOverlay,
		BTable,
		AgentManager,
		AgentCreationModal,
	},

	directives: {
		'b-tooltip': VBTooltip
	},

	data: () => ({
		loading: false,
		searchText: '',
		selectedAgent: null,
		errorMessage: null,
		now: Date.now(),

		allFields: ['email', 'name', 'is_admin', 'is_new', 'isLoggedIn', 'accountType', 'password'],
	}),

	computed: {
		agents() // clones store state so we don't mutate, and attaches passwords
		{
			const passwords = this.$store.state.admin.agentPasswords;
			return [...this.$store.state.admin.agents].map(agent => ({
				...agent,
				password: passwords[agent.email]
			}))
		},

		agentsTable()
		{
			return this.agents.map(agent => {
				const row = {};
				this.allFields.forEach(field => {
					row[field] = agent[field] // *these will not be reactive
				})
				return row;
			})
		},

		fieldsToShow()
		{
			return [
				{key: 'email', label: 'E-mail'},
				{key: 'name'},
				{key: 'accountType', label: 'Type', formatter: this.$store.getters['admin/FormatAgentType'] },
				{key: 'isLoggedIn', label: 'Status', formatter: (value) => value ? 'Online' : 'Offline'}
			]
		},

		lastUpdateTime()
		{
			return this.$store.state.admin.lastAgentsUpdateTime;
		}
	},

	watch: {
		lastUpdateTime()
		{
			this.now = Date.now();
		},

		agents(agents)
		{
			const selectedEmail = this.selectedAgent?.email;

			// Clear selectedAgent if it doesn't exist anymore
			if (!agents.some( ({email}) => email === selectedEmail ))
			{
				this.selectedAgent = null;
			}
			// OR force re-selection in case the table component resets its selection
			else if (selectedEmail)
			{
				this.$nextTick(() => this.selectedAgent = this.agents.find(agent => agent.email === selectedEmail));
			}
		}
	},

	methods: {
		ExportTableAsCSV,

		/**
		 * RefreshAgents
		 * Pulls agents table from backend.
		 */
		async RefreshAgents()
		{
			this.loading = true;
			const success = await this.$store.dispatch('admin/RefreshAgents');
			if (!success)
			{
				this.errorMessage = 'Failed to refresh agents.'
			}
			this.loading = false;
		},

		/**
		 * HighlightLoggedInAgents
		 * Used to assign 'table-success' class to logged in entries.
		 * Note: this will fail if 'isLoggedIn' is not a displayed column.
		 */
		HighlightLoggedInAgents(item, type)
		{
			if (!item || type !== 'row') return '';
			if (item.isLoggedIn) return 'table-success';
		},

		/**
		 * SelectAgent
		 * Selects/Deselects an agent based on current selection.
		 */
		SelectAgent([agent])
		{
			if (agent === this.selectedAgent)
				this.selectedAgent = null;
			else
				this.selectedAgent = agent;
		},

		/**
		 * ShowCreationModal
		 * Displays the agent creation modal
		 */
		ShowCreationModal()
		{
			this.$root.$emit('bv::show::modal', 'agent-creation-modal');
		},
	},

	mounted()
	{
		this.RefreshAgents();
		this._timer = setInterval(() => this.now = Date.now(), 60*1000); // every minute
	},

	beforeDestroy()
	{
		clearInterval(this._timer);
	}
}
</script>


<style scoped>
.tools .btn { margin-bottom: 10px }
.tools .btn + .btn { margin-left: 10px }

/* TODO: SHOULD THESE BE GLOBAL STYLES? */
* {
	scrollbar-width: thin;
	scrollbar-color: blue #002B36;
}
*::-webkit-scrollbar {
	width: 12px;

}
*::-webkit-scrollbar-track {
	background: #002B36;
}
*::-webkit-scrollbar-thumb {
	background-color: #E6E9EA;
	border-radius: 20px;
	border: 3px solid #002B36;
}
</style>