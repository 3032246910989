
<div class = "row" style = "display:flex; flex-direction: row; width:100%;">
	
	<b-modal
		id="fullframe-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		hide-footer
	>	
		<FullFrameDisplay 
			style="height: 1000px; width: 500px;"
			:imageScale="2.7"	
			:framePath="currentFullFrameImage"
			:size="fullFramePathSizeMap[currentDetectionUuid]"
			:subFrame="currentFullFrameSubFrame"
		/>
	</b-modal>
	<div class="scatter">
		<div style="overflow:auto">
			<div class = "button-row">
				<div  style="float:left;width: 58px;">
					<input class="form-control" v-model="selectedBlock" type="text">
				</div>
				<div v-if="!taskUuid">
					<select style="border-radius: 5px; background-color: #A9BDBD;padding: 4px;height:100%;" v-model="currentLocalTrackList">
						<option v-if="localTrackLists" disabled value="">{{localTrackLists.length}} Datasets Available</option>
						<option v-for="dataset in localTrackLists" :key="dataset.tableName" :value="dataset.tableName">
							{{ dataset.tableName }}
						</option>
					</select>
				</div>
				<button v-if="(currentBlock>0)" class="btn btn-info" @click="loadPrevious(currentBlock)" >
					<i class="fas fa-arrow-left"></i>
				</button>
				<button v-if="showUnlabelled" class="btn btn-info" @click="retrieveUnlabelledTracks(selectedBlock)">
					{{selectedBlock}}
				</button>
				<button v-if="!showUnlabelled" class="btn btn-info" @click="loadBlockNum(selectedBlock)">
					{{selectedBlock}}
				</button>
				<button class="btn btn-info" @click="loadNext(currentBlock)">
					<i class="fas fa-arrow-right"></i>
				</button>
				<button v-if="comparisonDistances" class="btn btn-info" @click="finderToggle = !finderToggle">
					<i class="fas fa-search"></i>
				</button>
				
				
				<button v-if="(taskUuid !== '')"
					class="btn btn-info" @click="toggleSelectorButtons=!toggleSelectorButtons">
					<i class="fas fa-info"></i>
				</button>
				<button
					class="btn btn-info" @click="toggleMergeTrackEditor=!toggleMergeTrackEditor">
					<i class="fas fa-edit"></i>
				</button>
				<button class="btn btn-info" @click="toggleImageCardSize">
					<i :class="`fas fa-search-${size === 'xl' ? 'minus' : 'plus'}`"/>
				</button>
				<div v-if="taskUuid===''" style="margin-left:50px;margin-top:6px;">
					<input v-if="isAdmin" v-model="showUnlabelled" type="checkbox" class="custom-control-input" id="viewTypeToggle" checked="">
					<label v-if="showUnlabelled" class="custom-control-label" for="viewTypeToggle">Unlabelled View</label>
					<label v-if="!showUnlabelled" class="custom-control-label" for="viewTypeToggle">Labelled View</label>
				</div>

			</div>
			<div v-if="toggleSelectorButtons">
				<div class="card mb-3" style="margin-top:10px;width:50%;">
					<h3 class="card-header">Task Info</h3>

					<div class="card-body">

						<h5 v-if="!consolidateTaskUuid" class="card-title" >Pages assigned: {{allocatedBlocksFormatted}}</h5>

						<h5 v-else class="card-title">Consolidation Task
							<p>Please click and search ALL the images below and perform consolidation on any matches</p>

						</h5>
						<h5>Task ID: <code>{{(consolidateTaskUuid==='') ? taskUuid : consolidateTaskUuid}}</code> </h5>

						<br>
						<button class="btn btn-success" @click="markTaskAsDone()">Finish Task</button>
					</div>
				</div>
			</div>
			<div id="row1">
				<div class="column" id="explorer">
					<div id="controls" >
						<div id="recently_added">
							<div class="controls" id="recents">
								
								<div id="recently_added" v-if="(!dbToggled)" class="card border-light mb-3">
									<div class="card-header" >
										<!-- <pre v-if="this.taskUuid!==''" style="margin-left:5px;">Merges From Task {{this.taskUuid}}</pre> -->
										<pre style="margin-left:5px;">Merges</pre>
										<pre v-if="toggleMulti &&selectedFilteredBlock!=='all'" style="margin-left:5px;">{{globalMergeTracksFiltered.length}} Merge Tracks from page {{selectedFilteredBlock}} to page {{selectedFilteredBlockUpper}}</pre>
										<pre v-if="selectedFilteredBlock!=='all' &&!toggleMulti" style="margin-left:5px;">{{globalMergeTracksFiltered.length}} Merge Tracks from page {{selectedFilteredBlock}}</pre>
										<pre v-if="selectedFilteredBlock==='all'" style="margin-left:5px;">{{globalMergeTracksFiltered.length}} Merge Tracks identified in total</pre>

										<vs-button
											icon
											style="outline: none;  "
											color="success"
											v-if="loadingConsolidations===false && taskUuid ===''"
											@click="findInstancesConsolidation(currentSelectedMergeTrack)"

										>

											<i class="fas fa-search"></i>
										</vs-button>
										<vs-button
											icon
											style="outline: none;"
											v-else-if="loadingConsolidations===true && taskUuid ===''"
											color="success"
											loading
											@click="findInstancesConsolidation(currentSelectedMergeTrack)"

										>
											<i class="fas fa-search"></i>
										</vs-button>
										<template>
											<div v-if="consolidationCandidates.length>0">
												<pre>Page {{1+consolidationPage}} out of {{Math.ceil(totalConsolidationHelperResults/consolidationPageSize)}}  </pre>
												<div class = "row" style="display: flex; flex-direction: row; margin-left: 1px;">
													<vs-button
														icon
														style="outline: none;  "
														color="success"
														v-if="(loadingConsolidations===false) && (consolidationPage>0)"
														@click="consolidationPage = Math.max(--consolidationPage, 0);"
													>
														<i class="fas fa-arrow-left"></i>
													</vs-button>
													<vs-button
														icon
														style="outline: none;  "
														color="success"
														v-if="loadingConsolidations===false"
														@click="consolidationPage+=1;"
													>
														<i class="fas fa-arrow-right"></i>
													</vs-button>
												</div>
								
											</div>
										</template>
									</div>
									<div class="card-body">
										<h5 v-if="consolidationCandidates.length>0">Search Results</h5>
										<div v-if="!consolidateTaskUuid" class="custom-control custom-switch">
											<input v-model="toggleMulti" type="checkbox" class="custom-control-input" id="pageSelectType" checked="">
											<label v-if="!toggleMulti" class="custom-control-label" for="pageSelectType">Single Page Select</label>
											<label v-if="toggleMulti" class="custom-control-label" for="pageSelectType">Multi Page Select</label>
										</div>
										<pre v-if="!consolidateTaskUuid">Filter by page: </pre>
										<div v-if="!consolidateTaskUuid" class = "row"  style="display: flex; flex-direction: row; width:500px;margin-left: 0px;">
											<select  class="form-control" v-model="selectedFilteredBlock" style="width: 150px;">
												<option disabled value="">Please select one</option>
												<option v-if="!toggleMulti" v-for="minBlockNum in uniqueMergeBlocksForFilter" v-bind:key="minBlockNum">
													{{minBlockNum}}
												</option>
												<option v-if="toggleMulti" v-for="minBlockNum in uniqueMergeBlocksForFilter.filter(row=>row!=='all')" v-bind:key="minBlockNum">
													{{minBlockNum}}
												</option>
											</select>
											<select v-if="toggleMulti" class="form-control" v-model="selectedFilteredBlockUpper" style="width: 150px;">
												<option disabled value="">Please select one</option>
												<option v-for="minBlockNum in uniqueMergeBlocksForFilter.filter(row=>row!='all')" v-bind:key="minBlockNum">
													{{minBlockNum}}
												</option>
											</select>


										</div>
										<button v-if="(selectedMergeIndexes.size !== undefined && (selectedMergeIndexes.size > 0))" class="btn btn-danger"
											@click="clearSelected()">
											Clear
										</button>
										<button v-if="(selectedMergeIndexes.size >1)" class="btn btn-info btn-sm" style="display: block;padding: 0 10px;"
											@click="consolidateMergeTracks(Array.from(selectedMergeIndexes))">
											Consolidate {{selectedMergeIndexes.size}} Merge Tracks
										</button>
										
										<div id="recents">
											<div v-for="(uniquePerson, index) in consolidationCandidates
													.slice(consolidationPageSize*consolidationPage, consolidationPageSize*consolidationPage + consolidationPageSize)"
												:key="uniquePerson.mergeUuid" class="track-highlight"
												:class="{active: ((frameHoverIndex4 === index)), selected: selectedMergeIndexes.has(uniquePerson.mergeUuid)}"
												@click="mergeTrackClicked(index, $event, uniquePerson.mergeUuid);(frameHoverIndex4 === index) ? frameHoverIndex4 = -1 : null;"
												@mousedown="setCurrentDetectionUuid(uniquePerson.detectionUuid)"
												@mouseover="frameHoverAction4(index, uniquePerson.detectionUuid)"
												@mouseleave="(frameHoverIndex4 === index) ? frameHoverIndex4 = -1 : null">
												<button v-if="((selectedPointIndexes !== null) && (selectedPointIndexes.size>0)) && (frameHoverIndex2 === index)"
													class="btn btn-info btn-sm"
													style="display: block;position:absolute; z-index:9999;margin-left: 15px; margin-bottom: 4px;"
													@click="mergeRecentTracks(uniquePerson.mergeUuid)">
													<i class="fas fa-plus"></i>
													{{selectedPointIndexes.size}}
												</button>
												<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
													<img v-if="uniquePerson.subFrame===undefined && sizeMap[uniquePerson.detectionUuid]!==undefined" :src="uniquePeopleFramePathsMap.get(uniquePerson.detectionUuid)"
														:style="localSetClipCss(uniquePersonSubFramesMap.get(uniquePerson.detectionUuid), sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)">
													<img v-if="uniquePerson.subFrame!==undefined" id="recent-thumbnail"
														:src="getEntryThumbnail(uniquePerson)"
														:style="localSetClipCss(uniquePerson.subFrame, sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)"
														@click="setCurrentGlobalMerge(uniquePerson.mergeUuid);">
												</div>
											</div>
										</div>
										<br>
										<br>
										<div id="recents">
											<div v-for="(uniquePerson, index) in globalMergeTracksFiltered"
												:key="uniquePerson.mergeUuid" class="track-highlight"
												:class="{active: ((frameHoverIndex2 === index )), selected: selectedMergeIndexes.has(uniquePerson.mergeUuid)}"
												@click="mergeTrackClicked(index, $event, uniquePerson.mergeUuid);(frameHoverIndex2 === index) ? frameHoverIndex2 = -1 : null;"
												@mouseover="frameHoverAction2(index, uniquePerson.detectionUuid)"
												@mousedown="setCurrentDetectionUuid(uniquePerson.detectionUuid)"
												@mouseleave="(frameHoverIndex2 === index) ? frameHoverIndex2 = -1 : null">
												<button v-if="((selectedPointIndexes !== null) && (selectedPointIndexes.size>0)) && (frameHoverIndex2 === index)"
													class="btn btn-info btn-sm"
													style="display: block;position:absolute; z-index:9999;margin-left: 15px; margin-bottom: 4px;"
													@click="mergeRecentTracks(uniquePerson.mergeUuid)">
													<i class="fas fa-plus"></i>
													{{selectedPointIndexes.size}}
												</button>
												<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
													<img 
														v-if="uniquePerson.subFrame===undefined && sizeMap[uniquePerson.detectionUuid]!==undefined" 
														:src="uniquePeopleFramePathsMap.get(uniquePerson.detectionUuid)"
														:style="localSetClipCss(uniquePersonSubFramesMap.get(uniquePerson.detectionUuid),  sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)">
													<img v-if="uniquePerson.subFrame!==undefined" id="recent-thumbnail"
														:src="getEntryThumbnail(uniquePerson)"
														:style="localSetClipCss(uniquePerson.subFrame, sizeMap[uniquePerson.detectionUuid].width, sizeMap[uniquePerson.detectionUuid].height)"
														@click="setCurrentGlobalMerge(uniquePerson.mergeUuid); setCurrentDetectionUuid(uniquePerson.detectionUuid)">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="button-controls" v-if ="availablePoints.length>0">
								<button v-if="selectedPointIndexes" class="btn btn-info" @click="mergeTracks()">
									Merge {{selectedPointIndexes.size}} Tracks
								</button>
								

								<button  class="btn btn-info" @click="clearSelected()">
									Clear
								</button>
								<button   class="btn btn-danger" @click="moveToGarbage({type: 'garbage'})" style="background-color: #FF0000;">
									<i class="fas fa-trash"></i>
								</button>
								<button   class="btn btn-danger" @click="moveToGarbage({type: 'staff'})" style="background-color: #FF0000;">
									<i class="fas fa-user"></i>
								</button>
							</div>
						</div>

						<div class = "row" style="display: flex; flex-direction: row; width: 100%;" v-if="(availablePoints)">

							<div v-if="(availablePoints.length>0)" id="tracks" :style="toggleMergeTrackEditorStyle()">
								<div v-for="(line, index) in displaySelected(frameHoverIndex)" :key="`summary-${index}`">
									{{ line }}
								</div>

								<div v-if="(framePathsMap !== null && subFramesMap !== null)">

									<div class="track-data" >
										<div v-for="(startPoint, index) in availablePoints"
											:key="startPoint.detectionUuid"
											:style="setTrackStyle(index)" class="track-highlight"
											style="position:relative;" 
											:class="setTrackClass(index,frameHoverIndex)"
											:data-uuid="startPoint.detectionUuid"
											@mouseover="frameHoverAction(index, startPoint.detectionUuid,$event);shiftKey = $event.shiftKey;clickEngaged = ($event.which===1)"
											@mouseleave="(frameHoverIndex === index) ? frameHoverIndex = -1 : null"
											@mousedown="frameClicked(index, $event); (frameHoverIndex === index) ? frameHoverIndex = -1 : null; setCurrentDetectionUuid(startPoint.detectionUuid)"
											@mouseup="selectedByDrag(index, $event);"
										>
											
											<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
												<img
													
													v-if="sizeMap[startPoint.detectionUuid]!==undefined"
													draggable="false"
													ondragstart="return false;"
													:src="framePathsMap.get(startPoint.detectionUuid)"
													:style="localSetClipCss(subFramesMap.get(startPoint.detectionUuid), sizeMap[startPoint.detectionUuid].width, sizeMap[startPoint.detectionUuid].height)"
												>
												
											</div>
											
											
										</div>
									</div>
								</div>
							</div>
							<MergeTrackEditor
								v-if="toggleMergeTrackEditor"
								:db="this.db"
								:inputTable="this.localTrackListComputed"
								:mergeUuid="this.currentSelectedMergeTrack"
								:key="this.currentSelectedMergeTrack"
								:resolution="this.resolution"
								:format="this.format"
								:currPage="this.currentBlock"
								@mergeUuidUpdateEvent="mergeUuidUpdateEvent"
								@updateCurrentMergeTrack="updateCurrentMergeTrack"
								@imageHover="mergeEditorHoverEvent"
								@retrieveTrackEndPoints="retrieveTrackEndPoints"
								ref="mergeEditor"
								:style="mergeTrackEditorStyle()"
								:mode="(consolidateTaskUuid !== '') ? 'read' : 'read/write'"
								:portalDetails="portalDetails"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<vue-context ref="menu">
		<li>
			<a href="#" @click="showFullFrame()" @click.prevent="onClick($event.target.innerText)">View Full Frame</a>
		</li>
	</vue-context>
</div>

