/**
 * ToCamelCase
 * Convert undercase strings to camel case
 * @param {String} string  String to convert to camel case
 */
export function ToCamelCase(string){

	let s = string.replace(/\s+(.)/g, function (match, group) {
		return group.toUpperCase()
	});

	return s[0].toLowerCase() + s.slice(1, s.length);

}

/**
 * formatSeconds
 * Formats seconds to MM:SS
 * @param {Int} seconds  seconds to conver
 */
export function formatSeconds(seconds) {
	const mins = Math.floor(seconds / 60);
	const secs = seconds % 60;
	const zeroPad = (num, places) => `${num}`.padStart(places, '0')
	const formattedTick = `${mins}:${zeroPad(secs.toFixed(0), 2)}`; // mm:ss
	return formattedTick;
}