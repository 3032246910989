<template>
	<div class="dataset-viewer-container">
		<b-modal
				id="gallery-modal"
				no-close-on-backdrop
				no-stacking
				size="xl"
				ok-variant="info"
				hide-footer
			>
				<template #modal-title>
					Similar Merge Tracks
				</template>

				<div class="search-results-container">
					<div class="search-results-pane-1">
						<div class="gallery-container">
							<p class="card-header">Target</p>
							<DetectionGallery
								v-if="mergeTrack && mergeTrack.length"
								:key="selectedMergeUuid"
								:imagesLoaded="true"
								:points="mergeTrack"
								:hasCroppedFrames="true"
								pointType="detectionUuid"
								:sortPoints="false"
								:pageSize="60"
								:mergeable="false"
								ref="targetGallery"
								size="xs"
								width="50vw"
								:selectable="true"
								:multiSelect="false"
								displayMode="fullframeGallery"
							/>
						</div>
						<div class="gallery-container">
							<p class="card-header" v-if="selectedSearchMergeTrack && selectedSearchMergeTrack.length">
								Viewing Merge: {{ selectedSearchMergeTrack[0].mergeUuid }}
							</p> 
							<DetectionGallery
								v-if="selectedSearchMergeTrack && selectedSearchMergeTrack.length"
								:imagesLoaded="true"
								:points="selectedSearchMergeTrack"
								:hasCroppedFrames="true"
								pointType="detectionUuid"
								:sortPoints="false"
								:pageSize="60"
								:mergeable="false"
								ref="targetGallery"
								size="xs"
								width="50vw"
								:selectable="true"
								:multiSelect="false"
								displayMode="fullframeGallery"
							/>
						</div>
					</div>
					<div class="search-results-pane-2">
						<p class="card-header">Similar</p>
						<DetectionGallery
							v-if="searchResults && searchResults.length"
							:imagesLoaded="true"
							:points="searchResults"
							:hasCroppedFrames="true"
							pointType="mergeUuid"
							:sortPoints="false"
							:pageSize="60"
							:mergeable="false"
							ref="resultsGallery"
							size="xs"
							width="100vw"
							:selectable="true"
							:multiSelect="false"
							@imageSelected="retrieveSearchMergeTrack($event.mergeUuid)"
						/>
					</div>
				</div>
		</b-modal>

		<div class="datasets-header-container">
			<div class="datasets-header">
				Datasets / {{ datasetDetails?.datasetName || 'Loading...' }}
			</div>
		</div>
		
		<div class="empty-datasets" v-if="mergeTracks.length==0 && !loadingData" >
			No merges available in this dataset
		</div>

		<div v-else>
			<div class="selected-merge-container">
				<b-form-input
					class="merge-search-input"
					placeholder="Search for a merge UUID"
					v-model="selectedMergeUuid"
				/>
				<div class="selected-merge-details-container">
					<div class="merge-track-information" v-if="selectedMergeUuid">
						<code class="code-white">
							Merge UUID: {{ selectedMergeUuid }}
						</code>
						<code class="code-white">
							Images: {{ mergeTrack ? mergeTrack.length : 0 }}
						</code>
						<code class="code-white" v-if="mergeTrack && mergeTrack[0] && mergeTrack[0].isStaff" >
							Type: Staff
						</code>
					</div>
					<div v-else class="no-merge-selected" >
						<code class="code-white">
							Search dataset for a mergeUUID to get started...
						</code>
					</div>
					<div class="controls-container">
							<b-button 
								@click="retrieveSimilarMerges(selectedMergeUuid)" 
								size="sm" 
								variant="outline-info"
								:disabled="!selectedMergeUuid"
							>
								<i class="fas fa-search fa-1x"/> Find Similar Tracks
							</b-button>
					</div>
				</div>
			</div>

			<div class="detection-gallery-container">
				<DetectionGallery
					v-if="mergeTrack && mergeTrack.length"
					:key="selectedMergeUuid"
					:imagesLoaded="true"
					:points="mergeTrack"
					:hasCroppedFrames="true"
					pointType="detectionUuid"
					:sortPoints="false"
					:pageSize="60"
					:mergeable="false"
					ref="gallery"
					size="xl"
					width="100%"
					:selectable="true"
					:multiSelect="false"
					displayMode="fullframeGallery"
					:datasetUUID="datasetUUID"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { dataAPI } from "../http-common";
import DetectionGallery from '../components/tools/DetectionGallery.vue';
import { BButtonGroup, BPagination } from 'bootstrap-vue'

export default {
	name: 'DatasetViewer',
	props: {
		datasetUUID: { type: String, required: true }
	},
	data() {
		return {
			datasetDetails: null,
			mergeTracks: null,
			mergeTrack: null,
			selectedMergeUuid: null,
			searchResults: [],
			selectedSearchMergeTrack: null,
			currentPage: 1,
			loadingData: false,
		};
	},
	components: {
		DetectionGallery,
		BButtonGroup,
		BPagination
	},
	methods: {
		async retrieveDatasetInfo(datasetUUID){
			try {
				const { data: datasetDetails } = await dataAPI.get("datasets/getDatasetInfo", { 
					params: { datasetUuid: datasetUUID }
				});
				this.datasetDetails = datasetDetails;
			} catch (error) {
				console.error("Failed to fetch dataset info:", error);
				this.datasetDetails = null;
			}
		},
		async retrieveMergeTrack(mergeUuid){
			if (!mergeUuid) return;
			try {
				const { data } = await dataAPI.get(`datasets/${this.datasetUUID}/mergeTracks/${mergeUuid}/detections`);
				this.mergeTrack = data;
			} catch (error) {
				console.error("Failed to fetch merge track:", error);
				this.mergeTrack = null;
			}
		},
		async retrieveSearchMergeTrack(mergeUuid){
			try {
				const { data } = await dataAPI.get(`datasets/${this.datasetUUID}/mergeTracks/${mergeUuid}/detections`);
				this.selectedSearchMergeTrack = data;
			} catch (error) {
				console.error("Failed to fetch search merge track:", error);
				this.selectedSearchMergeTrack = null;
			}
		},
		async retrieveDatasetMergeTracks(datasetUUID){
			try {
				const { data } = await dataAPI.get(`datasets/${datasetUUID}/mergeTracks?limit=500`);
				this.mergeTracks = data;
			} catch (error) {
				console.error("Failed to fetch merge tracks:", error);
				this.mergeTracks = [];
			}
		},
		async retrieveSimilarMerges(mergeUuid){
			if (!mergeUuid) return;
			try {
				const searchURL = `datasets/${this.datasetUUID}/mergeTracks/search/${mergeUuid}`;
				const { data } = await dataAPI.get(searchURL);
				//filter the target merge from the similar results arr
				this.searchResults = data.filter(obj => obj.mergeUuid !== mergeUuid);
				this.$root.$emit('bv::show::modal', 'gallery-modal');
			} catch (error) {
				console.error("Failed to search similar tracks:", error);
				this.searchResults = [];
			}
		},		
	},
	watch: {
		selectedMergeUuid(newVal){
			this.retrieveMergeTrack(newVal);
		}
	},
	async mounted() {
		this.loadingData = true;
		await this.retrieveDatasetInfo(this.datasetUUID);
		await this.retrieveDatasetMergeTracks(this.datasetUUID);

		this.loadingData = false;
	}
};
</script>

<style scoped>
.dataset-viewer-container {
	margin-bottom: 16px; 
	margin-top: 16px; 
	width: fit-content;  
	padding: 16px; 
	width: 100%; 
	border-radius: 5px;
}

.search-results-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.search-results-pane-1 {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.search-results-pane-2 {
	max-width: 100%;
	margin-right: calc(50% + 8px);
}

.gallery-container{
	flex: 1;
	max-width: 50%;
}

.datasets-header-container{
	margin-bottom: 16px;
	padding: 16px;
	font-size: 1.5em;
}

.datasets-header{
	margin-bottom: 16px;
	margin-top: 16px;
	width: fit-content;
}

.empty-datasets{
	font-size: 1.5em; 
	border-radius: 5px; 
	border: 1px solid #002A34; 
	height: 500px; 
	width: 100%; 
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 16px;
}

.merge-search-input{
	width:310px;
}

.selected-merge-container{
	margin-bottom: 16px;
	border: 2px solid #002A34;
	padding: 16px;
}

.selected-merge-details-container{
	display: flex;
	flex-direction: row
}

.merge-track-information{
	padding-top: 32px;
	padding-bottom: 32px;
	display: flex;
	flex-direction: column;
	width: 75%;
}

code.code-white{
	color: white
}

.no-merge-selected{
	padding-top: 32px; 
	padding-bottom: 32px;
	display: flex; 
	flex-direction: column; 
	width: 75%;
}

.controls-container{
	width: 25%; 
	padding-top: 32px;
	position: relative; 
	width: fit-content; 
	margin-left: auto;
}

.detection-gallery-container{
	display: flex;
	flex-direction: row;
	width: 100%;
}
</style>