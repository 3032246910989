<template>
<div style="display: contents;">
	<ul class="nav nav-tabs" style="width:fit-content">
		<li class="nav-item" @click="toggleTrackSearch=1">
			<a :class="toggleTrackSearch===0 ? 'nav-link' : 'nav-link active'" data-toggle="tab">Full Track Search</a>
		</li>

	</ul>
	<b-card v-if="toggleTrackSearch" class="card mb-0 h-100" style="padding:0px;">

		<div style="display: flex; flex-direction: column">
			<div style="display: flex; flex-direction: flex">
				<MergeTrackEditor
					:db="dataset.vendor"
					:inputTable="dataset.datasetName"
					mergeUuid=""
					:key="dataset.uuid"
					:resolution="1920"
					:format="jpeg"
					:currPage="0"
					ref="mergeEditor"
					:minimal="true"
					mode="read"
					@dataLoaded="dataLoaded"
					style="width: 50%; float:left;  height:499px; width: 50%; overflow-y: scroll;"
				>
				</MergeTrackEditor>
				<div v-if="currentHighLightedDetection!==undefined" style="height: 55%;width:49%;">
					<div
						:id="'ff-canvas-' + currentHighLightedDetection.detectionUuid" v-anime="{}"
						class="ff-canvas_fullframe_preview"
						:style="{width: frameWidth}"

					>

						<div id="fullframe">
							<img :src="currentHighLightedDetection.framePath">
						</div>

						<div :style="setFrameRegionCss(currentHighLightedDetection.subFrame, true)"></div>
					</div>
				</div>

			</div>
			<div style="display: flex; flex-direction: flex">
				<DetectionGallery
					style="width:100%; height: 100%; margin-left: 16px;"
					:points="points"
					:from="dataset.from"
					:pageSize="25"
					:to="dataset.to"
					:mergeable="true"
					:mergeUuid="mergeEditorData.mergeUuid"
					@setHighLightedDetection="setHighLightedDetection"
				>
				</DetectionGallery>
			</div>
		</div>
	</b-card>
	<b-card v-if="!toggleTrackSearch" class="card mb-0 h-100" style="padding:0px;">

	</b-card>
</div>
</template>

<script>
import { BButton, BCard, BFormInput as BInput, BOverlay, BTable, VBTooltip,BNav, BDropdown } from 'bootstrap-vue'
import { dataAPI } from "../../http-common";
import VueContext from 'vue-context';
import MergeTrackEditor from '@/components/MergeTrackEditor.vue';
import DetectionGallery from '@/components/tools/DetectionGallery.vue';
export default {
	name: 'DatasetExplorer',
	props: {
		dataset: {
			dataset: Object,
			datasetObj: {},
			default: {},
		}
	},
	components: {
		BButton,
		BCard,
		BInput,
		BOverlay,
		BTable,
		BNav,
		BDropdown,
		VueContext,
		MergeTrackEditor,
		DetectionGallery
	},
	directives: {
		'b-tooltip': VBTooltip
	},
	data: () => ({
		datasets: [],
		toggleActiveDatasets: 0,
		selectedDataset: {},
		statsEnabled: false,
		toggleTrackSearch: true,
		points: [],
		pointsMerged: [],
		mergeEditorData: [],
		currentHighLightedDetection: undefined,
		frameWidth: "850px",
	}),
	computed: {

	},
	watch: {
		selectedDataset(newVal){
			this.getDatasetStats(newVal.datasetName);
		}
	},
	methods: {

		async dataLoaded(data){
			this.mergeEditorData = data;

			this.$root.$emit('setIsLoading', true);
			console.log(this.dataset)
			// http://10.1.1.128:4222/api/hfm1data2020cloud/trackSearch/findSimilarPoints
			const body = {
				'mergeUuid': data.mergeUuid,
				'detections': data.detections.map(r=>r.detectionUuid),
				"from":this.dataset.from,
				"to": this.dataset.to,
				"vendor": this.dataset.vendor
			}

			this.points = (await dataAPI.post('hfm1data2020cloud/trackSearch/findSimilarPoints', body)).data;
			this.$root.$emit('setIsLoading', false);
		},
		async getAllDatasets(){
			this.$root.$emit('setIsLoading', true)
			this.datasets=[];
			let datasets = (await dataAPI.post('hfm1data2020cloud/datasets/getDatasets')).data;
			this.datasets = datasets;
			this.$root.$emit('setIsLoading', false)

		},
		async activateDataset(dataset){
			const confirm = window.confirm(`Move ${dataset.datasetType} ${dataset.datasetDateString} ${dataset.inJobQueue ? 'out of' : 'to'} labelling queue?`);
			const fieldsToUpdate = {
				inJobQueue: !dataset.inJobQueue
			}
			let activateResult = (await dataAPI.post('hfm1data2020cloud/datasets/updateDataset', {fieldsToUpdate: fieldsToUpdate, datasetUuid: dataset.uuid})).data;
			this.getAllDatasets();
		},
		async getDatasetStats(datasetName){
			let stats = (await dataAPI.post('hfm1data2020cloud/datasets/getDatasetStats', {datasetName: datasetName})).data;
			this.datasetStats[datasetName]=stats;
		},
		getCategories(histogram){
			// return histogram;
			return histogram.sort((a,b)=>{return a['count']-b['count']}).map(r=>r.count).filter( (value, index, self) => {
				return self.indexOf(value) === index;
			});
		},
		setFrameRegionCss: function(coords, visible) {
			if (coords != null){
				const ratio = $(".ff-canvas_fullframe_preview").width() / 1920;
				const top = ratio * coords.top;
				const left = ratio * coords.left;
				const width = ratio * (coords.right - coords.left);
				const height = ratio * (coords.bottom - coords.top);

				let borderPx = '2';
				let color = "white";
				const visVal = visible ? 'visible' : 'hidden';
				return `position:absolute;
						top:${top}px;
						left:${left}px;
						height:${height}px;
						width:${width}px;
						border:${borderPx}px solid ${color};
						color:${color};
						visibility:${visVal};`;

				// point.x = int((xmin + xmax)/2.0)
				// point.y = int(ymin + 0.0625*(ymax-ymin))
			}
		},
		getChartOptions(histogram, type) {
			let options = {
				chart: {
					height: 550,
					width: 1000,
					type: 'bar',
				},
				plotOptions: {
					bar: {
						dataLabels: {
							position: 'top'
						},
					}
				},
				dataLabels: {
					enabled: true,
					formatter: function(val) {
						return val;
					},
					offsetY: -20,
					style: {
						fontSize: '10px',
						colors: ["white"]
					}
				},
				xaxis: {
					categories: this.getCategories(histogram),
					position: 'bottom',
					axisBorder: {
						show: false
					},
					labels: {
						style: {
							colors: Array.from(Array(this.getCategories(histogram).length).keys()).map(r=>{return 'white'})
						}
					},
					axisTicks: {
						show: false
					},
					crosshairs: {
						fill: {
							type: 'gradient',
							gradient: {
								colorFrom: 'white',
								colorTo: 'white',
								stops: [0, 100],
								opacityFrom: 0.4,
								opacityTo: 0.5,
							}
						}
					},
					tooltip: {
						enabled: true,
					}
				},
				yaxis: {
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false,
					},
					labels: {
						show: false,
						formatter: function(val) {
							return val + "instances";
						}
					}

				},
				title: {
					text: `${type}`,
					floating: true,
					offsetY: 0,
					align: 'center',
					style: {
						color: 'white'
					}
				}
			}
			return options;
		},
		setHighLightedDetection(value){
			this.currentHighLightedDetection = value;
		}
	},
	mounted(){
		this.getAllDatasets();

	}
}
</script>

<style scoped>

span {
	margin-left:10px
}
.tools .btn { margin-bottom: 10px }
.tools .btn + .btn { margin-left: 10px }

* {
	scrollbar-width: thin;
	scrollbar-color: blue #002B36;
}
*::-webkit-scrollbar {
	width: 12px;

}
*::-webkit-scrollbar-track {
	background: #002B36;
}
*::-webkit-scrollbar-thumb {
	background-color: #E6E9EA;
	border-radius: 20px;
	border: 3px solid #002B36;
}

.ff-canvas_fullframe_preview{
	position: absolute;

	/* top: 8px;
	right: 8px;
	font-size: 18px;
	z-index: 10; */
}
</style>