
<div style="display: contents">
	<AgentCreationModal id="agent-creation-modal"/>

	<b-card class="card mb-3 h-100" style="background-color: transparent;">

		<b-overlay :show="loading" variant="transparent" :opacity="1" blur="1px">
			<!-- Tools -->
			<div class="tools">
				<b-button variant="outline-success" v-b-tooltip.hover="'Export to CSV'" @click="ExportTableAsCSV(agentsTable, 'agents')">
					<i class="fas fa-download fa-1x"></i>
				</b-button>
				<b-button variant="outline-success" v-b-tooltip.hover="'Refresh table'" @click="RefreshAgents">
					<i class="fas fa-sync fa-1x"></i>
				</b-button>
				<b-button variant="outline-info" @click="ShowCreationModal">
					<i class="fas fa-plus fa-1x mr-1"></i> Generate Agents
				</b-button>
				<b-button v-if="searchText" variant="outline-warning" @click="searchText=''">Clear Search</b-button>

				<b-input
					id="agent-search"
					type="text"
					placeholder="Search..."
					required
					autocomplete="off"
					v-model="searchText"
				/>
			</div>

			<!-- Agents -->
			<b-table
				striped
				hover
				sort-icon-right
				selectable
				select-mode="single"
				:items="agentsTable"
				:fields="fieldsToShow"
				:filter="searchText"
				:filterIncludedFields="[]"
				:tbody-tr-class="HighlightLoggedInAgents"
				@row-selected="SelectAgent"
				sticky-header="700px"
				style="overflow-y: scroll;"
			/>
		</b-overlay>

		<template #footer>
			<small v-if="loading">Loading...</small>
			<small class="text-muted" v-else>
				Updated {{new Date(now - lastUpdateTime).getMinutes()}} minute(s) ago
			</small>
		</template>
	</b-card>

	<AgentManager
		v-if="selectedAgent"
		:agent="selectedAgent"
	/>
</div>
