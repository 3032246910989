
<div class="timeline-wrap" :style="`--row-height: ${CSS_ROW_HEIGHT}px;`">
	<div class="event-types-wrapper">

		<h6 class="title" :style="`${(eventTypes.length===0) ? 'border-bottom:1px solid transparent' : ''}`">
			Timeline Annotations
			<span class = "badge badge-success" style="position:relative; left:25px;;"><b>{{timelineEvents.length}}</b></span>
		</h6>

		<div>
			<div
				v-for="type of eventTypes"
				:key="type"
				style="padding:14px;"
				class="event-type"
			>
				{{ type }}
				<i :class="`ml-1 fas fa-info-circle text${(showTimelineEventInfo)?'-info':''}`" style="margin-right:5px;" @click="showTimelineEventInfo=!showTimelineEventInfo"/>
				<i class="fas fa-ellipsis-v" style="position: relative; top: 4px; float: right;"/>
			</div>
		</div>
		<div v-if="eventTypes.length === 0">
			<div class="event-type"/>
		</div>
	</div>

	<div class="timeline" ref="timeline" :style="seekPercentCSS">
		<div class="seek-bar" @mousedown="selectorSeek" />
		<div class="controls">

			<div class="button play-pause" @click="togglePlayback">
				<i v-if="!isPlaying" class="fas fa-play" />
				<i v-else class="fas fa-pause" />
			</div>

			<div class="button" @click="$emit('seek', seekPosition - 1)"><i class="fas fa-chevron-left" /></div>
			<div class="button" @click="$emit('seek', seekPosition + 1)"><i class="fas fa-chevron-right" /></div>

			<div v-for="speed in speedOptions" v-bind:key="speed.value" style="margin-top:3px;">
				<div class="button" :style="`${(selectedSpeed===speed.value)?'background-color: #2876D4;color:white;padding-top:3px;':'padding-top:3px'}`" @click="$emit('updatePlaybackRate', speed.value);selectedSpeed=speed.value"> {{speed.value}}x</div>
			</div>

			<div style="margin-left: 10px; position: relative;top:1px;">
				<div class="button" @click="$emit('resetZoom')">
					<i class="fas fa-sync"/>
				</div>
			</div>
			<div class="ml-auto" style="padding-top:10px;">
				<div style="display: flex;flex-direction: row;">
					<p class="duration-display" style="font-size:15px;">
						<code>{{formatSeconds(hoverPosition)}}</code>
					</p>
				</div>

			</div>

			<div class="ml-auto" style="padding-top:10px;">
				<div style="display: flex;flex-direction: row;">
					<p class="duration-display" style="font-size:15px;margin-right:10px;">
						<code style="color: #2876D4">{{formatSeconds(seekPosition)}} / {{formatSeconds(duration)}}</code>
					</p>
				</div>
			</div>
		</div>


		<div class="x-axis" :style="`--lastWidth: ${xAxisLastWidth}%`">
			<div v-for="label in xAxisLabels" :key="label" class="label">
				<div class="text" style="">{{ label }}</div>

				<div class="tick" />
			</div>
		</div>

		<div class="selector"
			@mousemove="[focusHover($event), dragAction($event)]"
			@mousedown="[selectorSeek($event), dragStart($event)]"
			@mouseup="selectedByDrag"
		>
			<div class="focus" />
			<div class="focus-hover"/>

			<template v-if="showSelection">
				<div class="selection" :style="selectionTopCSS">
					<div class="draggable-start" />
					<div class="draggable-end" />
				</div>
			</template>

			<div class="events">

				<!-- TODO fix y-index position, as a workaround highlighted events in label list are shown through a focused/highlighted event -->

				<!-- <div v-if="currentHighlightedEvent!==null && showTimelineEventInfo" class="tag-editor" :style="eventHighLightCSS">
					<div class="tag-editor-content">
						<div style="display: flex; flex-direction: column; padding:10px">
							<code style="font-size:10px;margin-bottom:10px;">EVENT INFO</code>
							<code>Created By: {{currentHighlightedEvent.createdBy}}</code>
							<code>Created At: {{new Date(currentHighlightedEvent.createdAt).toISOString()}}</code>
						</div>


					</div>
				</div> -->

				<div v-for="event in timelineEvents" :key="event.id" class="selection" :style="convertEventToSeekCSS(event)"/>
			</div>

			<div class="timeline-event-grid">
				<div v-for="type in eventTypes" class="" :key="type" />
			</div>

		</div>
	</div>
</div>
