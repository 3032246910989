<template>
<!-- Use v-b-modal directive and assign an ID to this component to open it -->
<b-modal
	ref="modal"
	centered
	no-close-on-backdrop
	no-stacking
	size="md"
	:id="id"
	:ok-disabled="hasInvalidSettings || loading"
	ok-variant="info"
	v-bind="$attrs"
	@ok="GenerateAgents"
>
	<!-- Title -->
	<template #modal-title>Generate New Agents</template>

	<!-- Instructions -->
	<b-overlay :show="loading" variant="transparent" :opacity="1" blur="1px">
		<div class="instructions">
			<p>Agents will be generated with a random email and password.</p>
			<p>Newly generated passwords can be exported via the table view (<i class="fas fa-download fa-1x"/>), until the page is refreshed or this admin user is logged out.</p>
		</div>

		<div class="settings">
			<b-form-group label="Num. Agents" label-for="agent-count" label-cols="3">
				<b-input id="agent-count" type="number" min="1" :max="maxCount" v-model="numberOfAgents" step="1" :state="(validCount === false) ? false : null"/>
				<b-invalid-feedback>Number must be between 1 and {{maxCount}}</b-invalid-feedback>
			</b-form-group>

			<b-form-group label="Task Type" label-for="agent-type" label-cols="3">
				<b-select id="agent-type" v-model="selectedAgentType" :options="agentTypes"/>
			</b-form-group>
		</div>

	</b-overlay>

	<template #modal-ok>Create</template>
</b-modal>
</template>

<script>
import {
	BFormGroup,
	BFormSelect as BSelect,
	BFormInput as BInput,
	BFormInvalidFeedback as BInvalidFeedback,
	BModal
} from 'bootstrap-vue';

export default {
	name: 'agent-creation-modal',

	props: {
		id: {required: true},
	},

	components: {
		BFormGroup,
		BInput,
		BInvalidFeedback,
		BModal,
		BSelect,
	},

	data: () => ({
		selectedAgentType: null,
		numberOfAgents: 1,
		maxCount: 1000, // TODO: Should come from server config
		loading: false
	}),

	computed: {
		agentTypes()
		{
			return [
				{text: 'Please select an option...', value: null},
				...Object.entries(this.$store.state.admin.agentTypeFormat)
					.flatMap(([value, text]) => {
						if (value === 'admin') return []; // ignore admin option for now
						return [{value, text}];
					})
			]
		},

		validCount()
		{
			return this.numberOfAgents >= 1 && this.numberOfAgents <= this.maxCount;
		},

		hasInvalidSettings()
		{
			return !this.selectedAgentType || !this.validCount;
		}
	},

	methods: {
		/**
		 * GenerateAgents
		 * Requests creation of new agents.
		 */
		async GenerateAgents(e)
		{
			// Prevent closing the modal
			e.preventDefault();

			this.loading = true;
			const success = await this.$store.dispatch('admin/GenerateAgents', {
				count: this.numberOfAgents,
				accountType: this.selectedAgentType
			});

			if (!success)
			{
				this.$noty.error('Failed to generate agent(s).')
			} else
			{
				// Close the modal manually
				await this.$nextTick();
				this.$root.$emit('bv::hide::modal', this.id);
			}
			this.loading = false;
		}
	}
}
</script>


<style scoped>
::v-deep .instructions p + p {
	margin-top: -0.5rem;
}
</style>