

<div>
	<h4>Audit agent work for invoicing</h4>
	
	<div class="my-2">
		<button
			@click="aggregateOnUser=!aggregateOnUser"
			class="btn btn-outline-success"
			:class="{active: aggregateOnUser}"
		>
			Aggregate On User
		</button>
	</div>

	<div class="date-range my-4">
		<b-form-input v-model="auditBillingStart" placeholder="Billing period start (YYYY-MM-DD)"></b-form-input>
		<i class="fas fa-arrow-right fa-1x my-2 mx-4"/>
		<b-form-input v-model="auditBillingEnd" placeholder="Billing period end (YYYY-MM-DD)"/>
	</div>
	
	<div>
		<b-form-select v-model="datasetSelected" :options="datasets.map(r=>r.datasetName)" placeholder="Select Dataset"/>
	</div>
	
	<div class="my-2">
		<b-overlay
			:show="loadingAuditResults"
			rounded
			opacity="1"
			spinner-small
			spinner-variant="info"
			class="d-inline-block"
			@hidden="onHidden"
		>
			<b-button variant="outline-success" @click="retrieveAudit(auditBillingStart, auditBillingEnd)">
				Generate Audit
			</b-button>
		</b-overlay>
		<button
			v-if="auditResults.length>0"
			@click="saveAsCSV()"
			class="btn btn-outline-success mx-2">
			<i class="fas fa-save fa-1x"/>
		</button>
	</div>

	<div v-if="auditResults.length>0 && aggregateOnUser" class="audit-summary my-3">
		<h1>{{moment(auditBillingStart).format('MMMM Do YYYY')}} to {{moment(auditBillingEnd).format('MMMM Do YYYY')}}</h1>
		<p>{{auditSummary}}</p>
	</div>

	<b-table
		striped hover
		:items="auditResults"
	/>

</div>

