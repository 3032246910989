<template>
<div id="review" :key="'review_' + trackUuid" class="fill">

	<div class="fill" style="display: flex; flex-direction: row; width: 100%;margin-left:-287px;">
		<div class="sidebar">
			<div style=" height: 100%;padding: 15px;">
				<h6 style="color:white;">Event Labels</h6>
				<ul class="list-group list-group-flush event-labels">
					<li v-for="label in labels.filter(r=>r.type==='event')" class="list-group-item"  v-bind:key="label.labelTitle" @click="addLabel(label)">
						{{label.labelTitle}}
					</li>
				</ul>
				<h6 style="color:white;">Tag Labels</h6>
				<ul class="list-group list-group-flush tag-labels">
					<li v-for="label in labels.filter(r=>r.type==='tag')" class="list-group-item"  v-bind:key="label.labelTitle" @click="addLabel(label)">
						{{label.labelTitle}}
					</li>
				</ul>
			</div>
		</div>

		<div class="editor-container">
			<div class="settings">
				<div style="float: right;">
					<button
						class="btn btn-outline-warning btn-sm"
						:class="{flagSubmitted: trackFlagActive['videoNotLoading']}" style="margin-right:10px;"
						@click="reportIssue('videoNotLoading')"
					>
						<i class="ml-1 fas fa-exclamation-triangle text-warning"/>
						{{`${(trackTypeComments.includes("videoNotLoading"))? 'Video Link Missing Reported' : 'Report Missing Video'}`}}
					</button>

					<button
						class="btn btn-outline-warning btn-sm"
						:class="{flagSubmitted: trackFlagActive['trackIssues']}"
						@click="reportIssue('trackIssues')"
					>
						<i class="ml-1 fas fa-unlink text-warning" style="margin-right:5px;"/>
						{{`${(trackTypeComments.includes("trackIssues"))? 'Track Issues Reported' : 'Report Track Issues'}`}}
					</button>

					<button class="btn btn-outline-info btn-sm" style="margin-left:40px;"> Submit </button>
				</div>
			</div>

			<div style="width:100%; height:100%">
				<!-- Hidden video element to proxy video into canvas -->
				<video
					v-if="videoSrc"
					class="d-none"
					ref="video"
					:src="videoSrc"
					muted
					preload="auto"
					@canplay="canPlay(firstLoad)"
					@timeupdate="videoCurrentTime = video.currentTime"
					@durationchange="videoDuration = Math.ceil(video.duration)"
				/>

				<!-- Konva video display -->
				<div class="video-container" :style="`width: ${videoContainerWidth}px; height: ${videoContainerHeight}px;position:relative;left:249px;`">
					<v-stage
						:config="{
							width: videoContainerWidth,
							height: videoContainerHeight
						}"
						ref="stage"
						@wheel="zoom"
					>
						<v-layer ref="layer">
							<v-image :config="konvaVideoConfig" @click="togglePlayback" />
						</v-layer>

					</v-stage>
					<div class="label-info-pane">

						<div class="label-info-pane-buttons" style="padding:10px;">
							<button v-if="selectedCreatedLabel!==''" class="btn btn-outline-info btn-sm" :class="{active: !showCreatedLabelList}" @click="showCreatedLabelList=true;selectedCreatedLabel=''"><i class="fas fa-arrow-left"/> Back</button>
						</div>

						<div v-if="showCreatedLabelList">


							<div style="padding: 15px;display: flex;flex-direction: column;">

								<ul class="list-group list-group-flush" style="margin-top:10px;" v-if="createdLabels.length>0">
									<li v-for="(label) in createdLabels.filter(r=>eventTypes.includes(r.type))" class="list-group-item" :key="label.id"
										style="background-color: #1A282C"
										@click="labelSelectedFromList(label);showCreatedLabelList=false;"
										@mouseover="labelHoverAction(label)">
										{{label.type}}  {{formatSeconds(label.start)}} - {{formatSeconds(label.end)}}


										<div v-if="label.unconfirmed" class="label-item">
											<i class="ml-1 fas fa-exclamation-circle text-danger" style="margin-right:5px;"/>
											<p> Addional Info Required </p>
										</div>
										<div v-if="!label.unconfirmed && label.isLocal" class="label-item">
											<i class="ml-1 fas fa-check text-success" style="margin-right:5px;"/>
											<p> Saved </p>
										</div>
										<div v-if="!label.isLocal" class="label-item">
											<i class="ml-1 fas fa-user-circle text-success" style="margin-right:8px;margin-top:1px;"/>
											<p> created by {{label.user}} </p>
										</div>


									</li>
									<li v-for="(label) in createdLabels.filter(r=>tagTypes.includes(r.type))" class="list-group-item" :key="label.id"
										style="background-color: #1A282C"
										@click="labelSelectedFromList(label);showCreatedLabelList=false;"
									>
										{{label.type}}: {{label['Items Taken']}}

										<div v-if="label.unconfirmed" class="label-item">
											<i class="ml-1 fas fa-exclamation-circle text-danger" style="margin-right:5px;"/>
											<p> Addional Info Required </p>
										</div>
										<div v-if="!label.unconfirmed && label.isLocal" class="label-item">
											<i class="ml-1 fas fa-check text-success" style="margin-right:5px;"/>
											<p> Saved </p>
										</div>
										<div v-if="!label.isLocal" class="label-item">
											<i class="ml-1 fas fa-user-circle text-success" style="margin-right:8px;margin-top:1px;"/>
											<p> created by {{label.user}} </p>
										</div>


									</li>
								</ul>

								<ul class="list-group list-group-flush" style="margin-top:10px;" v-else>
									<li class="list-group-item no-labels">
										No labels found
									</li>

								</ul>

							</div>
						</div>

						<div v-if="selectedCreatedLabel!==''" style="height:90%;">

							<div style="padding: 15px;height:89%">
								<div class="card mb-3" style="background-color: transparent;height: 100%;;">
									<div class="card-body">
										<div v-if="eventTypes.includes(selectedCreatedLabel.type)" class="card-title">
											{{selectedCreatedLabel.type}}:
											{{formatSeconds(selectedCreatedLabel.start)}} -
											{{formatSeconds(selectedCreatedLabel.end)}}
										</div>

										<div v-else class="card-title">
											{{selectedCreatedLabel.type}}
										</div>
										<div style="display: flex; flex-direction: column;">
											<div>
												<h6 v-if="eventTypes.includes(selectedCreatedLabel.type)" style="margin-bottom: 15px;">Event Details</h6>
											</div>

											<div v-for="fieldValue in labels.filter(r=>r.labelTitle===selectedCreatedLabel.type)[0].additionalInfo" :key="fieldValue.field">
												<div style="display: flex; flex-direction: row;width:fit-content;">
													<div style="float: left;">
														{{fieldValue.field}}:
													</div>
													<div style="position: relative; left: 100px;">
														<select class ="form-control" style="width: fit-content" v-bind:id="'plot-event-location-'"
															name="plot-event-location" v-model="selectedCreatedLabel[fieldValue.field]">
															<option v-for="(location, element_index) in fieldValue.values"
																v-bind:key="'temp-event-location-'  + '-' + element_index" v-bind:value="location" >
																{{ location }}
															</option>
														</select>
													</div>
												</div>
											</div>
											<!-- TODO: Implement a comment system -->
											<!-- <div class="comments" style="position: relative; top:100px">

												<h6>Comments</h6>

												<div class="card mb-3">
													<div class="card-body">
														<p>ambiguous event, not too sure here, please review</p>
													</div>
												</div>

											</div> -->
										</div>
										<div v-if="!selectedCreatedLabel.isLocal" class="created-by">
											<button class="btn btn-outline-info btn-sm" style="color:white;" @click="saveLabel(selectedCreatedLabel)"> Save </button>
											<button class="btn btn-outline-info btn-sm error" style="margin-left :10px;border:1px solid red;color:white" @click="deleteLabel(selectedCreatedLabel)"> Delete </button>
											<div class="card-subtitle text-muted" style="margin-top:10px;">
												Created by {{this.username }} at {{new Date(selectedCreatedLabel.createdAt).toISOString()}}
											</div>
										</div>

										<div v-else class="created-by">
											<button class="btn btn-outline-info btn-sm" style="color:white;" @click="saveLabel(selectedCreatedLabel)"> Save </button>
											<button class="btn btn-outline-info btn-sm error" style="margin-left :10px;border:1px solid red;color:white" @click="deleteLabel(selectedCreatedLabel)"> Delete </button>
											<div class="card-subtitle text-muted" style="margin-top:10px;">
												Created by {{selectedCreatedLabel.createdBy }} at {{selectedCreatedLabel.createdAt}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Timeline section -->
				<div style="width:100%; ">
					<Timeline
						v-if="videoLoaded"
						ref="timeline"
						v-model="seekPosition"
						@play="play"
						@pause="pause"
						@createTemporaryEvent="createTemporaryEvent"
						@updatePlaybackRate="updatePlaybackRate"
						:duration="videoDuration"
						:playing="videoPlaying"
						:eventTypes="eventsSelected"
						@resetZoom="resetZoom"
						@hook:created="retrieveLabels()"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Timeline from '@/components/video/Timeline.vue';
import { v4 as uuidv4 } from 'uuid';
import {formatSeconds} from '@/utils/strings.js';
import RealTimeValue from "@/components/common/RealTimeValue.vue";
import { dataAPI } from "../http-common";
export default {
	name: 'ReviewTrack',

	components: {
		Timeline
	},

	props: {
		// track: {type: Object},
		
	
		stage: {type: Number, default: 0},
		requestViaUrl: {type: Boolean, default: false},
		username: {type: String},
		videoLabelPickups: {type: Boolean, default:true},
		taskType: {type: String, default: ''},

		datasetUuid: {type: String, default: ""},	
		trackUuid: {type: String, default: ""},	
		type: {type: String, default: "global"}
		
	},

	data: function() {
		return {
			video: null,
			konvaUpdater: null,
			videoLoaded: false,
			videoPlaying: false,
			videoDuration: 0,
			videoContainerWidth: 1920 / 2,
			videoContainerHeight: 1080 / 2,
			videoCurrentTime: 0, // set directly only to avoid calling seek,
			videoSpeed: 1.0,
			firstLoad: true,
			labels: [
				{labelTitle: 'Checkout', type: 'event'},
				{labelTitle: 'Interaction', type: 'event'},
				{labelTitle: 'Dwelling', type: 'event'},
				{labelTitle: 'Walking', type: 'event'},
				{labelTitle: 'Inspecting', type: 'event'},
				{labelTitle: 'Viewing Shelf', type: 'event'},
				{labelTitle: 'Items Taken', type: 'tag', additionalInfo: [{field:'Items Taken', values: Array.from(Array(101).keys())}]},
				{labelTitle: 'Age', type: 'tag', additionalInfo: [{field:'Age', values: ['0-5', '6-10', '11-20', '21-30', '>90']}]},
				{labelTitle: 'Gender', type: 'tag', additionalInfo: [{field:'Gender', values: ['Male', 'Female', 'Other']}]},
				// {labelTitle: 'Custom', type: 'tag'},
			],
			temporaryEvents: [],
			eventSelected: [],
			eventsSelected: [],
			startTimestamp: null,
			videoY: 0,
			videoX: 0,
			createdLabels: [],
			selectedCreatedLabel: '',
			showCreatedLabelList: true,
			eventLocation: '',
			trackComments: null,
			videoSrc: "",
			db: "",
			
		}
	},

	computed: {
		seekPosition: {
			get() {
				return this.videoCurrentTime;
			},
			set(seekPosition) {
				this.videoCurrentTime = seekPosition;
				this.seek(seekPosition);
			}
		},
		eventTypesToDisplay(){
			return this.eventsSelected;
		},
		// videoSrc() {
		// 	// return `https://blackdatastoragevideos.blob.core.windows.net/videos/${this.trackUuid}${(this.taskType!=='') ? '_'+this.taskType : ''}.mp4`;
		// 	return `https://supervisor.black.ai:4205/${this.trackUuid}${(this.taskType !== '') ? '_' + this.taskType : ''}.mp4`;
		// },
		mostRecentlyCreatedEvent(){
			return this.createdLabels.slice(-1)[0];
		},
		konvaVideoConfig() {
			return {
				image: this.video,
				// draggable: true,
				x: this.videoX,
				y: this.videoY,
				width: this.videoContainerWidth,
				height: this.videoContainerHeight,
			};
		},
		tagTypes(){
			return this.labels.filter(r=>r.type==='tag').map(r=>r.labelTitle);
		},
		eventTypes(){
			return this.labels.filter(r=>r.type==='event').map(r=>r.labelTitle);
		},
		trackTypeComments(){
			const comments  = this.trackComments??false;
			if(!comments){
				return '';
			}
			else{
				return JSON.parse(this.trackComments)[this.taskType];
			}
		},
		trackFlagActive(){
			const flags = ['videoNotLoading', 'trackIssues'];

			return flags.reduce((obj, flag)=>{
				obj[flag]=this.trackTypeComments.includes(flag);
				return obj;
			}, {});
		},
	},
	watch: {
		videoSpeed(newVal){
			this.video.playbackRate = newVal;
		}
	},
	mounted: async function () {
		const { datasetUuid, trackUuid } = this;
		console.log("start")
		await this.retrieveDatasetInfo(datasetUuid);
		await this.retrieveTrackVideoURL({datasetUuid, trackUuid});
		// await this.retrieveStartTime({trackUuid: this.trackUuid, type: this.type});
		console.log("end")
		this.video = this.$refs.video;

		this.konvaUpdater = new Konva.Animation(() => {}, this.$refs.layer.getNode()); // do nothing, forces timely re-render
		
		const self = this; // i hate this wtf is this doing here
		document.body.onkeyup = function(e){
			if(e.keyCode === 32){
				self.togglePlayback();
			}
		}


	},

	beforeRouteUpdate (to, from, next) {
		let uuid = to.params.uuid;
		if (uuid) {
			console.log("Updating ReviewTrack component data for 'track' object.");
			// TODO: reinsert these
			//this.resetTrack(uuid);
			// this.retrieveStartTime(uuid);
		} else {
			console.log("Cannot update ReviewTrack component data; no 'to.params.uuid'");
		}
		next();
	},

	methods: {
		zoom(e) {
			const scaleBy = 1.5;
			e.evt.preventDefault();
			const stage = this.$refs.stage.getStage();

			const oldScale = stage.scaleX();
			console.log(oldScale);
			const pointer = stage.getPointerPosition();
			const mousePointTo = {
				x: (pointer.x - stage.x()) / oldScale,
				y: (pointer.y - stage.y()) / oldScale,
			};
			const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
			stage.scale({ x: newScale, y: newScale });
			const newPos = {
				x: pointer.x - mousePointTo.x * newScale,
				y: pointer.y - mousePointTo.y * newScale,
			};

			stage.position(newPos);
		},
		resetZoom() {

			const stage = this.$refs.stage.getStage();
			const layer = this.$refs.layer.getNode();
			const newPos = {
				x: 10,
				y: 10
			};

			stage.scale({ x: 1, y: 1 });
			// stage.position({x: -10, y:-10})
			// stage.position(newPos);
			stage.position({ x: 0, y: 0 });
		},
		togglePlayback() {
			this.videoPlaying = !this.videoPlaying;
			if (this.videoPlaying) {
				this.play();
			} else {
				this.pause();
			}
		},

		play() {
			console.log("being played")
			this.videoPlaying = true;
			this.video.play();
			this.konvaUpdater.start();
		},

		pause() {
			this.videoPlaying = false;
			this.video.pause();
			this.konvaUpdater.stop();
		},

		seek(positionSeconds) {
			console.log('seek being called', positionSeconds)
			this.video.currentTime = positionSeconds;
		},

		canPlay(firstTime){
			console.log('firstTime',  firstTime)
			if(firstTime){
				console.log('can play being called')
				this.videoLoaded = true;
				// this.video.pause();
				this.play();
				// this.video.currentTime = 0;
				this.firstLoad = false;
			}


		},


		beforeRouteUpdate (to, from, next) {
			let uuid = to.params.uuid;
			if (uuid) {
				// this.retrieveStartTime(uuid);
			} else {
				console.log("Cannot update ReviewTrack component data; no 'to.params.uuid'");
			}
			next();
		},

		retrieveStartTime: async function({trackUuid, type}){
			try{
				if(type==="merge"){
					const {startTime} = (await dataAPI.post(`/${this.db}/detections/getStartTime`, {trackUuid})).data;
					this.startTimestamp = startTime;
				}
				else if(type==="global"){
					const { data: events } = await dataAPI.get(`/${this.db}/events/dataset/${this.datasetUuid}/track/${trackUuid}`);
					// from the interaction input table, start time comes from a join on track summary, so all rows will have the same trackStart when filtered by trackUuid
					const {trackStart: startTime} = events[0]; 
					this.startTimestamp = startTime;
				}
			}
			catch(error){
				this.$noty.warning(`Unable to retrieve start timestamp for track ${this.trackUuid}: ${error}` ,{layout: "bottomLeft"})
			}
		},

		createTemporaryEvent(event){
			event.unconfirmed=true;
			event.isLocal=true;
			this.createdLabels.push(event)
		},

		addSecondsToStartTime(seconds){
			let startTimestamp = new Date(Date.parse(this.startTimestamp+"+00:00"));
			startTimestamp.setSeconds(startTimestamp.getSeconds()+seconds);
			return startTimestamp.toISOString();
		},

		updatePlaybackRate(speed){
			this.video.playbackRate=speed;
		},

		addLabel(label){
			if(label.type === "event"){
				if(this.eventsSelected.findIndex(r => r === label.labelTitle)<0){
					this.eventsSelected.push(label.labelTitle);
				}
			}
			if(label.type === "tag"){
				const labelToAdd ={ id: Date.now(), type: label.labelTitle, start: null, end: null, createdAt: Date.now(), unconfirmed: true, createdBy: this.username, isLocal: true}
				this.createdLabels.push(labelToAdd)
			}
		},

		formatSeconds(seconds){
			return formatSeconds(seconds);
		},

		labelSelectedFromList(label){
			this.selectedCreatedLabel = label;
		},

		async saveLabel(label){

			this.createdLabels.filter(r=>r.id===label.id)[0].unconfirmed = false;
			this.createdLabels.filter(r=>r.id===label.id)[0].isLocal = true;

			this.$refs.timeline.clearEventSelection();

			if(label.type==="Items Taken"){

				let page = this;
				const body = {
					user: this.username,
					trackUuid: this.trackUuid,
					type: 'pickup video labelling',
					stage: 1,
					points: parseInt(label['Items Taken']),
					comment: '',
					status: 'unreviewed'
				};

				try{
					const result = (await dataAPI.post(`/${page.db}/track-status`,body)).data;
					this.$noty.info('Label Submitted', {layout: 'bottomLeft'});
				}
				catch(error){
					this.$noty.warning(`Could not save label: ${error}`, {layout: 'bottomLeft'});
				}


			}

			if(this.eventTypes.includes(label.type)){
				this.postNewEvent(label);
			}
			this.showCreatedLabelList=true;
			this.selectedCreatedLabel='';

		},

		async deleteLabel(label){
			this.$refs.timeline.deleteEvent(label.id);
			this.showCreatedLabelList=true;
			this.selectedCreatedLabel='';
			this.createdLabels=this.createdLabels.filter(r => r.id!==label.id);

			try{
				if(this.eventTypes.includes(label.type)){
					const url = `/${this.db}/interactions/delete`;
					const result = await dataAPI.post(url, {eventUuid: label.id});
				}
				this.$noty.info('Label Deleted', {layout: 'bottomLeft'});
			}
			catch(error){
				this.$noty.error(`Deletion Failed: ${error}`, {layout: 'bottomLeft'});
			}
		},

		labelHoverAction(label){
			const eventTypes = this.labels.filter(r=>r.type==='event').map(r=>r.labelTitle);
			if(eventTypes.includes(label.type)){
				// TODO update with username info from historial events
				label.createdBy=this.username;
				this.$refs.timeline.highlightTimelineEvent(label);
			}
		},

		postNewEvent: async function (event) {

			try{
				const url = `/${this.db}/events/track/${this.trackUuid}`;
				const start = this.addSecondsToStartTime(event.start);
				const end = this.addSecondsToStartTime(event.end);
				const {data} = await dataAPI.post(url, {
					detectionUuids: 'N/A',
					type: 'interaction',
					category: event.type,
					location: event.zone,
					sourceData: 'supervisorUI',
					uniqueEventUuid: uuidv4(),
					startTimestamp: start,
					endTimestamp: end
				});

				const eventUuid = data.uuid;
				this.postEventStatus(eventUuid, "Created", "track", this.username);

				this.$noty.info('Event successfully created!', {layout:'bottomLeft'});

			}
			catch(error){
				this.$noty.error(`Failed to save event ${error}. Please try again.`, {layout:'bottomLeft'})
			}
		},
		postEventStatus: async function (eventUuid, status, eventTypeString, user) {

			const url = `/${this.db}/events/${eventTypeString}-status/${eventUuid}`;
			let body = {
				user: user,
				status: status,
				confidence: this.confidence,
				comment: this.comments
			};
			try{
				await dataAPI.post(url, body);

			}
			catch(error){
				console.log('Error in submitting event status', )
			}
		},

		async retrieveLabels(){
			// console.log('exists?',this.$refs.timeline);
			if(this.taskType==='pickup'){
				this.getTagLabels();

			}
			else{
				this.getTagLabels();

				this.getEventLabels();

			}


		},
		async getTagLabels(){
			try{
				const url = `/${this.db}/events/status/track/${this.trackUuid}`;
				const {data} = (await dataAPI.get(url));
				const {points, comment} = data;
				this.trackComments = comment;
				if(data!=="" && points!==null){

					this.createdLabels.push({
						isLocal: false,
						user: data.user,
						unconfirmed: false,
						"Items Taken": points,
						createdAt: data.timestamp,
						type: 'Items Taken',
						eventID: Date.now(),
					})
				}
			}
			catch(error){
				this.$noty(`Unable to retrieve video tags: ${error}`);
			}
		},
		async getEventLabels(){
			try{
				const v2url = `/${this.db}/interactions/track/${this.trackUuid}`;
				await this.retrieveStartTime({trackUuid: this.trackUuid, type: this.type});
				const {data} = (await dataAPI.get(v2url, { params: {pageSize: 500}}));
				const events = data.map(r=>{
					return {
						checkoutEnd: r.endTimestamp,
						checkoutStart: r.startTimestamp,
						tillLocation: r.location,
						eventID: r.uuid,
						// TODO update backend db schema to have both created at and updated at
						isLocal: false,
						user: r.user,
						createdAt: r.updatedAt,
						type: r.category
					}
				})

				events.forEach(event => {
					event.id = event.eventID;
					event.unconfirmed = false;

					event.start = this.convertTimestampToTimelineSeconds(event.checkoutStart);
					event.end = this.convertTimestampToTimelineSeconds(event.checkoutEnd);
					event['Till'] = event.tillLocation;

					this.createdLabels.push(event);
					console.log(this.$refs.timeline)
					this.$refs.timeline.timelineEvents.push(event);

				})
			}
			catch(error){
				this.$noty(`Unable to retrieve event labels: ${error}`);
			}
		},
		convertTimestampToTimelineSeconds(ISOTimestamp){
			const startTime = this.startTimestamp;
			const startTimeUNIX = new Date(startTime).getTime();
			const ISOTimestampInUNIX = new Date(ISOTimestamp.replace('Z','')).getTime();
			const seconds = Math.ceil((ISOTimestampInUNIX-startTimeUNIX)/1000);
			return seconds;
		},
		// on submit
		trackDone: async function(){
			await this.removeFromPool(this.trackUuid);
			await this.next();

			// this.track.done = true;
			console.log("End of trackDone fn.");
		},
		removeFromPool: async function(uuid){
			try {
				const { data } = await dataAPI.post(`/${this.db}/removeFromGestureJobPool/`, {foreignUuid: uuid, user: this.username});
				return data;
			} catch(error) {
				this.$noty.error(`Error removing ${uuid} from task pool: ${error}`)
			}
		},
		reportIssue(issue){
			try{
				const url = `/${this.db}/track-status/reportIssue`;
				const issueObj = JSON.parse(this.trackComments??"{}");
				issueObj[this.taskType]=issue;

				dataAPI.post(url, {trackUuid: this.trackUuid, user: this.username, trackIssue: issueObj});
				this.trackComments = JSON.stringify(issueObj);
				this.$noty.info(`Issue: ${issue} has been reported!`)
			}
			catch(error){
				this.$noty.info(`Falied to report issue: ${issue}, ${error}`)
			}
		},
		async retrieveTrackVideoURL({trackUuid, datasetUuid}){
			try{
				const { data: signedURL } = await (dataAPI.post('/video/retrievePortalTrackVideo', {trackUuid, datasetUuid}));
				this.videoSrc = signedURL;	
			}
			catch(error){
				this.$noty.error('Failed to retrieve track video', {layout: "bottomLeft"});
			}
		},
		async retrieveDatasetInfo(datasetUuid){
			try{
				const { data: datasetInfo } = await (dataAPI.get('/datasets/getDatasetInfo', {params: {datasetUuid}}));
				const { dbName: db, labellingConfig } = datasetInfo;

				const zones_of_interest =  labellingConfig?.zones_of_interest;
				if(zones_of_interest){
					this.labels.forEach(label => {
						const { type } = label;
						if(type === 'event'){
							label['additionalInfo'] = [{field: "zone", values: zones_of_interest}];
						}
					}) 	
				}
				this.db = db;
			}
			catch(error){
				this.$noty.error('Failed to retrieve dataset info', {layout: "bottomLeft"});
			}
		}


		// labels: [
		// {labelTitle: 'Checkout', type: 'event'},
		// {labelTitle: 'Interaction', type: 'event'},
		// {labelTitle: 'Dwelling', type: 'event'},
		// {labelTitle: 'Walking', type: 'event'},
		// {labelTitle: 'Inspecting', type: 'event'},
		// {labelTitle: 'Viewing Shelf', type: 'event'},
		// {labelTitle: 'Items Taken', type: 'tag', additionalInfo: [{field:'Items Taken', values: Array.from(Array(101).keys())}]},
		// {labelTitle: 'Age', type: 'tag', additionalInfo: [{field:'Age', values: ['0-5', '6-10', '11-20', '21-30', '>90']}]},
		// {labelTitle: 'Gender', type: 'tag', additionalInfo: [{field:'Gender', values: ['Male', 'Female', 'Other']}]},
		// {labelTitle: 'Custom', type: 'tag'},
		// ],
	}

}
</script>


<style scoped>

.event-labels {
	margin-top: 10px;
	overflow-y: auto;
	height: 55%;
	margin-bottom: 5px;
}

.tag-labels {
	margin-top: 10px;
	overflow-y: auto;
	height: 50%;
}

.editor-container {
	display: flex;
	flex-direction: column;
	margin-left: 0px;
	height: 40vh;
	width: 100%;
}
.label-item {
	width: 150px;
	height: 27px;
	float: right;
	display: flex;
	flex-direction: row;
	padding-top: 4px;
}
.list-group-item.no-labels {
	border: 1px solid #1A282C;
	background-color: #1A282C
}

.card-title {
	color: white;
	border-bottom: 1px solid #007FFF;
	padding-bottom:10px;
}

.created-by{
	font-size:12px;
	position: absolute;
	bottom: 10px;
	border: 1px solid transparent;
}

.label-item p {
	font-size: 12px;
}

.fill {
	width: 100%;
	height: 89vh;
}
.settings {
	padding: 10px;
	background-color: #1A282C;
	/* border: 1px solid #d8dee6da; */
	width: 100%;

	/* border-radius: 5px; */
	height: 50px
}
.sidebar {
	flex-direction: column;
	height:590px;
	background-color: #1A282C;
	width: 284px;
	position: relative;
	left: 248px;
	/* border: 1px solid #d8dee6da; */

}
.label-info-pane{
	/* flex-direction: column; */
	height:540px;
	overflow-y: auto;
	background-color: #001014;
	border-right: 1px solid #1A282C;
	width: 405px;
	position: relative;
	left: 961px;
	bottom: 540px;
	/* border:1px solid red; */
}

.label-info-pane code {
	font-weight:  50;
	color: white;
	font-size: 20px;
}


.list-group-item:hover {
	border-left: 2px solid #2876D4;
}

.list-group-item {
	border-left: 2px solid transparent;
}


.btn.btn-outline-info.btn-sm.error:hover{
	background-color: red;
}
.btn.btn-outline-warning.btn-sm.flagSubmitted{
	border: 1px solid transparent;
}
.btn.btn-outline-warning.btn-sm.flagSubmitted:hover{
	background-color: transparent;
}
/*  */

</style>