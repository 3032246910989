<template>
<section class="workflow-container">
	<div class="sidebar">
		<h6>Labels</h6>
		<b-card v-for="label in labels.filter(r=>r.type==='classification')" :key="label.labelTitle" :header="label.labelTitle">
			<b-list-group v-for="field in label.fields" :key="field">
				<b-list-group-item
					href="#"
					:class="{active: createdLabels[label.labelTitle] === field}"
					@click="fieldSelected(label.labelTitle, field)"
				>
					{{field}}
				</b-list-group-item>
			</b-list-group>
		</b-card>
	</div>

	<div class="right-container">
		<div class="settings">

			<div v-if="containsMultipleImages" class="info-section">
				{{labellingInputData.uuidsToLabel.length}} remaining
				<div class="pagination">
					Multiple Angles Available:
					<b-pagination
						v-model="currentImageIndex"
						:total-rows="supportingData[currentUuid].length"
						:per-page="1"
					/>
				</div>
			</div>
			<div class="ml-auto">
				<b-button variant="outline-warning" size="sm" @click="resetZoom()">
					<i class="fas fa-sync"/>
				</b-button>
				<b-button variant="outline-info" class="ml-4" size="sm" @click="submitLabel(currentUuid, createdLabels)">
					Submit
				</b-button>
			</div>

		</div>

		<div v-if="activeImage" class="image-container" :style="`width: ${stageHeight}px; height: ${stageWidth}px;`">
			<v-stage ref="stage" :config="{height: stageHeight, width: stageWidth}" @wheel="zoom">
				<v-layer ref="layer">
					<v-image :config="{
						image: activeImage,
						height: stageHeight,
						width: stageWidth
					}"
					/>
					<v-rect :config="{
						stroke: 'red',
						strokeWidth: 5,
						x: activeSubframeBounds.left,
						y: activeSubframeBounds.top,
						width: activeSubframeBounds.width,
						height: activeSubframeBounds.height,
					}" />
				</v-layer>
			</v-stage>
		</div>
	</div>
</section>
</template>

<script>
import { createImageSizeMap, scaleSubframeBoundsToDisplay } from '@/utils/images';
import { dataAPI } from "@/http-common";
import { BListGroup, BCard, BListGroupItem, BButton, BPagination } from 'bootstrap-vue';

export default {
	name: 'ImageClassifier',

	props: {
		taskUuid: {type: String, default: ''},
	},
	components: {
		BPagination,
		BListGroup,
		BCard,
		BListGroupItem,
		BButton,
	},

	data: function() {
		return {
			// TODO make this configurable from explorer
			labels: [
				{labelTitle: 'Age', type: 'classification', fields: ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60+']  },
				{labelTitle: 'Gender', type: 'classification', fields: ['Male', 'Female', 'Not sure']  },
			],
			createdLabels: {},
			labellingInputData: {},
			supportingData: {},
			scaleBy: 1.5,
			currentImageIndex: 1,
		}
	},

	computed: {
		activeSubframeBounds() {
			/* assumes image is display-scaled to stageSize
			*  use first image of current track as display
			*  TODO allow user to cycle through different angles of same track
			*/

			const { left, top, bottom, right } = this.activeItem.subFrames;

			const { height, width } = this.activeItem.size;

			return scaleSubframeBoundsToDisplay({ left, right, top, bottom }, width, height, this.stageWidth, this.stageHeight);
		},
		containsMultipleImages(){
			return this.supportingData[this.currentUuid].length > 1;
		},
		database(){ return this.labellingInputData.database },
		datasetName(){ return this.labellingInputData.datasetName; },
		activeItem(){ return this.supportingData?.[this.currentUuid]?.[this.currentImageIndex-1] },
		uuidsToLabel(){ return this.labellingInputData.uuidsToLabel; },
		currentUuid(){ return this.labellingInputData.uuidsToLabel[0]; },
		stageHeight(){ return (this.activeItem?.size.height ?? 0) / 1.3 },
		stageWidth(){ return (this.activeItem?.size.width ?? 0) / 1.3  },
		activeImage(){ return this.activeItem?.image }
	},

	async mounted(){
		await this.retrieveTaskInfo(this.taskUuid);
		this.currentUuid = this.labellingInputData.uuidsToLabel[0];
		this.resetLabels();
	},

	methods: {
		/**
		 * Resets/clears label selection on submit
		 */
		resetLabels(){
			this.createdLabels = this.labels.reduce( (map, obj) => {
				map[obj.labelTitle] = '';
				return map;
			}, {});
		},

		/**
		 * Creates a map between detectionUuid and framePath
		 */
		createFramePathMap(rawData) {
			let _framePathsMap = new Map();
			rawData.forEach(path => _framePathsMap.set(path.detectionUuid, path.framePath));
			return _framePathsMap;
		},

		/**
		 * Creates a map between detectionUuid and its respective subframe information
		 */
		createSubFramesMap(rawData) {
			let _subFramesMap = new Map();
			rawData
				.forEach(subFrame => _subFramesMap.set(subFrame.detectionUuid, {
					top: subFrame.minimumY,
					right: subFrame.maximumX,
					bottom: subFrame.maximumY,
					left: subFrame.minimumX
				}));
			return _subFramesMap;
		},

		/**
		 * Handles scroll action to zoom in and out of konva image
		 */
		zoom(e) {
			const scaleBy = this.scaleBy;
			e.evt.preventDefault();
			const stage = this.$refs.stage.getStage();
			const oldScale = stage.scaleX();
			const pointer = stage.getPointerPosition();
			const mousePointTo = {
				x: (pointer.x - stage.x()) / oldScale,
				y: (pointer.y - stage.y()) / oldScale,
			};
			const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
			stage.scale({ x: newScale, y: newScale });
			const newPos = {
				x: pointer.x - mousePointTo.x * newScale,
				y: pointer.y - mousePointTo.y * newScale,
			};
			stage.position(newPos);
		},

		/**
		 * Resets zoom, returns image back to original scale
		 */
		resetZoom() {
			const stage = this.$refs.stage.getStage();
			stage.scale({ x: 1, y: 1 });
			stage.position({ x: 0, y: 0 });
		},

		/**
		 * Handles click event on label field, adds to internal 'createdLabels' object
		 */
		fieldSelected(label, field){
			this.createdLabels[label] = field;
		},

		/**
		 * On mounted, handles retrieving information required to perform the task from backend
		 */
		async retrieveTaskInfo(taskUuid){
			try{
				const labellingInputData = (await dataAPI.post('imageLabelling/retrieveTaskInfo', {taskUuid})).data;

				this.labellingInputData = labellingInputData;

				await Promise.all(labellingInputData.uuidsToLabel.map(async uuid => {
					const detectionData = (await this.retrieveDataForUuid('merge', uuid));

					const data = [];

					detectionData.forEach(async frame => {
						const image = new window.Image();
						image.src = frame.framePath;
						const {height, width} = (await createImageSizeMap(this.createFramePathMap([frame])))[frame.detectionUuid];
						const {minimumY: top, maximumX: right, maximumY: bottom, minimumX: left} = frame;
						data.push({
							image, size: {height, width}, subFrames: {top, right, bottom, left}
						})

					});

					this.$set(this.supportingData, uuid, data);
				}));
			}
			catch(error){
				this.$noty.error(`Unable to retrieve details for task: ${error}`, {layout: 'bottomLeft'});
			}
		},

		/**
		 * Retrieves the relevant data needed to display frames for a specific uuid, merge, local, or global
		 * TODO: Support local and global tracks
		 */
		async retrieveDataForUuid(type, uuid){
			try{
				if(type === 'merge'){
					const result = (await dataAPI.post(`${this.database}/imageLabelling/retrieveUuidData`, {type: 'merge', uuid: uuid})).data;
					return result;
				}
			}
			catch(error){
				this.$noty.error(`Unable to retrieve details for ${type} uuid ${uuid}: ${error}`, {layout: 'bottomLeft'});
			}
		},

		/**
		 * Submits selected labels to backend
		 */
		async submitLabel(uuid, labels){

			// make sure each label has an associated class selection
			if(Object.values(labels).some(value => value === '')){
				this.$noty.error(`Please make a selection`, {layout:'bottomLeft'});
				return;
			}

			const confirm = window.confirm('Please confirm your selection');

			if(confirm){
				try{
					// submit labels to db
					await dataAPI.post(`${this.database}/imageLabelling/submitLabels`, {uuid, type: 'merge', labels, dataset: this.datasetName});

					// remove image from assigned work pool when submitted
					await dataAPI.post('imageLabelling/removeImageFromJobPool', {uuid, taskUuid: this.taskUuid});

					// reset label/field selections
					this.resetLabels();

					this.$noty.success(`Submitted labels for ${uuid}`, {layout: 'bottomLeft'});

					this.labellingInputData.uuidsToLabel = this.uuidsToLabel.filter(r=> r !== this.currentUuid);

					this.currentImageIndex = 1;

					if(this.labellingInputData.uuidsToLabel.length === 0) { this.finishTask(); }

				}
				catch(error){
					this.$noty.error(`Unable to submit label for ${uuid}: ${error}`, {layout: 'bottomLeft'});
				}
			}
		},

		/**
		 * Submits a finished task request to backend, upon response, page is redirected to dashboard
		 * Hit only upon submission of last image in task
		 */
		async finishTask(){
			try{
				await dataAPI.post(`/updateTask/`, {taskUuid: this.taskUuid, status: "pendingApproval", taskType: 'image'})
				// const nextUrl = window.location.href.split('/')[0]+"/"+window.location.href.split('/')[1];
				// window.location.replace(`${nextUrl}`+'dashboard');
				this.$router.push('/dashboard');
			}
			catch(error){
				this.$noty.error(`Unable to finish task: ${error}`, {layout: 'bottomLeft'});
			}
		},
	},
};
</script>

<style scoped>
.workflow-container {
	/* TODO: fix size 100%-navbar w/ new styling*/
	height: 90vh;
	width: 95vw;
	overflow: hidden;
	display: flex;
}

.sidebar {
	flex: 0 0 12rem;
	height: 100%;
	padding: 1rem;
	background-color: #002933;
}

.right-container {
	display: flex;
	flex-direction: column;
	flex: 1 0 0;
}

.settings {
	padding: 1rem;
	display: flex;
	background-color: #002933;
}

.pagination {
	display: flex; 
	flex-direction: row;
	margin-left: 5rem;
}


.info-section {
	display: flex; 
	width: fit-content; 
	height: fit-content
}

</style>