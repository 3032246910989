
<b-card class="h-100" style="background-color: transparent;">
	<template #header>
		<h3 class="mb-0 mr-2">{{agent.email}}</h3>
	</template>

	<!-- Navigator -->
	<b-nav tabs class="mb-3" style="background-color: transparent;">
		<b-nav-item
			v-for="tab of tabOptions"
			:key="tab.value"
			:active="currentTab === tab.value"
			@click="currentTab = tab.value"
		>
			<span class="px-3">{{tab.text}}</span>
		</b-nav-item>
	</b-nav>

	<b-overlay :show="loading" variant="transparent" :opacity="1" blur="1px">
		<div v-if="currentTab === 'settings'">
			<!-- Production Access -->
			<section>
				<h5>
					Production Access
					<b-badge
						:variant="hasProductionAccess ? 'success' : 'warning'"
						pill
						class="ml-3"
					>
						{{hasProductionAccess ? 'Yes' : 'No'}}
					</b-badge>
				</h5>

				<b-button
					:variant="`outline-${hasProductionAccess ? 'warning' : 'info'}`"
					@click="ToggleAgentAccess"
				>
					<i :class="`fas fa-${hasProductionAccess ? 'ban' : 'check'} fa-1x mr-1`"></i>
					{{hasProductionAccess ? 'Disable' : 'Enable'}} Access
				</b-button>
			</section>

			<!-- Password Reset with Confirmation -->
			<section>
				<h5>Password Management</h5>

				<Confirmation
					v-slot="{ Confirm, awaiting }"
					class="d-inline-block"
					placement="bottom"
					title="Confirm Password Reset"
					:warning="`This will generate a new login password for ${agent.name}.
						The new password will be visible to admin users until the account has logged in.`"
				>
					<b-button
						variant="outline-warning"
						:disabled="awaiting"
						@click="Confirm(null, ResetAgentPassword)"
					>
						<i class="fas fa-key fa-1x mr-1"></i> Reset Password
					</b-button>
				</Confirmation>

				<div v-if="password" class="mt-2">
					New password: <code class="ml-1" style="user-select: all">{{password}}</code>
					<br><small>This agent's password will <b>only be visible to you</b> until you log out or exit the tab. Please copy it to a secure location.</small>
				</div>
			</section>

			<!-- Delete with Confirmation -->
			<section>
				<h5>Agent Management</h5>

				<Confirmation
					v-slot="{ Confirm, awaiting }"
					class="d-inline-block"
					placement="bottom"
					title="Confirm Delete"
					:warning="`This will permanently revoke access rights for ${agent.name}.`"
				>
					<b-button
						variant="outline-warning"
						:disabled="awaiting"
						@click="Confirm(null, DeleteAgent)"
						style="width:154px;"
					>
						<i class="fas fa-times fa-1x mr-1 ml-"></i> Remove Agent
					</b-button>
				</Confirmation>
			</section>
		</div>

		<!-- Metrics Tab -->
		<div v-else-if="currentTab === 'metrics'">
			<!-- Accuracy data -->
			<section v-if="accuracyData">
				<h5>
					Training Accuracy
					<b-button class="ml-3" size="sm" variant="outline-success" @click="RefreshAccuracyData">
						<i class="fas fa-sync fa-1x mr-1"></i> Reload
					</b-button>
				</h5>

				<div v-for="(taskType, taskName) in taskTypes" :key="taskType" class="metric">
					<header>{{taskName}}:</header>
					<li>Submissions: <code>{{accuracyData[taskType].submissionCount}}</code></li>
					<li>Avg. Accuracy: <code>{{parseFloat( accuracyData[taskType].averageAccuracy.toFixed(2) )}}</code></li>
				</div>
			</section>
		</div>

		<!-- Alerts -->
		<b-alert v-model="showErrorMessage" variant="warning" dismissible>
			{{ errorMessage }}
		</b-alert>
	</b-overlay>
</b-card>
