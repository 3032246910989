<template>
<div class="card" style="margin-left:-26px;margin-right:25px;height:100%;border:transparent; background-color: #001014;">
	<div v-for="icon in icons" v-bind:key="icon" class="icons">
		<div class="card-body" @click="setCurrentSection(icon)" :style="`padding-bottom:10px; border-left: 4px solid ${(currentSection===icon) ? '#007fff' : '#001014' }; display: flex; flex-direction: row;`">
			<i :class="iconImageMap[icon]" style="padding-left:2px;margin-right:10px;">
			</i>
			<h5 class="card-title" style="font-size: 0.9em;font-weight: 3px;">

				{{icon.slice(0,1).toUpperCase()+icon.slice(1,icon.length)}}
			</h5>
		</div>
	</div>
</div>
</template>

<script>
import 'vue-progress-path/dist/vue-progress-path.css';
export default {
	name: 'dashboard-sidebar',
	props: {
		icons: {type: Array, default: () => ['playground']},
	},
	data: () => ({
		currentSection: 'tasks',
		iconSize: 'fa-2x',
		iconImageMap: {
			'playground': `fas fa-bullseye fa-1x`,
			'tasks': 'fas fa-tags fa-1x',
			'broadcast': 'fas fa-broadcast-tower fa-1x',
			'manager': 'fas fa-user-shield fa-1x',
			'datasets': 'fas fa-database fa-1x',
			'settings': 'fas fa-cogs fa-1x',
		}
	}),
	computed: {
		isNew(){
			return (localStorage.getItem('is_new') === 'true');
		},
	},
	methods: {
		setCurrentSection(iconName){
			this.currentSection = iconName;
			this.$emit('setCurrentSection', iconName);
		}
	}
}
</script>

<style scoped>
.card-body:hover {
	background-color: rgb(0, 35, 44);
}
.icons {
	/* height: 100px; */
	align-content: center;
}

.fa-database {
	margin-left:2px;
}
.fa-broadcast-tower {
	margin-left:-0.5px;
}
</style>