
<div class="row" style="display:flex; margin-left:0.001rem; flex-direction: row; height: 100%; width:100%;background-color:transparent;">
	<div :style="`display: flex; flex-direction: row; width: ${width}; height:${height}; background-color:transparent`">
		<div class="card mb-0" :style="setCardStyle()" style="width: 100%; background-color:transparent; border: 2px solid #002A34;">
			<div v-if="showSettings" class="row" style="display:flex; gap: 32px; margin-left:0.01rem; border-bottom: 2px solid #002A34; height: 50px; padding-right: 16px; padding-left: 16px; align-items: center; ">
				<b-button size="sm" v-b-tooltip.hover="'Tile View'" variant="outline-info" style="all: unset; border: none; color: white;" @click="displayMode='tiledGallery'">
					<i class="fas fa-th"></i>
				</b-button>
				<b-button size="sm" v-b-tooltip.hover="'Gallery View'" variant="outline-info" style="all: unset; border: none; color: white;" @click="displayMode='fullframeGallery'" >
					<i class="fas fa-images"></i>
				</b-button>
			</div>
			<div v-if="displayMode === 'tiledGallery'" class="card-body" style="position: relative; width: 100%;background-color:transparent;">
				<div class="local-track-list" style=" width: fit-content; margin-left: auto;margin-right: auto;">
						<div v-for="(point,index) in pointsToDisplay" class="local-track" v-bind:key="point[pointType]">
							<div class="mask">
								<div class="card image-tile" :class="setTrackClass(index+'_pointsMutablePage', frameHoverIndex, point[pointType])" style="margin: 5px;">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										:style="imageCardSize.css"
										@mousedown="galleryElementOnClick($event, index, point)"
										@mouseover="frameHoverAction(index+'_pointsMutablePage', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null"
									>
										<div>
											
											<img
												v-if="sizeMap[detection.detectionUuid]!==undefined"
												:src="framePathsMap.get(detection.detectionUuid)"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid), sizeMap[detection.detectionUuid].width, sizeMap[detection.detectionUuid].height)"
												draggable="false"
												ondragstart="return false;"
											>
										</div>
										
									</div>
									
								</div> 
								
							</div>
							
						</div>
					</div>
					<div v-if="pointsMutable.length > pageSize" style="margin-top: 16px;">
						<b-pagination
							:per-page="pageSize"
							v-model="pageNumberRaw"
							:total-rows="pointsMutable.length"
							align="center"
							class="my-1"
						/>
				</div>
			</div>
			<div v-if="displayMode === 'fullframeGallery'" class="card-body" style="position: relative; display: flex; width: 100%;">
				<div class="local-track-list" style=" width: fit-content; margin-left: auto;margin-right: auto;">
					<!-- Gallery tiles container -->
						<div v-for="(point,index) in pointsToDisplay" 
							class="local-track" 
							v-bind:key="point[pointType]">
							<div class="mask">
								<div class="card image-tile" :class="setTrackClass(index+'_pointsMutablePage', frameHoverIndex, point[pointType])" style="margin: 5px;">
									<div v-for="(detection, index2) in [point]"
										v-bind:key="detection.detectionUuid"
										class="frame-item"
										:style="imageCardSize.css"
										@mousedown="galleryElementOnClick($event, index, point)"
										@mouseover="frameHoverAction(index+'_pointsMutablePage', detection.detectionUuid,$event);
											shiftKey = $event.shiftKey;
											clickEngaged = ($event.which===1);
											currentDetection = {detectionUuid: detection.detectionUuid, framePath: framePathsMap.get(detection.detectionUuid), subFrame: subFramesMap.get(detection.detectionUuid)}"
										@mouseleave="(frameHoverIndex === index+'_pointsMutablePage') ? frameHoverIndex = -1 : null">
										<div>
											<img
												v-if="sizeMap[detection.detectionUuid]!==undefined"
												:src="framePathsMap.get(detection.detectionUuid)"
												:style="localSetClipCss(subFramesMap.get(detection.detectionUuid), sizeMap[detection.detectionUuid].width, sizeMap[detection.detectionUuid].height)"
												draggable="false"
												ondragstart="return false;">
										</div>
									</div>
								</div>
							</div>
						</div>
								
					<div v-if="pointsMutable.length > pageSize" 
						style="width: 100%; margin-top: 16px; margin-bottom: 16px; text-align: center;">
						<b-pagination
							:per-page="pageSize"
							v-model="pageNumberRaw"
							:total-rows="pointsMutable.length"
							align="center"
							class="my-1"
						/>
					</div>
					</div>
						<div style="width: 100%; margin-bottom: 16px;">
							<FullFrameDisplay 
								:key="pointsMutable[galleryIndex].detectionUuid"
								:imageScale="3"
								:size="{
									height: sizeMapFull[pointsMutable[galleryIndex].detectionUuid].height,
									width: sizeMapFull[pointsMutable[galleryIndex].detectionUuid].width,
								}"
								:subFrame="{ 
									top: pointsMutable[galleryIndex].subFrames.minimumY, 
									right: pointsMutable[galleryIndex].subFrames.maximumX, 
									bottom: pointsMutable[galleryIndex].subFrames.maximumY,
									left: pointsMutable[galleryIndex].subFrames.minimumX
								}"
								:framePath="pointsMutable[galleryIndex].framePathFull"
								:draggable="true"
							/>
						</div>
					<div>		
				</div>
			</div>
		</div>
	</div>
</div>
