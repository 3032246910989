

<div>
	<ul v-if="$store.state.tracks.length===0" class="list-group list-group-flush" style="max-width: 100%;">
		<li class="list-group-item"><code>No tracks left to label</code></li>
	</ul>
	<div v-if="stage && stage < 3" class="column">
		<!-- <h1 >Stage {{stageInfo}}</h1> -->
		<span v-if="retrieveTracksStatus.length > 0">{{ retrieveTracksStatus }}</span>

		<div class="card border-secondary mb-3" style="max-width: 100%;">
			<router-link v-for="(track, index) in $store.state.tracks"
				:class="{done: track.isDone}"
				class="track"
				:key="'task_interaction_link_' + index"
				tag="section"
				:to="{ path: `/viewer/${track.trackUuid}/${taskType}` }">
				<ul class="list-group list-group-flush" style="max-width: 100%;">
					<li class="list-group-item"><h6> {{index+1}} - Track Uuid: {{track.trackUuid}} </h6></li>
				</ul>
			</router-link>

		</div>

	</div>

	<div v-if="stage && stage > 2 && stage < 5" class="column">

		<h1 >{{(stage === 3) ? 'Label' : 'Review'}} Points</h1>

		<span v-if="retrieveTracksStatus.length > 0">{{ retrieveTracksStatus }}</span>
		<div class="card border-secondary mb-3" style="max-width: 100%;">

			<router-link v-for="(interaction, index) in $store.state.interactions" :class="{done: interaction.isDone}" class="track" :key="'task_interaction_link_' + index" tag="section" :to="{ path: `/db/${db}/review/${interaction.uuid}/${stage}` }">
				<h6> {{index+1}} - Interaction Uuid: {{interaction.uuid}} </h6>
			</router-link>
		</div>
	</div>
</div>
