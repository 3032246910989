
<div style="display: contents;">
	<ul class="nav nav-tabs" style="width:fit-content">
		<li class="nav-item" @click="toggleTrackSearch=1">
			<a :class="toggleTrackSearch===0 ? 'nav-link' : 'nav-link active'" data-toggle="tab">Full Track Search</a>
		</li>

	</ul>
	<b-card v-if="toggleTrackSearch" class="card mb-0 h-100" style="padding:0px;">

		<div style="display: flex; flex-direction: column">
			<div style="display: flex; flex-direction: flex">
				<MergeTrackEditor
					:db="dataset.vendor"
					:inputTable="dataset.datasetName"
					mergeUuid=""
					:key="dataset.uuid"
					:resolution="1920"
					:format="jpeg"
					:currPage="0"
					ref="mergeEditor"
					:minimal="true"
					mode="read"
					@dataLoaded="dataLoaded"
					style="width: 50%; float:left;  height:499px; width: 50%; overflow-y: scroll;"
				>
				</MergeTrackEditor>
				<div v-if="currentHighLightedDetection!==undefined" style="height: 55%;width:49%;">
					<div
						:id="'ff-canvas-' + currentHighLightedDetection.detectionUuid" v-anime="{}"
						class="ff-canvas_fullframe_preview"
						:style="{width: frameWidth}"

					>

						<div id="fullframe">
							<img :src="currentHighLightedDetection.framePath">
						</div>

						<div :style="setFrameRegionCss(currentHighLightedDetection.subFrame, true)"></div>
					</div>
				</div>

			</div>
			<div style="display: flex; flex-direction: flex">
				<DetectionGallery
					style="width:100%; height: 100%; margin-left: 16px;"
					:points="points"
					:from="dataset.from"
					:pageSize="25"
					:to="dataset.to"
					:mergeable="true"
					:mergeUuid="mergeEditorData.mergeUuid"
					@setHighLightedDetection="setHighLightedDetection"
				>
				</DetectionGallery>
			</div>
		</div>
	</b-card>
	<b-card v-if="!toggleTrackSearch" class="card mb-0 h-100" style="padding:0px;">

	</b-card>
</div>
