
<div class = "notifications" style="position: fixed; background: black; display: flex; border: 1px solid white;border-radius:5px">
	<b-card class="card mb-3" style="background: black; ; " body-class="scroll-wrap">
		<template #header><code class="mb-0" style="font-size:20px;">Jobs <i :class="`${refreshClass} far fa-sync fa-1x`" @click="refresh()"></i></code></template>
		<div v-if="!isRefreshing && dagRunStatuses.length!==0 && Object.keys(moreInfo).length!==0">
			<div v-for="(dagRun) in dagRunStatuses" v-bind:key="dagRun.runId" class = "card-container" >
				<b-card id = "dagStatus" @click="getMoreInfo(dagRun.runId)">
					<template #header>
						<div>
							<code style="color: white;" >{{dagRun.datasetName}}</code>
							<a :href="pipelineUrl+'instance/runs/'+dagRun.runId" target="_blank">
								<i class="far fa-external-link fa-1x" style="float:right; margin-left:10px"></i>
							</a>
							<i class="fas fa-user fa-1x" style="float:; margin-left: 30%"></i> <code style="margin-left:10px;">{{dagRun.launchedBy}}</code>
							<i class="far fa-times fa-1x" style="float:right; margin-left:10px; color: #E93820" @click="dismissProcess(dagRun.runId)"></i>
						</div>
						<div>
							<code style="color: #BBC7C9;" >{{dagRun.pipelineName}}</code>
						</div>
					</template>
					<code
						v-if="dagRun.status==='STARTED'"
						class="loading">
						Running
					</code>
					<code v-else>
						<div style="color: #E93820;" v-if="dagRun.status==='FAILED'">
							Job Failed
						</div>
						<div style="color: #93E9BE;" v-if="dagRun.status==='SUCCESS'">
							Completed Successfully
						</div>
					</code>
				</b-card>
			</div>
		</div>
		<div v-if="!isRefreshing && dagRunStatuses.length===0" class = "card-container">
			<b-card>
				<div class="card-body">
					<code style="color: white;">
						No running jobs to display...
					</code>
				</div>
			</b-card>
		</div>
		<!-- Place holder cards -->
		<div v-if="isRefreshing" class = "card-container">
			<div v-for="placeHolderDiv in Array.from(Array(5).keys())" :key="placeHolderDiv">
				<b-card class="placeholder">
					<template #header id="statusHeader">
						<code style="color: white;" ></code>
					</template>
					<div class="card-body">
					</div>
				</b-card>
			</div>
		</div>

	</b-card>
</div>
