
<div id="review" :key="'review_' + trackUuid" class="fill">

	<div class="fill" style="display: flex; flex-direction: row; width: 100%;margin-left:-287px;">
		<div class="sidebar">
			<div style=" height: 100%;padding: 15px;">
				<h6 style="color:white;">Event Labels</h6>
				<ul class="list-group list-group-flush event-labels">
					<li v-for="label in labels.filter(r=>r.type==='event')" class="list-group-item"  v-bind:key="label.labelTitle" @click="addLabel(label)">
						{{label.labelTitle}}
					</li>
				</ul>
				<h6 style="color:white;">Tag Labels</h6>
				<ul class="list-group list-group-flush tag-labels">
					<li v-for="label in labels.filter(r=>r.type==='tag')" class="list-group-item"  v-bind:key="label.labelTitle" @click="addLabel(label)">
						{{label.labelTitle}}
					</li>
				</ul>
			</div>
		</div>

		<div class="editor-container">
			<div class="settings">
				<div style="float: right;">
					<button
						class="btn btn-outline-warning btn-sm"
						:class="{flagSubmitted: trackFlagActive['videoNotLoading']}" style="margin-right:10px;"
						@click="reportIssue('videoNotLoading')"
					>
						<i class="ml-1 fas fa-exclamation-triangle text-warning"/>
						{{`${(trackTypeComments.includes("videoNotLoading"))? 'Video Link Missing Reported' : 'Report Missing Video'}`}}
					</button>

					<button
						class="btn btn-outline-warning btn-sm"
						:class="{flagSubmitted: trackFlagActive['trackIssues']}"
						@click="reportIssue('trackIssues')"
					>
						<i class="ml-1 fas fa-unlink text-warning" style="margin-right:5px;"/>
						{{`${(trackTypeComments.includes("trackIssues"))? 'Track Issues Reported' : 'Report Track Issues'}`}}
					</button>

					<button class="btn btn-outline-info btn-sm" style="margin-left:40px;"> Submit </button>
				</div>
			</div>

			<div style="width:100%; height:100%">
				<!-- Hidden video element to proxy video into canvas -->
				<video
					v-if="videoSrc"
					class="d-none"
					ref="video"
					:src="videoSrc"
					muted
					preload="auto"
					@canplay="canPlay(firstLoad)"
					@timeupdate="videoCurrentTime = video.currentTime"
					@durationchange="videoDuration = Math.ceil(video.duration)"
				/>

				<!-- Konva video display -->
				<div class="video-container" :style="`width: ${videoContainerWidth}px; height: ${videoContainerHeight}px;position:relative;left:249px;`">
					<v-stage
						:config="{
							width: videoContainerWidth,
							height: videoContainerHeight
						}"
						ref="stage"
						@wheel="zoom"
					>
						<v-layer ref="layer">
							<v-image :config="konvaVideoConfig" @click="togglePlayback" />
						</v-layer>

					</v-stage>
					<div class="label-info-pane">

						<div class="label-info-pane-buttons" style="padding:10px;">
							<button v-if="selectedCreatedLabel!==''" class="btn btn-outline-info btn-sm" :class="{active: !showCreatedLabelList}" @click="showCreatedLabelList=true;selectedCreatedLabel=''"><i class="fas fa-arrow-left"/> Back</button>
						</div>

						<div v-if="showCreatedLabelList">


							<div style="padding: 15px;display: flex;flex-direction: column;">

								<ul class="list-group list-group-flush" style="margin-top:10px;" v-if="createdLabels.length>0">
									<li v-for="(label) in createdLabels.filter(r=>eventTypes.includes(r.type))" class="list-group-item" :key="label.id"
										style="background-color: #1A282C"
										@click="labelSelectedFromList(label);showCreatedLabelList=false;"
										@mouseover="labelHoverAction(label)">
										{{label.type}}  {{formatSeconds(label.start)}} - {{formatSeconds(label.end)}}


										<div v-if="label.unconfirmed" class="label-item">
											<i class="ml-1 fas fa-exclamation-circle text-danger" style="margin-right:5px;"/>
											<p> Addional Info Required </p>
										</div>
										<div v-if="!label.unconfirmed && label.isLocal" class="label-item">
											<i class="ml-1 fas fa-check text-success" style="margin-right:5px;"/>
											<p> Saved </p>
										</div>
										<div v-if="!label.isLocal" class="label-item">
											<i class="ml-1 fas fa-user-circle text-success" style="margin-right:8px;margin-top:1px;"/>
											<p> created by {{label.user}} </p>
										</div>


									</li>
									<li v-for="(label) in createdLabels.filter(r=>tagTypes.includes(r.type))" class="list-group-item" :key="label.id"
										style="background-color: #1A282C"
										@click="labelSelectedFromList(label);showCreatedLabelList=false;"
									>
										{{label.type}}: {{label['Items Taken']}}

										<div v-if="label.unconfirmed" class="label-item">
											<i class="ml-1 fas fa-exclamation-circle text-danger" style="margin-right:5px;"/>
											<p> Addional Info Required </p>
										</div>
										<div v-if="!label.unconfirmed && label.isLocal" class="label-item">
											<i class="ml-1 fas fa-check text-success" style="margin-right:5px;"/>
											<p> Saved </p>
										</div>
										<div v-if="!label.isLocal" class="label-item">
											<i class="ml-1 fas fa-user-circle text-success" style="margin-right:8px;margin-top:1px;"/>
											<p> created by {{label.user}} </p>
										</div>


									</li>
								</ul>

								<ul class="list-group list-group-flush" style="margin-top:10px;" v-else>
									<li class="list-group-item no-labels">
										No labels found
									</li>

								</ul>

							</div>
						</div>

						<div v-if="selectedCreatedLabel!==''" style="height:90%;">

							<div style="padding: 15px;height:89%">
								<div class="card mb-3" style="background-color: transparent;height: 100%;;">
									<div class="card-body">
										<div v-if="eventTypes.includes(selectedCreatedLabel.type)" class="card-title">
											{{selectedCreatedLabel.type}}:
											{{formatSeconds(selectedCreatedLabel.start)}} -
											{{formatSeconds(selectedCreatedLabel.end)}}
										</div>

										<div v-else class="card-title">
											{{selectedCreatedLabel.type}}
										</div>
										<div style="display: flex; flex-direction: column;">
											<div>
												<h6 v-if="eventTypes.includes(selectedCreatedLabel.type)" style="margin-bottom: 15px;">Event Details</h6>
											</div>

											<div v-for="fieldValue in labels.filter(r=>r.labelTitle===selectedCreatedLabel.type)[0].additionalInfo" :key="fieldValue.field">
												<div style="display: flex; flex-direction: row;width:fit-content;">
													<div style="float: left;">
														{{fieldValue.field}}:
													</div>
													<div style="position: relative; left: 100px;">
														<select class ="form-control" style="width: fit-content" v-bind:id="'plot-event-location-'"
															name="plot-event-location" v-model="selectedCreatedLabel[fieldValue.field]">
															<option v-for="(location, element_index) in fieldValue.values"
																v-bind:key="'temp-event-location-'  + '-' + element_index" v-bind:value="location" >
																{{ location }}
															</option>
														</select>
													</div>
												</div>
											</div>
											<!-- TODO: Implement a comment system -->
											<!-- <div class="comments" style="position: relative; top:100px">

												<h6>Comments</h6>

												<div class="card mb-3">
													<div class="card-body">
														<p>ambiguous event, not too sure here, please review</p>
													</div>
												</div>

											</div> -->
										</div>
										<div v-if="!selectedCreatedLabel.isLocal" class="created-by">
											<button class="btn btn-outline-info btn-sm" style="color:white;" @click="saveLabel(selectedCreatedLabel)"> Save </button>
											<button class="btn btn-outline-info btn-sm error" style="margin-left :10px;border:1px solid red;color:white" @click="deleteLabel(selectedCreatedLabel)"> Delete </button>
											<div class="card-subtitle text-muted" style="margin-top:10px;">
												Created by {{this.username }} at {{new Date(selectedCreatedLabel.createdAt).toISOString()}}
											</div>
										</div>

										<div v-else class="created-by">
											<button class="btn btn-outline-info btn-sm" style="color:white;" @click="saveLabel(selectedCreatedLabel)"> Save </button>
											<button class="btn btn-outline-info btn-sm error" style="margin-left :10px;border:1px solid red;color:white" @click="deleteLabel(selectedCreatedLabel)"> Delete </button>
											<div class="card-subtitle text-muted" style="margin-top:10px;">
												Created by {{selectedCreatedLabel.createdBy }} at {{selectedCreatedLabel.createdAt}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Timeline section -->
				<div style="width:100%; ">
					<Timeline
						v-if="videoLoaded"
						ref="timeline"
						v-model="seekPosition"
						@play="play"
						@pause="pause"
						@createTemporaryEvent="createTemporaryEvent"
						@updatePlaybackRate="updatePlaybackRate"
						:duration="videoDuration"
						:playing="videoPlaying"
						:eventTypes="eventsSelected"
						@resetZoom="resetZoom"
						@hook:created="retrieveLabels()"
					/>
				</div>
			</div>
		</div>
	</div>
</div>
