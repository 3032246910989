
<div class="merge-track-editor">

	<b-modal
		:id="`${mergeUuid}-fullframe-modal`"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		hide-footer
	>	
		<FullFrameDisplay 
			style="height: 1000px; width: 500px;"
			:imageScale="2.7"	
			:framePath="currentFullFrameImage"
			:size="fullFramePathSizeMap[currentDetectionUuid]"
			:subFrame="currentFullFrameSubFrame"
		/>
	</b-modal>

	<div v-if="!minimal" id="data-entry-row" class="merge-selector">
		<h6>Merge Track Editor</h6>
		<div style="display: flex; flex-direction: row;">
			<b-input v-model="selectedMergeUuid" lazy placeholder="Enter merge UUID or select from above..."/>
		</div>
	</div>
	
	<div v-if="globalMergedTracks" class="overflow">
		<hr/>
		<div v-if="!minimal" class="information">
			<pre v-if="!selectedMergeUuid">Please input a merge UUID to edit.</pre>
			<pre v-else-if="framePathsMap">Merge UUID: {{currentMergeUuid}}</pre>
			<pre v-if="!framePathsMap">Loading merge: {{currentMergeUuid}}...</pre>
		</div>

		<b-overlay :show="(pipeLineProgress['frames'] !== true)" variant="transparent" blur="5px">
			<div class="local-track-list">
				<b-card
					v-for="(localTrack, index) in localTracks.slice(0, sampleSlice)"
					:key="index"
					class="local-track"
				>
					<!-- Detection images -->
					
					<div
						v-for="detectionUUID in localTrackMap[localTrack.localTrackUuid]"
						:key="detectionUUID"
						class="frame-item"
						@mousedown="currentDetectionUuid = detectionUUID"
						@mouseover="imageHover(detectionUUID)"
						:style="imageCardSize.css"
						@contextmenu.prevent="$refs.menu.open"
					>	
						<img v-if="sizeMap[detectionUUID]!==undefined" 
							:src="framePathsMap.get(detectionUUID)"
							
							:style="localSetClipCss(subFramesMap.get(detectionUUID), sizeMap[detectionUUID].width, sizeMap[detectionUUID].height)">
					</div>

					<!-- Removal button -->
					<b-button
						v-if="mode==='read/write'"
						size="sm"
						variant="warning"
						class="block-centered remove-button"
						v-b-tooltip.hover="'Remove from Merge'"
						@click="removeLocalTrackFromMergeTrack(localTrack, 'error')"
					>
						<i class="fas fa-times"></i>
					</b-button>
				</b-card>
			</div>
		</b-overlay>
	</div>
	<vue-context ref="menu">
		<li>
			<a href="#" @click="showFullFrame()">View Full Frame</a>
		</li>
	</vue-context>
</div>
