export function setClipCss(coords, imageWidth, imageHeight, fixedWidth, preCropped){
	// Get the transformed parameters - calculates the width and makes sure the coords are in pixel space
	const transformedParameters = scaleSubframeBoundsToDisplay(coords, imageWidth, imageHeight, imageWidth, imageHeight);

	// Determine the amount to scale the image by to make the subframe fit into the window - imagine the window being
	// your phone and you have to scale up or down to make the box fit the phone screen width
	const scaleAmount = fixedWidth / transformedParameters.width;
	const scaledWidth = imageWidth * scaleAmount;
	// This gives a uniform scale on X and Y
	const scaledHeight = imageHeight * scaleAmount;

	// The scaled parameters are
	const scaledParameters = scaleSubframeBoundsToDisplay(coords, imageWidth, imageHeight, scaledWidth, scaledHeight);

	const topAdjust = -scaledParameters.top;
	const leftAdjust = -scaledParameters.left;
	const borderheight = 1;
	if(preCropped){
		return `position: absolute;width:${fixedWidth}px;border: ${borderheight}px`;
	}
	else{
		return `position: absolute;
	        clip: rect(${scaledParameters.top}px, ${scaledParameters.right}px, ${scaledParameters.bottom}px, ${scaledParameters.left}px);
	        width:${scaledWidth}px;
	        top:${topAdjust}px;
	        left:${leftAdjust}px;
	        border: ${borderheight}px`;
	}
}

export async function getImageDimensions(path) {
	const image = new Image();

	const loadPromise = new Promise((resolve, reject) => {
		image.onload = resolve;
		image.src = path;  // always set src after event
		image.onerror = reject;
	});

	try{
		await loadPromise;
		const { height, width } = image;
		return {height, width}
	} catch(error) {
		return {height: 0, width: 0};
		console.warn('In getImageDimensions: could not load image!', e);
	}
}

export function scaleSubframeBoundsToDisplay(coords, imageWidth, imageHeight, displayWidth, displayHeight) {

	const widthRatio = displayWidth / imageWidth;
	const heightRatio = displayHeight / imageHeight;

	// handle coords that are ratio based

	if(coords.left < 1)
	{
		coords.left = coords.left * imageWidth;
	}

	if(coords.right < 1)
	{
		coords.right = coords.right * imageWidth;
	}

	if(coords.top < 1)
	{
		coords.top = coords.top * imageHeight;
	}

	if(coords.bottom < 1)
	{
		coords.bottom = coords.bottom * imageHeight;
	}

	const left =  coords.left * widthRatio;
	const top = coords.top * heightRatio;
	const right =  coords.right * widthRatio;
	const bottom = coords.bottom * heightRatio;
	const width = right - left;
	const height = bottom - top;

	return {left,
		right,
		bottom,
		top,
		width,
		height
	};
}


export default setClipCss;

let instanceCounter = 0;

// https://stackoverflow.com/questions/1484506/random-color-generator
export function randomColor(shouldIncrement) {

	if (shouldIncrement === true) {
		instanceCounter++;
	}

	const numOfSteps = 8;
	const step = instanceCounter % numOfSteps;

	// This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
	// Adam Cole, 2011-Sept-14
	// HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
	let r, g, b;
	let h = step / numOfSteps;
	let i = ~~(h * 6);
	let f = h * 6 - i;
	let q = 1 - f;
	switch(i % 6){
		case 0: r = 1; g = f; b = 0; break;
		case 1: r = q; g = 1; b = 0; break;
		case 2: r = 0; g = 1; b = f; break;
		case 3: r = 0; g = q; b = 1; break;
		case 4: r = f; g = 0; b = 1; break;
		case 5: r = 1; g = 0; b = q; break;
	}
	let c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
	return (c);
}

export function resetRandomColor() {
	instanceCounter = 0;
}

export async function createImageSizeMap(frameDetectionMap, isMap=true){
	let frameDetectionObj;
	if(isMap){
		frameDetectionObj = Object.fromEntries(frameDetectionMap);
	}
	else{
		frameDetectionObj=frameDetectionMap;
	}
	
	const cameraCodes = Object.keys(frameDetectionObj).map(detectionUuid => frameDetectionObj[detectionUuid].split('/').slice(-1)[0].split('.')[3]);

	const cameraCodesUnique = Array.from(new Set(cameraCodes));

	// creates a camera code to size map, allows us to grab image dimensions faster rather than checking every image as was done previously

	const cameraCodeToSizeMap = cameraCodesUnique.reduce((obj, x)=>{
		obj[x] = undefined;
		return obj;
	}, {});

	return (await Promise.all(Object.keys(frameDetectionObj).map(async detectionUuid => {

		const cameraCode = frameDetectionObj[detectionUuid].split('/').slice(-1)[0].split('.')[3];

		if(cameraCodeToSizeMap[cameraCode] === undefined){

			const { height, width } = await getImageDimensions(frameDetectionObj[detectionUuid]);

			cameraCodeToSizeMap[cameraCode] = {height, width};
			return {
				detectionUuid,
				height,
				width
			}
		}
		else{
			const {height, width} = cameraCodeToSizeMap[cameraCode];
			return {
				detectionUuid,
				height,
				width
			}
		}

	}
	))).reduce((obj, x)=>{
		const {height, width} = x;
		obj[x.detectionUuid] = {height, width};
		return obj;
	}, {});
}

export function promiseLoadImage(src) {
	return new Promise(resolve => {
		let image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', error => {
			console.error(`Failed to load promised image: ${src}`, error);
			resolve('');
		});
		image.src = src;
	});
}
