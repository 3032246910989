import Router from 'vue-router';
import ReviewTrack from './components/TrackViewer.vue';
import TrackScatterPlot from './components/tools/TrackScatterPlot.vue';
import ConsolidationView from './components/consolidation/Consolidation.vue';
import MergeTrackReviewer from './components/MergeTrackReviewer.vue';

import TrackListTask from './components/TrackListTask.vue';
import LabellingClient from './components/session/LabellingClient.vue';

import DatasetViewer from './views/DatasetViewer.vue';

import ImageClassifier from './components/workflows/image/ImageClassifier.vue'
import store from '../src/store';
import jwt_decode from 'jwt-decode';

function getTrackData(trackUuid) {
	return {
		"key": trackUuid,
		"trackUuid": trackUuid,
		"numPoints": null,
		"minTimestamp": null,
		"maxTimestamp": null,
		"isActive": true,
		"isDone": false,
	}
}
function getUserCredentials() {
	// Retrieve username
	if (localStorage.getItem('username') !== undefined) {
		return localStorage.getItem('username');
	} else {
		return "defaultuser"
	}
}

let router = new Router({
	mode: "history",
	routes: [
		// Active routes
		{
			path: "/viewer/:datasetUuid/:trackUuid/",
			name: "track-review",
			component: ReviewTrack,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				trackUuid: route.params.trackUuid,
				datasetUuid: route.params.datasetUuid,
				requestViaUrl: true,
				username: getUserCredentials(),
			})
		},
		{
			path: "/db/:db/review/:uuid/:stage",
			name: "track-view",
			component: ReviewTrack,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				db: route.params.db,
				// track: getTrackData(route.params.uuid),
				t_uuid: route.params.uuid,
				stage: parseInt(route.params.stage),
				requestViaUrl: true,
				username: getUserCredentials(),
			})
		},
		{
			path: "/dataset-viewer/datasetUUID/:datasetUUID",
			name: "DatasetViewer",
			component: DatasetViewer,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				datasetUUID: route.params.datasetUUID
			})

		},
		{
			path: "/scatter",
			name: "track-scatter",
			component: TrackScatterPlot,
			meta: {
				requiresAuth: true
			},

		},
		{
			path: "/scatter/taskUuid/:taskUuid",
			name: "track-scatter-task",
			component: TrackScatterPlot,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				taskUuid: route.params.taskUuid
			})
		},
		{
			path: "/consolidate/taskUuid/:taskUuid",
			name: "Consolidate",
			component: ConsolidationView,
			meta: {
				requiresAuth: true,
			},
			props: (route) => ({
				consolidateTaskUuid: route.params.taskUuid,
			})
		},
		{
			path: "/reidreview",
			name: "reid-review",
			component: MergeTrackReviewer,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				db: route.params.db
			})
		},
		{
			path: "/reidreview/taskUuid/:taskUuid",
			name: "reid-review-task",
			component: MergeTrackReviewer,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				taskUuid: route.params.taskUuid
			})
		},
		{
			path: "/dashboard",
			name: "labelling-client",
			component: LabellingClient,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: "/",
			redirect: "/dashboard",
			component: LabellingClient,
		},
		{
			path: '/playground/:subview?',
			name: 'playground',
			component: () => import('./views/Playground.vue'),
			meta: {
				requiresAuth: true
			},
			props: ({params}) => ({
				subview: params.subview // allows us to optionally direct to a particular subview
			})
		},
		{
			path: "/db/:db/gestureTask/taskUuid/:taskUuid",
			name: "track-list-task",
			component: TrackListTask,
			meta: {
				requiresAuth: true,
			},
			props: (route) => ({
				db: route.params.db,
				taskUuid: route.params.taskUuid,
				// stage: parseInt(route.params.stage),
				cacheTracks: (route.query.cache === 'true'),
			})
		},
		{
			path: "/video-labelling-task/taskUuid/:taskUuid/type/:taskType",
			name: "video-labelling-task",
			component: TrackListTask,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({

				taskUuid: route.params.taskUuid,
				taskType: route.params.taskType,

			})
		},
		{
			path: "/image-classifier/taskUuid/:taskUuid",
			name: "image-labelling-task",
			component: ImageClassifier,
			meta: {
				requiresAuth: true
			},
			props: (route) => ({
				taskUuid: route.params.taskUuid
			})
		}
	]
});
router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.requiresAuth)) {
		const token = localStorage.getItem('token');
		const jwtExpiryTimeSeconds = jwt_decode(token).exp;
		const currentTimeSeconds = ((new Date()).getTime()/1000);

		if (!jwtExpiryTimeSeconds){
			// if no expiry time, we want to invalidate their session as legacy indefinite tokens are banned
			forceLogout(router, token);
		}

		if(jwtExpiryTimeSeconds < currentTimeSeconds){ // Check if token is expired, if yes, logout out and redirect to home page
			forceLogout(router, token);
		}

		if (store.getters.isLoggedIn) {
			return next();
		} else if (to.path !== '/login') {
			next('/login');
		}
	} else {
		next();
	}
});

const forceLogout = (router, token) => {
	localStorage.removeItem('token')
	store.dispatch('logout')
		.then(() => {
			router.push('/')
		})
}

export default router;