<template>
<div class="timeline-wrap" :style="`--row-height: ${CSS_ROW_HEIGHT}px;`">
	<div class="event-types-wrapper">

		<h6 class="title" :style="`${(eventTypes.length===0) ? 'border-bottom:1px solid transparent' : ''}`">
			Timeline Annotations
			<span class = "badge badge-success" style="position:relative; left:25px;;"><b>{{timelineEvents.length}}</b></span>
		</h6>

		<div>
			<div
				v-for="type of eventTypes"
				:key="type"
				style="padding:14px;"
				class="event-type"
			>
				{{ type }}
				<i :class="`ml-1 fas fa-info-circle text${(showTimelineEventInfo)?'-info':''}`" style="margin-right:5px;" @click="showTimelineEventInfo=!showTimelineEventInfo"/>
				<i class="fas fa-ellipsis-v" style="position: relative; top: 4px; float: right;"/>
			</div>
		</div>
		<div v-if="eventTypes.length === 0">
			<div class="event-type"/>
		</div>
	</div>

	<div class="timeline" ref="timeline" :style="seekPercentCSS">
		<div class="seek-bar" @mousedown="selectorSeek" />
		<div class="controls">

			<div class="button play-pause" @click="togglePlayback">
				<i v-if="!isPlaying" class="fas fa-play" />
				<i v-else class="fas fa-pause" />
			</div>

			<div class="button" @click="$emit('seek', seekPosition - 1)"><i class="fas fa-chevron-left" /></div>
			<div class="button" @click="$emit('seek', seekPosition + 1)"><i class="fas fa-chevron-right" /></div>

			<div v-for="speed in speedOptions" v-bind:key="speed.value" style="margin-top:3px;">
				<div class="button" :style="`${(selectedSpeed===speed.value)?'background-color: #2876D4;color:white;padding-top:3px;':'padding-top:3px'}`" @click="$emit('updatePlaybackRate', speed.value);selectedSpeed=speed.value"> {{speed.value}}x</div>
			</div>

			<div style="margin-left: 10px; position: relative;top:1px;">
				<div class="button" @click="$emit('resetZoom')">
					<i class="fas fa-sync"/>
				</div>
			</div>
			<div class="ml-auto" style="padding-top:10px;">
				<div style="display: flex;flex-direction: row;">
					<p class="duration-display" style="font-size:15px;">
						<code>{{formatSeconds(hoverPosition)}}</code>
					</p>
				</div>

			</div>

			<div class="ml-auto" style="padding-top:10px;">
				<div style="display: flex;flex-direction: row;">
					<p class="duration-display" style="font-size:15px;margin-right:10px;">
						<code style="color: #2876D4">{{formatSeconds(seekPosition)}} / {{formatSeconds(duration)}}</code>
					</p>
				</div>
			</div>
		</div>


		<div class="x-axis" :style="`--lastWidth: ${xAxisLastWidth}%`">
			<div v-for="label in xAxisLabels" :key="label" class="label">
				<div class="text" style="">{{ label }}</div>

				<div class="tick" />
			</div>
		</div>

		<div class="selector"
			@mousemove="[focusHover($event), dragAction($event)]"
			@mousedown="[selectorSeek($event), dragStart($event)]"
			@mouseup="selectedByDrag"
		>
			<div class="focus" />
			<div class="focus-hover"/>

			<template v-if="showSelection">
				<div class="selection" :style="selectionTopCSS">
					<div class="draggable-start" />
					<div class="draggable-end" />
				</div>
			</template>

			<div class="events">

				<!-- TODO fix y-index position, as a workaround highlighted events in label list are shown through a focused/highlighted event -->

				<!-- <div v-if="currentHighlightedEvent!==null && showTimelineEventInfo" class="tag-editor" :style="eventHighLightCSS">
					<div class="tag-editor-content">
						<div style="display: flex; flex-direction: column; padding:10px">
							<code style="font-size:10px;margin-bottom:10px;">EVENT INFO</code>
							<code>Created By: {{currentHighlightedEvent.createdBy}}</code>
							<code>Created At: {{new Date(currentHighlightedEvent.createdAt).toISOString()}}</code>
						</div>


					</div>
				</div> -->

				<div v-for="event in timelineEvents" :key="event.id" class="selection" :style="convertEventToSeekCSS(event)"/>
			</div>

			<div class="timeline-event-grid">
				<div v-for="type in eventTypes" class="" :key="type" />
			</div>

		</div>
	</div>
</div>
</template>


<script>
const CSS_ROW_HEIGHT = 51; //px

export default {
	name: 'Timeline',

	model: {
		prop: 'seekPosition',
		event: 'seek'
	},

	props: {
		duration: { type: Number, default: 1 },
		seekPosition: { type: Number, default: 0 },
		playing: true,
		eventSelected: { type: Boolean, default: false },
		eventTypes: { type: Array, default: () => ['asdf'] }
	},

	data: () => {
		return {
			CSS_ROW_HEIGHT,
			containerSize: { width: 0, height: 0 },
			xAxisLastWidth: 0, // percentage length to equalize xAxis
			resizeObserver: null,
			isPlaying: false,
			hoverPosition: 0,
			clickEngaged: false,
			selection: { start: 0, end: 0, type: 0, startSeekPosition: 0, endSeekPosition: 0},
			dragState: false,
			allowSelection: true,
			speedOptions: [{value: 0.75}, {value: 1},{value: 2},{value: 5},{value: 8},{value: 10}],
			selectedSpeed: '',
			currentHighlightedEvent: null,
			timelineEvents: [],
			showTimelineEventInfo: false,
			eventsSelectable: true,
		}
	},

	watch: {
		playing(newVal) {
			this.isPlaying = newVal;
		},

		eventSelected(newVal) {
			this.allowSelection = newVal;
		},

		timelineEvents: {
			handler(val, oldVal) {
				const unique = (value, index, self) => {
					return self.indexOf(value) === index;
				}

				const eventTypesInTimeline = this.eventTypes;

				const eventTypesFromNewEvent = val.map(r=>r.type);

				const eventsToAddToTimeline = eventTypesFromNewEvent
					.filter(r=>!eventTypesInTimeline.includes(r))
					.filter(unique)
					.map(eventType=>{
						this.eventTypes.push(eventType);
					})

			},
			deep: true
		},
	},

	computed: {
		seekRatio() {
			return this.seekPosition / this.duration;
		},

		hoverRatio(){
			return this.hoverPosition / this.duration;
		},

		hoverPercentCSS(){
			return `--hover: ${this.hoverRatio}%`;
		},

		selectionRatio(){
			return this.selection.start / this.duration;
		},

		selectionEnd(){
			return this.selection.end / this.duration;
		},

		selectionWidth(){
			return this.selection.end / this.duration - (this.selection.start / this.duration);
		},

		selectionTopCSS() {
			const yOffset = Math.max(this.eventTypes.indexOf(this.selection.type), 0);
			return `top: calc(${yOffset} * var(--row-height)); --display-color: #2876D455`;
		},

		seekPercentCSS() {
			return `--percent: ${this.seekRatio * 100}%;
					--hover: ${this.hoverRatio * 100}%;
					--selection-start: ${this.selectionRatio * 100}%;
					--selection-end: ${this.selectionEnd * 100}%;
					--selection-width: ${this.selectionWidth*100}%`;
		},

		eventHighLightCSS(){

			if(this.currentHighlightedEvent!==null){
				return `--row-height: ${CSS_ROW_HEIGHT}px;
						--highlight-position-x: ${this.getLabelPosX(this.currentHighlightedEvent) * 100}%;
						--selection-top: ${this.getLabelPosY(this.currentHighlightedEvent)};`;
			}
			else{
				return '';
			}

		},

		xAxisLabels() {
			const MIN_TICK_SIZE = 50; // px
			const { width } = this.containerSize;

			let tickCount = Math.floor(width / MIN_TICK_SIZE);
			let durationSegment = Math.floor(this.duration / tickCount);
			const preferredDuration = [5, 10, 15, 30, 60, 120, 300];

			// Coerce duration segments and tick count to fit preferred values
			for (const prefer of preferredDuration) {
				if (prefer > durationSegment) {
					durationSegment = prefer;
					break;
				}
			}
			tickCount = Math.floor(this.duration / durationSegment) + 1;

			const labels = [];
			const zeroPad = (num, places) => `${num}`.padStart(places, '0')
			for (let i = 0; i < tickCount; ++i)
			{
				const label = i * durationSegment;
				labels.push(this.formatSeconds(label));
			}

			this.xAxisLastWidth = (1.0 - ((tickCount - 1) * durationSegment) / this.duration) * 100;

			return labels;
		},

		showSelection(){
			return this.allowSelection && ((this.dragState === true) || (this.dragState === 'DONE'));
		},

	},

	mounted() {
		// Attach a resize listener
		const observer = new ResizeObserver(this.resize);
		observer.observe(this.$refs.timeline);
		this.isPlaying = this.playing;
		this.resizeObserver = observer;
		this.resize();
	},

	beforeDestroy() {
		this.resizeObserver?.disconnect();
	},

	methods: {
		resize() {
			const { width, height } = this.$refs.timeline.getBoundingClientRect();
			this.containerSize = { width, height };
		},

		selectorSeek({ offsetX: x }) {
			// this be more complicated with zoom/pan
			const seek = Math.floor(x / this.containerSize.width * this.duration);
			this.$emit('seek', seek);
			this.selection = {start:0,end:0};
		},

		togglePlayback() {
			this.isPlaying = !this.isPlaying;
			this.$emit(this.isPlaying ? 'play' : 'pause');
			this.$emit('toggle', this.isPlaying);
		},

		formatSeconds(seconds) {
			const mins = Math.floor(seconds / 60);
			const secs = seconds % 60;
			const zeroPad = (num, places) => `${num}`.padStart(places, '0')
			const formattedTick = `${mins}:${zeroPad(secs.toFixed(0), 2)}`; // mm:ss
			return formattedTick;
		},

		focusHover({offsetX: x}){
			const timelinePosX = Math.floor(x / this.containerSize.width * this.duration);
			this.hoverPosition = timelinePosX;
		},

		dragAction(e) {
			// only allow selection if an interaction event has been selected
			if(this.eventTypes.length>0){

				// LMB
				if (e.which === 1) {
					if (this.dragState === 'START') {
						this.dragState = true;
						const timelinePosStart = Math.floor(e.offsetX / this.containerSize.width * this.duration);
						this.selection.start = timelinePosStart;
						this.selection.type = this.eventTypes[ Math.floor(e.offsetY / CSS_ROW_HEIGHT) ];
					}

					const timelinePosEnd = Math.floor(e.offsetX / this.containerSize.width * this.duration);
					if (this.showSelection) {

						this.selection.end = timelinePosEnd
					};
					const seek = Math.floor(e.offsetX / this.containerSize.width * this.duration);
					this.$emit('seek', seek);
				}
				else {
					if (this.dragState !== 'DONE'){

						// clear drag only if not finished

						this.dragState = false;
					}
				}
			}
		},

		dragStart(e){
			// LMB
			if(e.which === 1) {
				this.dragState = 'START'; // truthy but not explicitly true to indicate start
				// wait for mousemove to set initial selection position
			}
		},

		selectedByDrag(e) {
			// LMB
			if(e.which === 1) {
				if (this.dragState === true)
				{
					const timelinePosEnd = Math.floor(e.offsetX / this.containerSize.width * this.duration);
					this.selection.end = timelinePosEnd;
					this.createEvent();
				}
			}

			this.dragState = (this.dragState === true) ? 'DONE' : false;
		},

		clearEventSelection(){
			this.dragState = false;
		},

		createEvent() {
			// create a temporary ID before sending off to db to acquire a written UUID
			const tempEventId = Date.now();
			this.timelineEvents.push({id: tempEventId, type: this.selection.type, start: this.selection.start,end: this.selection.end,createdAt: Date.now()})
			this.$emit('createTemporaryEvent', {id: tempEventId, type: this.selection.type, start: this.selection.start,end: this.selection.end, createdAt: Date.now()})
		},

		convertEventToSeekCSS(event) {
			// Compute y position
			const { type, start, end } = event;
			const yIndex = this.eventTypes.indexOf(type);
			let color;
			if(this.currentHighlightedEvent===null){
				color = '#2876D455';
			}
			else{
				color = (this.currentHighlightedEvent.id === event.id) ? '#2875d4' : '#2876D455';
			}

			// Compute x position & width
			const startRatio = (start / this.duration) * 100;
			const endRatio = (end / this.duration) * 100;
			const width = endRatio - startRatio;
			return `
				--display-color: ${color};
				--selection-start: ${startRatio}%;
				--selection-width: ${width}%;
				--selection-top: ${yIndex};
			`;
		},

		timelineEventSelected(event){
			console.log(event)
		},

		deleteEvent(id){
			// this.dragState=false;

			console.log('before', this.timelineEvents);
			this.timelineEvents = this.timelineEvents.filter(r=>r.id!==id);
			this.clearEventSelection();
			console.log('after', this.timelineEvents);
		},

		highlightTimelineEvent(label){
			this.currentHighlightedEvent = label;
		},

		getLabelPosX(label){
			const middle = ((label.end+label.start)/2);
			return middle/this.duration;
		},

		getLabelPosY(label){
			const yOffset = this.eventTypes.indexOf(label.type);
			return yOffset;
		}
	}
}
</script>


<style scoped>
.timeline-wrap {
	display: flex;
	width: 100%;
}

.event-types-wrapper {
	flex: 0 0 250px;
}

.event-types-wrapper .title {
	height: 87px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin: 0;
	padding: 15px;

}

.event-types-wrapper .event-type {
	height: var(--row-height);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.timeline {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	color: white;
	/* border: 1px solid white; */
	/* border-radius: 5px; */
	/* background-color: white; */
}

.timeline-event-grid {
	height: 100%;
	width: 100%;
	position: absolute;
}
.timeline-event-grid > div {
	position: relative;
	margin-top: 49px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	z-index: -1;
}

.seek-bar {
	width: 100%;
	height: 3px;
	margin: 5px 0;
	background-image: linear-gradient(90deg, #2876D4 var(--percent), #ADCAEE var(--percent));

}

.controls {
	display: flex;
	width: 100%;
	height: 55px;
	padding: 5px;
	background: rgba(255, 255, 255, 0.1);
	color: #95A8BE;
}
.controls .button {
	border-radius: 5px;
	padding: 0.35em 0.5em;
	cursor: pointer;
	margin-right: 5px;
}
.controls .button:hover {
	background: rgba(255, 255, 255, 0.1);
}

.selector {
	width: 100%;
	height: 100%;
	position: relative;
	border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.selector .focus {
	width: 1px;
	height: 100%;
	background: #005399;
	pointer-events: none; /* prevent mousedown event from firing on me */
	position: absolute;
	left: var(--percent);
}
.selector .focus::before {
	content: '';
	position: absolute;
	width: 11px;
	height: 11px;
	top: -10px;
	left: -5px;
	background: #005399;
	border-radius: 2px;
}
.selector .focus-hover {
	width: 2px;
	height: 100%;
	background: #ffff;
	pointer-events: none;
	position: absolute;

	left: var(--hover);
}

.selector .selection {
	width: var(--selection-width);
	height: var(--row-height);
	border-top: 1px solid #e5eaf122;
	border-bottom: 1px solid #e5eaf122;
	border-radius: 5px;

	background-color: var(--display-color);
	z-index: 0;
	pointer-events: none;
	position: absolute;
	left: var(--selection-start);
	top: calc( var(--selection-top) * var(--row-height) );
	z-index: 10;
}

.selector .selection .draggable-start {
	width: 6px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	height: 100%;
	z-index: 1;
	background: #2876D4;
	pointer-events: none;
	position: absolute;

	left: -3px;
	/*left: var(--selection-start);*/
}
.selector .selection .draggable-start::before {
	content: '';
	position: absolute;
	width: 1px;
	height: 11px;
	top: 18px;
	left: 2px;
	background: black;
}

.selector .selection .draggable-end {
	width: 6px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 100%;
	background: #2876D4;
	pointer-events: none;
	z-index: 1;
	position: absolute;

	/*left: var(--selection-end);*/
	right: -3px;
}
.selector .selection .draggable-end::before {
	content: '';
	position: absolute;
	width: 1px;
	height: 11px;
	top: 18px;
	left: 2px;
	background: black;
}

.tag-editor {
	position: absolute;
	height:100px;
	width:200px;
	background-color: black;
	border:1px solid rgb(166,199,201);
	border-radius: 7px;
	left: calc(var(--highlight-position-x));
	opacity: 0.9;
	top: calc( var(--selection-top) * var(--row-height));
	/* bottom:10px; */
	z-index: 9999;

}

.tag-editor code {
	color: white;
	font-size: 11px;
	font-weight: 900;

}
.tag-editor::before {
	content:"";
    border-color: white transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    position: absolute;
    left: 0px;

	top: 99px;
	transform: rotate(50);
}

.tag-editor-content {
	/* padding: 10px; */
	padding-left:  10px;

	color: white;

	height:100%

}
.x-axis {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
}

.label {
	flex: 1 1 0;
	position: relative;
	color: #FFF9;
}
.label .text {
	margin-left: 5px;
	font-size: smaller;
}
.label .tick {
	position: absolute;
	height: 100%;
	width: 1px;
	top: 0;
	background: currentColor;
	opacity: 0.5;
}
.label:last-child {
	max-width: var(--lastWidth);
	flex: 0 0 var(--lastWidth);
}


.duration-display {
	font-size: 12px;

}


.button .active {
	background-color: red;

}
</style>