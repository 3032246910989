<template>
<div style="margin-top: 50%;">
	<div class="card border-secondary mb-3" style="width: 100%; height: 100%;">
		<div class="card-body">
			<h5 class="card-title" >Sign in to your account</h5>
			<form class="login" @submit.prevent="login">
				<input required v-model="email" type="email" placeholder="Email" class="form-control"/>
				<input required v-model="password" type="password" placeholder="Password" :class="getPasswordClass()"/>
				<div v-if="!isCorrect" class="invalid-feedback">Sorry, password or email is incorrect</div>
				<button @click="login()" type="submit" class="btn btn-outline-info btn-sm">Login</button>
			</form>
		</div>
	</div>
</div>
</template>

<script>
export default {
	data(){
		return {
			email : "",
			password : "",
			isCorrect: true
		}
	},
	methods: {
		login: function () {
			let email = this.email;
			let password = this.password;         
			this.$store.dispatch('login', { email, password }).catch(err => {
				this.incorrectPassword();
			});
		},
		incorrectPassword: function(){
			this.isCorrect = false;
		},
		getPasswordClass: function(){
			if(this.isCorrect === true){
				return "form-control";
			}
			else{
				return "form-control is-invalid";
			}
		}
	}
}
</script>


<style scoped>
input {
	margin-bottom:15px;
}

.invalid-feedback{
	margin-bottom: 8px;
}
</style>