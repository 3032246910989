<template>
<span :class="{updated: isUpdated}">
	<slot></slot>
	{{value}}
</span>
</template>

<script>
import {Delay} from '@/utils/promises.js';

export default {
	name: 'RealTimeValue',
	props: ['value'],

	data: () => ({
		isUpdated: false
	}),


	watch: {
		async value(newVal, oldVal)
		{
			if (newVal !== oldVal)
			{
				this.isUpdated = true;
				await Delay(1000);
				this.isUpdated = false;
			}
		}
	}
}
</script>

<style scoped>
@keyframes glowing {
	0%,100% {
		box-shadow: 0 0 0 #2ba805;
	}
	50% {
		background-color: #49e819;
		box-shadow: 0 0 10px #49e819;
	}
}

.updated {
	animation: glowing 1s;
}
</style>