/**
 * ExportTableAsCSV
 * Exports an array-formatted table to CSV file. 
 * @param {Array<Object>} items  Array representing table entries e.g. [{heading: value, ...}, ...]
 * @param {String} tableName  Added to filename: 'blackai_labelling_${tableName}_${ISOdatetime}.csv'.
 */
export function ExportTableAsCSV(table, tableName)
{
	if (!table.length || !tableName)
	{
		console.error('Cannot export table as CSV: Invalid parameters.\n', table, tableName);
		return;
	}

	const headers = Object.keys(table[0]);
	const timestamp = new Date().toISOString();
	const fileName = `blackai_labelling_${tableName}_${timestamp}`;

	let csv = headers.reduce((row, header) => row + header + ',', '') + '\r\n';
	csv += table.reduce(
		(csv, row) => csv + Object.values(row).reduce(
			(row, entry) => row + (entry??'') + ',', '') + '\r\n' // comma-separate entries, newline separate rows
		, '')

	ExportStringToFile(csv, fileName, 'csv', 'text/csv');
}


/**
 * ExportPlainTextToFile
 * Saves plain text to specified filename with optional extension. The file is automatically downloaded.
 * @param {String} data  String formatted data to export.
 * @param {String} fileName  Name of file to save. Should comply with filesystem naming restrictions.
 * @param {?String} extension  File type. Defaults to 'txt'.
 * @param {?String} mime  MIME type. Defaults to 'text/plain'. Note 'charset=utf-8' is always set.
 */
export function ExportStringToFile(data, fileName, extension='txt', mime='text/plain')
{
	const exportFilename = `${fileName}.${extension}`;
	const blobData = new Blob([data], {type:`${mime};charset=utf-8;`});

	if (navigator.msSaveBlob) // IE 10+
	{
		navigator.msSaveBlob(blobData, exportFilename);
	} else
	{
		const link = document.createElement('a');
		if (link.download !== undefined) // Browsers that support HTML5 'download' attribute
		{
			const url = URL.createObjectURL(blobData);
			link.setAttribute('href', url);
			link.setAttribute('download', exportFilename);
			link.style.display = 'none';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			setTimeout(() => URL.revokeObjectURL(url), 0);
		} else
		{
			console.error(`Could not export ${fileName}, the user agent does not support blob download.`)
		}
	}
}
