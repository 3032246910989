/**
 * Deferred
 * Extends Promise to expose resolve/reject functions.
 */
export class Deferred extends Promise
{
	constructor()
	{
		let _resolve, _reject;
		super((resolve, reject) => {
			_resolve = resolve;
			_reject = reject;
		});
		this.resolve = function(...args) {this.status = 'Resolved'; _resolve(args)};
		this.reject = function(...args) {this.status = 'Rejected'; _reject(args)};
		this.status = 'Pending';
	}

	// Required to return a Promise for then/catch/finally
	static get [Symbol.species]() {
		return Promise;
	}

	get [Symbol.toStringTag]() {
		return 'Deferred';
	}
}

/**
 * CancelablePromise
 * Extends Promise to expose a cancel function, which forces rejection.
 */
export class CancelablePromise extends Promise
{
	constructor(executor)
	{
		let _reject;
		super((resolve, reject) => {
			_reject = reject;
			executor(resolve, reject);
		});
		this.cancel = function(...args) {this.status = 'Canceled'; _reject(args)};
		this.status = 'OK';
	}

	// Required to return a Promise for then/catch/finally
	static get [Symbol.species]() {
		return Promise;
	}

	get [Symbol.toStringTag]() {
		return 'CancelablePromise';
	}
}

/**
 * Delay
 * Returns an awaitable Promise for a timeout.
 */
export function Delay(timeout)
{
	return new Promise(resolve => setTimeout(resolve, timeout));
}