import axios from "axios";

/**
 * This API endpoint will either be served directly from a production env,
 * or will direct to the devServer target in vue.config.js
 */
const targetAPI = '/api';

/**
 * DONT CHANGE BELOW.
 */

var dataAPI = axios.create({
	baseURL: targetAPI,
	headers: {
		"Content-type": "application/json",
		"Access-Control-Allow-Origin": "*",
	}
});

var directoryAPI = axios.create({
	baseURL: targetAPI.replace(/api/g,''),
	headers: {
		"Content-type": "application/json",
		"Access-Control-Allow-Origin": "*",
	}
});

const token = localStorage.getItem('token');
if (token) {
	dataAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
export var dataAPI;
export var directoryAPI;

// Video to Frame API


// ML Server
export const mlserver = axios.create({
	baseURL: 'http://10.1.1.8:8080/',
	headers: {
		"Content-type": "application/json",
		"Access-Control-Allow-Origin": "*",
	}
});

export const kitchenSync = axios.create({
	baseURL: 'http://wmt2-ks.black.ai.lan:10180/',
	headers: {
		"Content-type": "application/json",
		"Access-Control-Allow-Origin": "*",
	}

});

export const benchmarkKitchenSync = axios.create({
	baseURL: 'https://supervisor.black.ai:4201/',
	headers: {
		"Content-type": "application/json",
		"Access-Control-Allow-Origin": "*",
	}
});

export const dagsterEndpoint = axios.create({
	baseURL: 'http://supervisor.black.ai.lan:3000/',
	headers: {
		"Content-type": "application/json",
	}
});


export const socketAPI = targetAPI.replace(/api/g,'');
