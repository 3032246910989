import Vue from 'vue'
import Vuex from 'vuex'
import { dataAPI } from "../http-common";
import jwt_decode from 'jwt-decode';

import axios from 'axios';

import admin from './modules/admin.store';
import cache from './modules/cache.store';
import playground from './modules/playground.store';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		admin,
		cache,
		playground
	},
	state: {
		tracks: [],
		interactions: [],
		status: '',
		token: localStorage.getItem('token') || '',
		user : {},
		dbSelected: '',
		databaseName: 'hfm1data2020cloud',
		datasetDate: '2020-10-06'
	},
	getters: {
		interactionCount(state) {
			return state.interactions.length;
		},
		findInteraction : (state) =>  (uuid) => {
			return state.interactions.filter(interaction => interaction.uuid === uuid)[0];
		},
		findInteractionIndex : (state) =>  (uuid) => {
			return state.interactions.findIndex((i)=>{
				return i.uuid === uuid;
			});
		},
		nextInteraction : (state) =>  (uuid) => {
			let this_interaction = state.interactions.filter(interaction => interaction.uuid === uuid)[0];
			let this_index = state.interactions.indexOf(this_interactions);
			return state.interactions[(this_index + 1)];
		},
		trackCount(state) {
			return state.tracks.length;
		},
		findTrack : (state) =>  (uuid) => {
			return state.tracks.filter(track => track.trackUuid === uuid)[0];
		},
		findTrackIndex : (state) =>  (uuid) => {
			return state.tracks.findIndex((t)=>{
				return t.trackUuid === uuid;
			});
		},
		nextTrack : (state) =>  (uuid) => {
			let this_track = state.tracks.filter(track => track.trackUuid === uuid)[0];
			let this_index = state.tracks.indexOf(this_track);
			return state.tracks[(this_index + 1)];
		},
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
	},
	mutations: {
		trackDone(state, uuid) {
			console.log("$store: track.done() called for track:", uuid);
			console.log("state:", state, "track uuid:", uuid, 'this:', this);
			if (uuid) {
				let index = this.getters.findTrackIndex(uuid);
				if (state.tracks[index]) {
					state.tracks[index].isDone = true;
				}
			} else {
				console.log("$store: track not found.");
			}
		},
		interactionDone(state, uuid) {
			console.log("$store: interaction.done() called for interaction:", uuid);
			console.log("state:", state, "track uuid:", uuid, 'this:', this);
			if (uuid) {
				let index = this.getters.findInteractionIndex(uuid);
				if (state.interactions[index]) {
					state.interactions[index].isDone = true;
				}
			} else {
				console.log("$store: interaction not found.");
			}
		},
		add(state, track) { // Legacy function; please use 'addTrack' instead.
			let uuid = (track.trackUuid)? track.trackUuid : (track.mergeUuid)? track.mergeUuid : (track.visitUuid)? track.visitUuid : undefined;
			let type = (track.trackUuid)? "trackUuid" : (track.mergeUuid)? "mergeUuid" : (track.visitUuid)? "visitUuid" : undefined;

			if (uuid !== undefined) {
				let matched = state.tracks.filter(t => t[type] === uuid);
				if (matched.length < 1) {
					state.tracks.push(track);
				}
			}
		},
		addTrack(state, track) {
			let uuid = (track.trackUuid)? track.trackUuid : (track.mergeUuid)? track.mergeUuid : (track.visitUuid)? track.visitUuid : undefined;
			let type = (track.trackUuid)? "trackUuid" : (track.mergeUuid)? "mergeUuid" : (track.visitUuid)? "visitUuid" : undefined;

			if (uuid !== undefined) {
				let matched = state.tracks.filter(t => t[type] === uuid);
				if (matched.length < 1) {
					state.tracks.push(track);
				}
			}
		},
		addInteraction(state, interaction) { // Legacy function; please use 'addTrack' instead.
			let uuid = (interaction.uuid)? interaction.uuid : undefined;

			if (uuid !== undefined) {
				let matched = state.interactions.find(track => {
					return(track.uuid === uuid)
				});

				if (!matched) {
					state.interactions.push(interaction);
				}
			}
		},
		clear(state) {
			state.tracks = [];
			state.interactions = [];
		},
		auth_request(state){
			state.status = 'loading'
		},
		auth_success(state, token, user){
			state.status = 'success';
			state.token = token;
			state.user = user;
		},
		auth_error(state){
			state.status = 'error'
		},
		logout(state){
			state.status = '';
			state.token = '';
		},
	},
	actions: {
		login({commit}, user){
			return new Promise((resolve, reject) => {
				commit('auth_request');
				dataAPI({url: '/login', data: user, method: 'POST' })
					.then(resp => {
						const token = resp.data.token;
						const user = resp.data.user;
						const decoded = jwt_decode(token);
						console.log('decoded token', decoded);
						const email = decoded.email;
						const username = email.split('@')[0];

						// console.log('decoded type', typeof(decoded.is_admin))
						localStorage.setItem('token', token); // set token in local storage
						localStorage.setItem('email', email);
						localStorage.setItem('organisation', email.split('@')[1].split('.')[0]);
						localStorage.setItem('username', (username)); // set token in local storage
						localStorage.setItem('is_admin', decoded.is_admin); // set admin in local storage
						localStorage.setItem('is_new', decoded.is_new); // set admin in local storage
						localStorage.setItem('account_type', decoded.accountType); // set admin in local storage
						localStorage.setItem('is_checker', decoded.is_checker)
						console.log('decoded.is_admin', decoded.is_admin);
						// console.log('localStorage type', typeof(localStorage.getItem('is_admin')))

						axios.defaults.headers.common['Authorization'] = token;
						commit('auth_success', token, user);
							
						window.location.href = "/dashboard";
						resolve(resp)
					})
					.catch(err => {
						commit('auth_error');
						localStorage.removeItem('token');
						reject(err)
					})
			})
		},
		
		logout({commit}){
			return new Promise((resolve, reject) => {
				commit('logout');
				dataAPI.post('/logout', {email: `${localStorage.getItem('email')}`});
				localStorage.removeItem('token');
				localStorage.removeItem('username');
				localStorage.removeItem('is_admin');
				localStorage.removeItem('is_checker');
				delete axios.defaults.headers.common['Authorization'];
				resolve()
			})
		}
	}
});

export default store;
