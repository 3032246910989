
<div id="app">
	<div class="navbar navbar-expand-lg navbar-dark bg-dark">
		<div class="container">
			<div style="display: flex; flex-direction: column; height:42px;width:100px;">
				<a href="/dashboard" class="navbar-brand" style="margin-left:-10px;">
					Supervisor
				</a>
				<div  style="margin-top: -10px">
					<loading-progress
						v-if="isLoading"
						:indeterminate="true"
						:counter-clockwise="true"
						:hide-background="true"
						fillDuration="1"
						style="height: 0px; width:0px; margin-top:-200px;"
						size="100"
					/>
				</div>
			</div>
			<button
				class="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarResponsive"
				aria-controls="navbarResponsive"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon" />
			</button>

			<div id="navbarResponsive" class="collapse navbar-collapse">
				<ul v-if="isLoggedIn" class="navbar-nav">
					<li class="nav-item">
						<span class="nav-link">
							<b>Welcome {{this.username}}!</b>
						</span>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/dashboard">Dashboard</router-link>
					</li>
					<li class="nav-item">
						<router-link v-if="(isAdmin) || (isChecker)" class="nav-link" to="/scatter">ReID</router-link>
					</li>
					<li class="nav-item">
						<router-link v-if="isAdmin" class="nav-link" to="/reidreview">Review</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/playground">Playground</router-link>
					</li>
					
					<li class="nav-item">
						<span class="nav-link" @click="logout">Logout</span>
					</li>
				</ul>
			</div>
		</div>
		<div class = "container" style="display: flex; flex-direction: row; justify-content:flex-end;" >
			<div id="navbox" :class="{updated: newNotification}" @click="toggleNotifications=!toggleNotifications;toggledNotificationType='Notifications';newNotification=false"
				style="display: flex;flex-direction: row;height: 42px;width: 40px;justify-items: center; user-select: none;">
				<div class="collapse navbar-collapse" style="margin-left: 12px">
					<ul v-if="isLoggedIn" class="navbar-nav">
						<li class="nav-item" >
							<i id="notifications" class = "far fa-bell fa-1x" style="color:grey;"></i>
						</li>
					</ul>
				</div>
			</div>
			<div
				id="navbox"
				@click="toggleNotifications=!toggleNotifications;toggledNotificationType='Jobs';"
				style="display: flex;flex-direction: row;height: 42px;width: 40px;justify-items: center; user-select: none;"
				v-if="organisation==='black'"
			>
				<div class="collapse navbar-collapse" style="margin-left: 12px">
					<ul v-if="isLoggedIn" class="navbar-nav">
						<li class="nav-item" >
							<i id="notifications" class = "far fa-running fa-1x" style="color:grey;"></i>
						</li>
					</ul>
				</div>
			</div>
			<Notifications v-show="toggledNotificationType==='Notifications' && toggleNotifications" :newNotifications="[]"/>
		</div>
	</div>

	<div style="padding-left:40px;min-height:80vh;width: 99vw;">
		<div v-if="!isLoggedIn" id="nav">
			<div style="width:350px; margin-left:36%;">
				<Login  />
			</div>
		</div>
		<router-view v-else :store="$store" :username="username" @setIsLoading="setIsLoading"/>
	</div>
</div>
