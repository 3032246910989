<template>
<div>
	<slot :Confirm="RequestConfirmation" :awaiting="awaiting" :context="context"></slot>

	<b-popover
		ref="popover"
		:target="ref"
		:show.sync="visible"
		triggers="manual"
		:title="title"
		:placement="placement"
		boundary="viewport"
		no-fade
	>
		{{ warning }}

		<div class="d-flex mt-2">
			<!-- Confirm -->
			<b-button size="sm" variant="outline-warning" class="mr-1" @click="Confirm">
				<span>Confirm</span>
			</b-button>

			<!-- Confirm -->
			<b-button size="sm" variant="outline-success" @click="Deny">
				<span>Cancel</span>
			</b-button>
		</div>
	</b-popover>
</div>
</template>


<script>
import { Deferred } from '@/utils/promises'
import { BButton, BPopover } from 'bootstrap-vue'

export default {
	name: 'confirmation',

	props: {
		title: {type: String, default: 'Confirm change'},
		warning: {type: String, default: ''},
		placement: {type: String, default: 'auto'}
	},

	components: {
		BButton,
		BPopover
	},

	data: () => ({
		confirmation: null, // Deferred handling interaction state
		visible: false, // shows/hides the popover
		context: null // exposed as a slot option so inputs can temporarily show what they might end up with
	}),

	computed: {
		ref() { return () => this.$el },
		awaiting() { return this.confirmation !== null }
	},

	methods: {
		/**
		 * RequestConfirmation
		 * Displays modal and sets confirmation state
		 */
		async RequestConfirmation(context, callback)
		{
			this.context = context;

			if (this.awaiting)
			{
				console.warn('Confirmation is awaiting a response. To clear this warning, use "disabled" slot property to prevent trigger.')
				return;
			}

			if (!(callback instanceof Function))
			{
				console.error('Callback passed to Confirmation is not of type Function.')
				return;
			}

			try {
				this.confirmation = new Deferred();
				this.visible = true;
				//console.log('Awaiting confirm request!')

				await this.confirmation; // wait for use interaction to resolve
				callback(context);
			} catch(err)
			{
				// Do nothing!
				//console.log('Deleting confirm request!')
			}

			// Reset state
			this.confirmation = null;
			this.visible = false;
			this.context = null;
		},

		/**
		 * Confirm
		 * Resolves deferred confirmation.
		 */
		Confirm()
		{
			this.confirmation?.resolve();
		},

		/**
		 * Deny
		 * Rejects deferred confirmation.
		 */
		Deny()
		{
			this.confirmation?.reject();
		}
	},

	beforeDestroy()
	{
		// Auto-reject on destruction so promises can clear
		this.Deny();
	}
}
</script>