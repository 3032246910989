import Vue from 'vue'
import Vuex from 'vuex'

import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import VueNoty from 'vuejs-noty'
import VueAnime from 'vue-animejs'

import VueSocketIO from 'vue-socket.io';
import {socketAPI} from "./http-common.js";
import VueKonva from 'vue-konva';
import sentryInit from './sentry.js'

Vue.use(VueKonva);

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueAnime);

Vue.use(VueNoty, {
	timeout: 4000,
	progressBar: true,
	theme: 'sunset',
	layout: 'centerLeft'
});

Vue.use(new VueSocketIO({
	debug: process.env.NODE_ENV === 'development' ? true : false,
	connection: socketAPI,
	options: { path: "/ws/" , withCredentials: true, transports: [ 'websocket','polling' ]}
}));

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.prototype.$shared = {
	eventTypes: [],
};

sentryInit(Vue, router)

const instance = new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');

if (process.env.NODE_ENV === 'development')
{
	// Use for debugging store, i.e. injecting values for testing.
	window.vue = instance;
}


import IdleVue from './utils/idle-vue'

const eventsHub = new Vue();

Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	store,
	idleTime: 1000 * 60, // 1 minute
	startAtIdle: false
});
