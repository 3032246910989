<template>
<div class = "notifications" style="position: fixed; background: black; display: flex; border: 1px solid white;border-radius:5px">
	<b-card class="card mb-3" style="background: black; ; " body-class="scroll-wrap">
		<template #header><code class="mb-0" style="font-size:20px;">Jobs <i :class="`${refreshClass} far fa-sync fa-1x`" @click="refresh()"></i></code></template>
		<div v-if="!isRefreshing && dagRunStatuses.length!==0 && Object.keys(moreInfo).length!==0">
			<div v-for="(dagRun) in dagRunStatuses" v-bind:key="dagRun.runId" class = "card-container" >
				<b-card id = "dagStatus" @click="getMoreInfo(dagRun.runId)">
					<template #header>
						<div>
							<code style="color: white;" >{{dagRun.datasetName}}</code>
							<a :href="pipelineUrl+'instance/runs/'+dagRun.runId" target="_blank">
								<i class="far fa-external-link fa-1x" style="float:right; margin-left:10px"></i>
							</a>
							<i class="fas fa-user fa-1x" style="float:; margin-left: 30%"></i> <code style="margin-left:10px;">{{dagRun.launchedBy}}</code>
							<i class="far fa-times fa-1x" style="float:right; margin-left:10px; color: #E93820" @click="dismissProcess(dagRun.runId)"></i>
						</div>
						<div>
							<code style="color: #BBC7C9;" >{{dagRun.pipelineName}}</code>
						</div>
					</template>
					<code
						v-if="dagRun.status==='STARTED'"
						class="loading">
						Running
					</code>
					<code v-else>
						<div style="color: #E93820;" v-if="dagRun.status==='FAILED'">
							Job Failed
						</div>
						<div style="color: #93E9BE;" v-if="dagRun.status==='SUCCESS'">
							Completed Successfully
						</div>
					</code>
				</b-card>
			</div>
		</div>
		<div v-if="!isRefreshing && dagRunStatuses.length===0" class = "card-container">
			<b-card>
				<div class="card-body">
					<code style="color: white;">
						No running jobs to display...
					</code>
				</div>
			</b-card>
		</div>
		<!-- Place holder cards -->
		<div v-if="isRefreshing" class = "card-container">
			<div v-for="placeHolderDiv in Array.from(Array(5).keys())" :key="placeHolderDiv">
				<b-card class="placeholder">
					<template #header id="statusHeader">
						<code style="color: white;" ></code>
					</template>
					<div class="card-body">
					</div>
				</b-card>
			</div>
		</div>

	</b-card>
</div>
</template>

<script>
import { BButton, BCard, BFormInput as BInput, BOverlay, BTable, VBTooltip } from 'bootstrap-vue'
import { dataAPI, dagsterEndpoint} from "../../http-common";
import 'vue-progress-path/dist/vue-progress-path.css';
import {Delay} from '@/utils/promises';
import VueProgress from 'vue-progress-path';
export default {
	name: 'Jobs',
	props: {

	},
	components: {
		BButton,
		BCard,
		BInput,
		BOverlay,
		BTable,
		VueProgress,
	},
	data: () => ({
		dagRunStatuses: [],
		moreInfo: {},
		isRefreshing: false,
		refreshClass: '',
	}),
	computed: {
		pipelineUrl(){
			return dagsterEndpoint.defaults.baseURL;
		}
	},
	watch: {

	},
	methods: {
		getProgress(runId,index){
			const statuses = this.dagRunStatuses[index]?.[runId] ?? [];
			return statuses.filter(r => r.state === 'success').length / (statuses.length || 1); // if 0, changes to 1 to avoid Infinity
		},
		async refresh(){
			Promise.all([this.getRunningJobs()])
		},
		getMoreInfo(dagID){
			this.$set(this.moreInfo, dagID, !this.moreInfo[dagID])
		},
		async getRunningJobs(){
			if(localStorage.getItem('organisation') === 'black'){
				this.isRefreshing=true;
				this.refreshClass = 'spinning';
				try{
					this.dagRunStatuses = (await dataAPI.post('pipeline/getRunningDagInfo')).data;
					this.dagRunStatuses.map(dagRun => {
						const key = Object.keys(dagRun)[0];
						this.$set(this.moreInfo, key, false)
						this.moreInfo[key] = false;
					})
					await Delay(1000)
					this.refreshClass = '';
					this.isRefreshing=false;
				}
				catch(error){
					this.$noty.error(`Unable to get running dag info: ${error}`)
				}
			}
		},
		async dismissProcess(runId){
			try{
				await dataAPI.post('pipeline/dismissDagRun', {runId: runId});
				this.dagRunStatuses = this.dagRunStatuses.filter(r => r.runId !== runId);
			}
			catch(error){
				this.$noty.error(`Error dismissing job: ${error}`);
			}
		}
	},
	async mounted()
	{
		this.refresh()
	}
}
</script>

<style scoped>
	.notifications {
		z-index:9999999;
		margin-top: 860px;
		position: absolute;
		display: flex;
		width:fit-content;
		height:800px;
		/* border: 1px solid red; */
	}

	.statusHeader:hover{
		background-color: white;
		opacity: 0.8;
	}

	.scroll-wrap {
		overflow-y: auto;
	}

	.card-container {
		margin-bottom: 10px;
		width: 800px;
	}

	.done {
		color: #93E9BE;
	}

	@keyframes dots {
		0%, 20% {
			display: none;
		}
		40% {
			color: rgb(156,57,125);
			text-shadow:
			.25em 0 0 rgb(156,57,125),
			.5em 0 0 rgb(156,57,125);
		}
		60% {
			text-shadow:
			.25em 0 0 rgb(156,57,125),
			.5em 0 0 rgb(156,57,125);
		}
		80%, 100% {
			text-shadow:
			.25em 0 0 rgb(156,57,125),
			.5em 0 0 rgb(156,57,125);
		}
	}
	.spinning{

		-webkit-animation-name: spin;
		-webkit-animation-duration: 4000ms;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		-moz-animation-name: spin;
		-moz-animation-duration: 4000ms;
		-moz-animation-iteration-count: infinite;
		-moz-animation-timing-function: linear;
		-ms-animation-name: spin;
		-ms-animation-duration: 4000ms;
		-ms-animation-iteration-count: infinite;
		-ms-animation-timing-function: linear;
		animation-name: spin;
		animation-duration: 4000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@-ms-keyframes spin {
		from { -ms-transform: rotate(0deg); }
		to { -ms-transform: rotate(360deg); }
	}
	@-moz-keyframes spin {
		from { -moz-transform: rotate(0deg); }
		to { -moz-transform: rotate(360deg); }
	}
	@-webkit-keyframes spin {
		from { -webkit-transform: rotate(0deg); }
		to { -webkit-transform: rotate(360deg); }
	}
	@keyframes spin {
		from {
			transform:rotate(0deg);
		}
		to {
			transform:rotate(360deg);
		}
	}
	.loading:after {
		content: '.';
		animation: dots 1s steps(5, end) infinite;
	}

    .placeholder {
        margin-bottom: 10px;
        animation-duration: 2.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
        background: linear-gradient(to right, #1E1D1B 8%, #000000 18%, #1E1D1B 33%);
        background-size: 1200px 100%;
    }

    @-webkit-keyframes shimmer {
        0% {
            background-position: -100% 0;
        }
        100% {
            background-position: 100% 0;
        }
    }

    @keyframes shimmer {
        0% {
            background-position: -1200px 0;
        }
        100% {
            background-position: 1200px 0;
        }
    }

</style>