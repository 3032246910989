
<div class="card" style="margin-left:-26px;margin-right:25px;height:100%;border:transparent; background-color: #001014;">
	<div v-for="icon in icons" v-bind:key="icon" class="icons">
		<div class="card-body" @click="setCurrentSection(icon)" :style="`padding-bottom:10px; border-left: 4px solid ${(currentSection===icon) ? '#007fff' : '#001014' }; display: flex; flex-direction: row;`">
			<i :class="iconImageMap[icon]" style="padding-left:2px;margin-right:10px;">
			</i>
			<h5 class="card-title" style="font-size: 0.9em;font-weight: 3px;">

				{{icon.slice(0,1).toUpperCase()+icon.slice(1,icon.length)}}
			</h5>
		</div>
	</div>
</div>
