
<!-- Use v-b-modal directive and assign an ID to this component to open it -->
<b-modal
	ref="modal"
	centered
	hide-footer
	no-close-on-backdrop
	no-stacking
	size="lg"
	:id="id"
	v-bind="$attrs"
	@shown="GetSuggestedMergeFeatures"
	@hidden="CancelPendingRequests"
>
	<!-- Title -->
	<template #modal-title>
		Move Track to Correct Merge
	</template>

	<!-- Given local track -->
	<div class="track-highlight mr-4" v-if="localTrack">
		<div class="frame-item">
			<img :src="localTrackThumbnail" :style="ClipCSS(localTrackSubframe)"/>
		</div>
	</div>

	<div class="mr-4">
		<i class="fas fa-arrow-alt-circle-right fa-3x"></i>
	</div>

	<!-- Tiles -->
	<b-overlay :show="loading" variant="transparent" :opacity="1" blur="1px" class="merge-options">
		<div
			v-for="feature of paginatedFeatures"
			:key="feature.mergeUuid"
			class="track-highlight"
		>
			<!-- Track image -->
			<div class="frame-item">
				<img
					:src="framePaths[feature.detectionUuid]"
					:style="ClipCSS(subFrames[feature.detectionUuid])"
				/>
			</div>

			<!-- Reassign button -->
			<b-button size="sm" variant="info" class="block-centered reassign" @click="Reassign(feature)">
				<i class="fas fa-plus"></i>
			</b-button>
		</div>

		<!-- New Merge button -->
		<div v-if="!loading" class="track-highlight new-merge">
			<div class="frame-item"/>
			<b-button
				v-b-tooltip.hover="`Assign to new merge`"
				size="sm" variant="info" class="block-centered reassign" @click="Reassign(/*to a new merge*/)"
			>
				<i class="fas fa-plus"></i>
			</b-button>
		</div>

		<b-pagination
			v-show="numPages > 1"
			v-model="currentPage"
			:per-page="entriesPerPage"
			:total-rows="suggestedFeatures.length + 1"
			align="center"
			class="my-1"
		/>
	</b-overlay>

</b-modal>
