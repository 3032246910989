
<div class="fill">
	<div class="fill">
		<div class="card-header" style="align-items:center;width: 100%; display: flex; flex-direction: row;margin-bottom:10px;">
			<div style="align-items: center;">
				Task ID: {{taskInfo.taskUuid}}
				
			</div>
			
			<div style="margin-left: auto;">
				<button class="btn btn-info" @click="toggleImageCardSize">
					<i :class="`fas fa-search-${size === 'xl' ? 'minus' : 'plus'}`"/>
				</button>
				Merges remaining: {{mergesRemaining}}
				<button
					type="button"
					class="btn btn-outline-info"
					style="border: 2px solid #007fff; height: 30px; width:40px; margin-left:10px;"
					@click="finishTask()"
				>
					<i class="fas fa-check" style="position: relative;left:-1px; top:-5px;"></i>
				</button>
			</div>
		</div>
	
		<div style="display:flex;flex-direction: row; width:100%; height:70vh;">
			<div class="card mb-3" style="margin-left:0px; margin-right:10px;background-color: transparent;height:100%;">
				<p class="card-header">Merge List</p>
				<div style="height: 100%; overflow-y: auto; border: 1px solid #002834; ">
					<ul v-for="(mergeTrack) in targetMerges" class="list-group" id="mergeList" :key="mergeTrack">
						<li
							v-if="mergeListStatus[mergeTrack] === 'unlabelled'"
							class="list-group-item d-flex justify-content-between align-items-center" :id="`${(currentMergeTrack===mergeTrack)?'active':'inactive'}`"
							:style="`border: 1px solid ${(mergeListStatus[mergeTrack]!=='unlabelled') ? `${(mergeListStatus[mergeTrack]==='matchesFound') ? 'green': 'red'}` : 'transparent'}`"
							style="padding:8px;color: white;font-size:0.7em;"
							@click="currentMergeTrack=mergeTrack;">
							{{mergeTrack}}
						</li>
					</ul>
					<li
						v-if="mergesRemaining === 0"
						class="list-group-item d-flex justify-content-between align-items-center"
						style="border: 1px solid transparent;padding:8px;color: white;font-size:0.7em;"
					>
						No Merge Tracks Available
					</li>
				</div>
			</div>

			<!-- TARGET -->

			<div v-if="mergesRemaining" class="card mb-3" style="display: flex; flex-direction: column; width: 30%; background-color: transparent; margin-right:10px;height:100%">
				<p class="card-header">Target</p>
				
				
				<MergeTrackEditor
					v-if="currentMergeTrack"
					:db="db"
					@imageHover="setSelectedTargetHover"
					:inputTable="taskInfo.tableName"
					:mergeUuid="currentMergeTrack"
					:key="currentMergeTrack"
					:resolution="1920"
					:minimal="true"
					ref="mergeEditor"
					:size="size"
					style="border:1px solid transparent;"
					:mode="(consolidateTaskUuid !== '') ? 'read' : 'read/write'"
					:portalDetails="portalDetails"
				>
				</MergeTrackEditor>

			</div>

			<!-- GALLERY -->
			<div v-if="mergesRemaining" class="card mb-3" style="background-color: transparent;width: 30%;margin-right:10px;height:100%">
				<p class="card-header">Gallery</p>

				<div v-if="galleryStatus[currentMergeTrack]==='loaded'" style="overflow-y: auto; height:100%">
					<DetectionGallery
						v-if="galleryMerges[currentMergeTrack] && galleryMerges[currentMergeTrack].length!==0"
						style="border: 0px solid transparent"
						:imagesLoaded="true"
						:points="galleryMerges[currentMergeTrack]"
						:hasCroppedFrames="hasCroppedFrames"
						pointType="mergeUuid"
						:sortPoints="false"
						:pageSize="50"
						:showSettings="false"
						:multiSelect="true"
						:mergeable="false"
						:size="size"
						:selectable="true"
						ref="gallery"
						:key="currentMergeTrack"
						width="100%"
						@updateSelectedLocalTracks="updateSelectedGalleryPoints"
						@imageSelected="galleryClickAction"
					/>
					 
					<div v-if="galleryMerges[currentMergeTrack].length===0">
						<p class="card-header" style="align-self:center">No merges in gallery</p>
					</div>
				</div>

				<div v-else>
					<b-overlay style="margin-top: 50%" :show="true" variant="transparent" blur= "5px" rounded="sm" >

					</b-overlay>
				</div>

			</div>

			<!-- GALLERY SELECTION -->
			<div v-if="mergesRemaining" class="card mb-3" style="display:flex; flex-direction: column; width: 30%; background-color: transparent;margin-right:0px;overflow-x: hidden; overflow-y: auto; max-height:80vh;height:100%" >
				<p v-if="currentGalleryMergeSelected!==''" class="card-header">Merge Viewer: {{currentGalleryMergeSelected.mergeUuid}}</p>
				<p v-else class="card-header">Select a gallery merge to view below...</p>
				

				<!-- Merge editor to expand merges in gallery -->

				<MergeTrackEditor
					v-if="currentGalleryMergeSelected!==''"
					:db="db"
					:inputTable="taskInfo.tableName"
					:mergeUuid="currentGalleryMergeSelected.mergeUuid"
					:key="currentGalleryMergeSelected.mergeUuid"
					:resolution="1920"
					:minimal="true"
					:size="size"
					@imageHover="setSelectedGalleryHover"
					ref="mergeEditor"
					style="width: 100%;border:1px solid transparent;"
					:mode="(consolidateTaskUuid !== '') ? 'read' : 'read/write'"
					:portalDetails="portalDetails"
				>
				</MergeTrackEditor>

			</div>
		</div>
	</div>

	<div>
		<button
			v-if="selectedGalleryMerges.length!==0"
			type="button"
			class="btn btn-outline-info"
			style="border: 2px solid #007fff;margin-top:10px;"
			@click="consolidateMergeTracks()"
		>
			Consolidate {{selectedGalleryMerges.length}} with target
		</button>

		<button
			v-if="selectedGalleryMerges.length===0 && mergesRemaining"
			type="button"
			class="btn btn-outline-warning"
			style="border: 2px solid #C44315;margin-top:10px;"
			@click="noMatch()"
		>
			Unable to find a match
		</button>
		{{user}}
		<button
			v-if="selectedGalleryMerges.length>0"
			type="button"
			class="btn btn-outline-warning"
			style="border: 2px solid #C44315;margin-top:10px;margin-left:10px"
			@click="clearGallerySelection()"
		>
			Clear selection
		</button>
	</div>
</div>

