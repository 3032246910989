
<section class="workflow-container">
	<div class="sidebar">
		<h6>Labels</h6>
		<b-card v-for="label in labels.filter(r=>r.type==='classification')" :key="label.labelTitle" :header="label.labelTitle">
			<b-list-group v-for="field in label.fields" :key="field">
				<b-list-group-item
					href="#"
					:class="{active: createdLabels[label.labelTitle] === field}"
					@click="fieldSelected(label.labelTitle, field)"
				>
					{{field}}
				</b-list-group-item>
			</b-list-group>
		</b-card>
	</div>

	<div class="right-container">
		<div class="settings">

			<div v-if="containsMultipleImages" class="info-section">
				{{labellingInputData.uuidsToLabel.length}} remaining
				<div class="pagination">
					Multiple Angles Available:
					<b-pagination
						v-model="currentImageIndex"
						:total-rows="supportingData[currentUuid].length"
						:per-page="1"
					/>
				</div>
			</div>
			<div class="ml-auto">
				<b-button variant="outline-warning" size="sm" @click="resetZoom()">
					<i class="fas fa-sync"/>
				</b-button>
				<b-button variant="outline-info" class="ml-4" size="sm" @click="submitLabel(currentUuid, createdLabels)">
					Submit
				</b-button>
			</div>

		</div>

		<div v-if="activeImage" class="image-container" :style="`width: ${stageHeight}px; height: ${stageWidth}px;`">
			<v-stage ref="stage" :config="{height: stageHeight, width: stageWidth}" @wheel="zoom">
				<v-layer ref="layer">
					<v-image :config="{
						image: activeImage,
						height: stageHeight,
						width: stageWidth
					}"
					/>
					<v-rect :config="{
						stroke: 'red',
						strokeWidth: 5,
						x: activeSubframeBounds.left,
						y: activeSubframeBounds.top,
						width: activeSubframeBounds.width,
						height: activeSubframeBounds.height,
					}" />
				</v-layer>
			</v-stage>
		</div>
	</div>
</section>
