/**
 * cache.store.js
 * Vuex module for acquiring and caching data, e.g.
 * - Merge tracks
 * - Local tracks
 * - Detections
 * - Frames
 */
import Vue from 'vue';
import { dataAPI } from '@/http-common';
import { PickProperties } from '@/utils/objects';

export default {
	namespaced: true,

	state: () => ({
		mergeLocalTracks: {},      // 'UUID' : Array<LocalTrack>  -- maybe we only need local track UUIDs?
		localTrackDetections: {},  // 'UUID' : Array<Detection>
		detectionSubframes: {},    // 'UUID' : Subframe
		detectionFramePaths: {},   // 'UUID' : URL
	}),

	mutations: {
		ClearAll(state)
		{
			// Useful e.g. if leaving a view and cached data is no longer needed.
			state.mergeLocalTracks = {};
			state.localTrackDetections = {};
			state.detectionSubframes = {};
			state.detectionFramePaths = {};
		},

		SetMergeLocalTrack(state, {mergeUUID, localTrack})
		{
			const mergeLocalTracks = state.mergeLocalTracks[mergeUUID] ??
				Vue.set(state.mergeLocalTracks, mergeUUID, []); // Create if null

			if (!mergeLocalTracks.find(({localTrackUuid}) => localTrackUuid === localTrack.localTrackUuid))
			{
				mergeLocalTracks.push(localTrack);
			}
		},

		SetMergeLocalTracks(state, {mergeUUID, localTracks})
		{
			Vue.set(state.mergeLocalTracks, mergeUUID, localTracks);
		},

		SetLocalTrackDetection(state, {localTrackUUID, detection})
		{
			const localTrackDetections = state.localTrackDetections[localTrackUUID] ??
				Vue.set(state.localTrackDetections, localTrackUUID, []); // Create if null

			if (!localTrackDetections.find(({detectionUuid}) => detectionUuid === detection.detectionUuid))
			{
				localTrackDetections.push(detection);
			}
		},

		SetLocalTrackDetections(state, {localTrackUUID, detections})
		{
			Vue.set(state.localTrackDetections, localTrackUUID, detections);
		},

		SetDetectionSubframe(state, {detectionUUID, subframe})
		{
			// Property checking
			if (!('minimumX' in subframe) ||
			    !('minimumY' in subframe) ||
			    !('maximumY' in subframe) ||
			    !('maximumY' in subframe) )
			{
				console.error(`Missing required properties for subframe description for detection ${detectionUUID}:`, subframe);
				return;
			}
			Vue.set(state.detectionSubframes, detectionUUID, {
				top: subframe.minimumY,
				right: subframe.maximumX,
				bottom: subframe.maximumY,
				left: subframe.minimumX
			});
		},

		SetDetectionFramePath(state, {detectionUUID, framePath})
		{

			// Property checking
			if (framePath === '')
			{
				console.error(`Missing framePath for detection ${detectionUUID}.`);
				return;
			}

			Vue.set(state.detectionFramePaths, detectionUUID, framePath);

		}
	},

	actions: {
		/**
		 * GetMergeLocalTracks
		 * Fetches all local tracks for a particular merge UUID.
		 */
		async GetMergeLocalTracks({rootState, commit}, {mergeUUID, forceUpdate=false})
		{
			const presetQueryID = 10;
			try
			{
				const {data} = await dataAPI.get(`/${rootState.databaseName}/preset/${presetQueryID}`, {params: {mergeUuid: mergeUUID}})
				console.log(`Retrieved local tracks for merge ${mergeUUID}:`, data);
				// TODO: mutation
			} catch(error)
			{
				console.error(`Could not get local tracks for merge ${mergeUUID}`);
				return null;
			}
		},

		/**
		 * GetLocalTracksDetections
		 * Caches all detections for a local track if needed.
		 * @returns {Object<UUID, Array<Detection>>}
		 */
		async GetLocalTracksDetections({rootState, state, commit}, {localTracks, forceUpdate=false})
		{
			// If using existing cache, ignore existing entries
			let filteredLocalTracks = localTracks;
			if (!forceUpdate)
			{
				filteredLocalTracks = localTracks.filter(localTrack => !(localTrack.localTrackUuid in state.localTrackDetections));
			}
			// TODO: else clear all detections in cache in case they need to be hard-updated? (i.e. delete all entries with localTrackUUID)

			try
			{
				if (filteredLocalTracks.length > 0)
				{

					filteredLocalTracks.forEach(detection => {
						commit('SetLocalTrackDetection', {
							localTrackUUID: detection.localTrackUuid,
							detection
						});
						commit('SetDetectionSubframe', {
							detectionUUID: detection.detectionUuid,
							subframe: PickProperties(detection, ['minimumX','minimumY','maximumX','maximumY'])
						});
						commit('SetDetectionFramePath', {
							detectionUUID: detection.detectionUuid,
							framePath: detection.framePath
						});
					});
				}

				return PickProperties(state.localTrackDetections, localTracks);
				// This returns array references, so they will be bidirectionally reactive
				// unless we ever overwrite one of these arrays in localTrackDetections.
				// In this edge case - or if we delete an entire localTrackUUID key from localTrackDetections -
				// the detection array will be detached from the store but will remain reactive in local contexts.
			} catch(error)
			{
				console.error(`Could not update local track detections for local tracks:`, localTrackUUIDs, error);
				return null;
			}
		},

		/**
		 * GetDetectionsFramePaths
		 * Specialised function to cache framepaths for specific detection UUIDs (e.g. from feature similarity).
		 * TODO
		 */
		async GetDetectionsFramePaths({rootState, commit}, {detectionUUIDs, forceUpdate=false})
		{
			try
			{
				// Get detection framePaths
				const {data} = await dataAPI.post(`/${rootState.databaseName}/frame/get/framePaths`, {
					detectionUuids: detectionUUIDs, // TODO: Filter out any existing from cache
					dataset: rootState.datasetDate  // TODO: handle this selection in root state
				});
				// TODO: mutation
				//Object.entries(data).forEach( ([detectionUuid, framePath]) =>
				//	this.$set(this.framePaths, detectionUuid, server.defaults.baseURL + framePath) );

				// TODO: return all
			} catch(error)
			{
				console.error('Failed to update framePaths for detections.', error);
				return null;
			}
		},

		/**
		 * GetDetectionsSubframes
		 * Specialised function to cache subframes for specific detection UUIDs (e.g. from feature similarity).
		 */
		async GetDetectionsSubframes({rootState, commit}, {detectionUUIDs, forceUpdate=false})
		{
			const {data} = await dataAPI.post(`/${this.databaseName}/preset/1`, {
				detections: detectionUUIDs.join(),
				reidInput: `reidInput_reidlocaltracks${this.datasetDate}`
			});

			const subFrames = data
				.filter( ({label, textureLabel}) => label === 'person' && textureLabel === 'rgb' )
				// TODO: mutation
				/*.forEach( subFrame => commit('SetDetectionSubframe',
					subFrame.detectionUuid,
					{
						top: subFrame.minimumY,
						right: subFrame.maximumX,
						bottom: subFrame.maximumY,
						left: subFrame.minimumX
					}
				) );*/
		},

		/**
		 * GetDataForLocalTracks
		 * Updates all data for multiple local tracks.
		 */
		async GetDataForLocalTracks({dispatch}, {localTracks, forceUpdate=false})
		{
			return await dispatch('GetLocalTracksDetections', {localTracks, forceUpdate});
		},

		/**
		 * GetDataForLocalTrack
		 * Updates all data for a local track.
		 */
		async GetDataForLocalTrack({dispatch}, {localTrack, forceUpdate=false})
		{
			return await dispatch('GetDataForLocalTracks', {localTracks: [localTrack], forceUpdate});
		},

		/**
		 * GetDataForMerge
		 * Updates all data for a merge.
		 */
		async GetDataForMerge({dispatch, state}, mergeUUID)
		{
			await dispatch('GetMergeLocalTracks');

			const localTracks = state.mergeLocalTracks[mergeUUID];
			await Promise.all( localTracks.map(({localTrack}) => dispatch('GetDataForLocalTrack', localTrack)) );
		}
	}
}