
<div style="display:flex; flex-direction: column;height:100%">
	<b-modal
		id="fullframe-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		hide-footer
	>
		<FullFrameDisplay 
			style="height: 1000px; width: 500px;"
			:imageScale="2.7"	
			:framePath="currentFullFrameImage"
			:size="fullFramePathSizeMap[currentDetectionUuid]"
			:subFrame="currentFullFrameSubFrame"
		/>

	</b-modal>
	<div class="sidebar">
		<div class="sidebar-scroll-wrapper">
			<div v-if="globalMergedTracks" style="width:100%">
				<h1 v-if="globalMergedTracks.length">
					<code>Merge  {{currentMergeTrack+1}}/{{globalMergedTracks.length}}</code>
				</h1>
				<button :style="`${(currentMergeTrack+1 === globalMergedTracks.length) ? 'width: 96%' : 'width:48%'}`" class="btn btn-outline-info" @click="currentMergeTrack = parseInt(currentMergeTrack)-1" v-if="currentMergeTrack>0">
					<i class="fas fa-arrow-left"></i>
				</button>
				<button :style="`${(currentMergeTrack === 0) ? 'width: 96%' : 'width:48%'}`" v-if="(globalMergedTracks.length)&&(currentMergeTrack+1)!==globalMergedTracks.length" class="btn btn-outline-info" @click="currentMergeTrack = parseInt(currentMergeTrack)+1">
					<i class="fas fa-arrow-right"></i>
				</button>

				<div style="margin-top:10px;" v-if="currentMergeUuid || numRemovals">
					<code>Merge ID:</code> <br>
					<code>{{currentMergeUuid}}</code>
				</div>

			</div>

			<div v-if="taskUuid === ''" style="display:flex; flex-direction: column; margin-top:10px;">
				<div v-if="datasetsToggled" style="display: flex-wrap; flex-direction: row; width: 95%">
					<code style="padding-top:7px;">Dataset:</code>
					<select v-if="this.taskUuid===''" v-model="currentLocalTrackList" style="width: 95%;" class="form-control">
						<option disabled value="">Datasets Available</option>
						<option v-for="dataset in reidLocalTrackLists" :key="dataset.tableName" :value="dataset.tableName">
							{{ dataset.tableName }}
						</option>
					</select>
				</div>
				<div>
					<button style="width:96%; margin-top:10px;"
						v-if="(taskUuid==='')"
						@click="searchToggled=!searchToggled; datasetsToggled=false"
						class="btn btn-outline-success">
						Search
					</button>
				</div>
				<div>
					<button style="width:96%; margin-top:10px;"
						v-if="(taskUuid==='')"
						@click="mergeListButton=!mergeListButton; datasetsToggled=false"
						class="btn btn-outline-success">
						Merge Track List
					</button>
				</div>
				<div>
					<button style="width:96%; margin-top:10px;"
						v-if="(loadToggled)&&(currentLocalTrackList)&&(taskUuid==='')"
						@click="getMergeUuidsFromReidLocalTrackList(currentLocalTrackList)"
						class="btn btn-success">
						Load
					</button>
				</div>


				<div v-if="searchToggled" id="data-entry-row" style="display:flex;flex-direction:column; width: 96%;height:100%">
					<input v-model="selectedMergeUuid" type="text" placeholder="Search for a Merge Track here" class="form-control">
					<button v-if="selectedMergeUuid" @click="loadMergeUuid(selectedMergeUuid)" class="btn btn-info btn-sm"> Load Merge Track {{selectedMergeUuid}} </button>


					<input placeholder="Go to track" v-model="temporaryMergeUuid" class="form-control">
					<button v-if="temporaryMergeUuid" @click="currentMergeTrack = parseInt(temporaryMergeUuid)-1" class="btn btn-info btn-sm"> Load Index {{temporaryMergeUuid}} </button>

					<input v-model="selectedTaskUuid" type="text" placeholder="Task Uuid" class="form-control">
					<button v-if="selectedTaskUuid" @click="getCleanMergeUuidsFromTaskUuid(selectedTaskUuid)" class="btn btn-info btn-sm"> Load Task {{selectedTaskUuid}} </button>

					<input v-model="selectedMergeLength" type="text" placeholder="Merges of length" class="form-control">
					<button v-if="selectedMergeLength" @click="getMergesOfLength(selectedMergeLength)" class="btn btn-info btn-sm"> Load Merges of length: {{selectedMergeLength}} </button>

					<button @click="getStaffMerges()" class="btn btn-outline-info"> Load Staff Merges </button>
					<button @click="getDeletedMerges()" class="btn btn-outline-info"> Load Deleted Merges </button>

				</div>
			</div>
			<!--
		<button :style="`${(currentMergeTrack === 0) ? 'width: 96%' : 'width:48%'}`" v-if="(globalMergedTracks.length)&&(currentMergeTrack+1)!==globalMergedTracks.length" class="btn btn-outline-info" @click="currentMergeTrack = parseInt(currentMergeTrack)+1">
				<i class="fas fa-arrow-right"></i>
			</button>

		<button :style="" -->

			<!-- <button style="width: 100%" class="fas fa-align-justify"> -->


			<div v-if="!searchToggled && !mergeListButton" style="min-height:200px; height:30%; overflow-y:scroll; margin-top:20px;">
				<ul v-for="(mergeTrack, index) in globalMergedTracks" class="list-group" id="mergeList" :key="mergeTrack">
					<li class="list-group-item d-flex justify-content-between align-items-center" :id="`${(currentMergeTrack===index)?'active':'inactive'}`"
						:style="`border: 3px solid ${(cleanedTracks.includes(mergeTrack)) ? 'green' : 'transparent'}`"
						style="color: white;"
						@click="currentMergeTrack=(index)">
						{{mergeTrack}}
					</li>
				</ul>
			</div>

			<div v-if="globalMergedTracks" class="footer">
				<div v-if="globalMergedTracks.length" style="width: 100%;">
					<button style="width:100%" class="btn btn-outline-success" v-if="localTracks.length>0" @click="postCleanMergeTrack(currentMergeUuid)">
						Submit as Reviewed <i class="icon fas fa-check"></i>
					</button>
					<template v-if="localTracks.length>0 && mergeDataSource==='acceptedMerges'">
						<button v-if="!isStaff " style="width:100%;margin-left: 0px;" class="btn btn-outline-warning"  @click="markAsStaff(currentMergeUuid)">
							Mark as Staff  <i class="icon fas fa-hard-hat"></i>
						</button>
						<button v-else style="width:100%;margin-left: 0px;" class="btn btn-outline-warning" @click="unMarkAsStaff(currentMergeUuid)">
							Remove Staff Label  <i class="icon fas fa-hard-hat"></i>
						</button>
					</template>
				</div>
				<ul class="list-group">
					<li class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid || numRemovals">
							<code>Page number: {{localTracks.map(r=>r['pageNum']).sort((a,b)=>{return a-b})[0]}}</code>
						</div>
					</li>

					<li class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid || numRemovals">
							<code>Number of removals: {{numRemovals}}</code>
						</div>
					</li>

					<li class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid && taskUuid===''">
							<code>Merge Source: {{ mergeDataSource }}</code>
						</div>
					</li>

					<li v-if="advancedFeatures" class="list-group-item d-flex justify-content-between align-items-center">
						<div v-if="currentMergeUuid || numRemovals">
							<code>Marked as Staff: {{isStaff ? 'Yes' : 'No'}} </code>
						</div>
					</li>
				</ul>
				<button :class="`${(batchSelect===false) ? 'btn btn-outline-info' : 'btn btn-warning'}`" @click="batchSelect=!batchSelect" style="width:100%;">
					<i class="icon fas fa-object-group"></i>
				</button>
				<button v-if="batchSelect && selectedLocalTracks.length>0" class="btn btn-outline-info" style="width:100%;margin-left:-1px;" @click="mergeFromBatchSelect()">
					<code>Merge {{selectedLocalTracks.length}}</code>
				</button>
				<button :class="`${(settingsToggle===false) ? 'btn btn-outline-success' : 'btn btn-warning'}`" @click="settingsToggle=!settingsToggle" style="width:100%;">
					<i class="icon fas fa-cogs"></i>
				</button>
				<button class="btn btn-outline-info" @click="toggleImageCardSize" style="width:100%;">
					<i :class="`fas fa-search-${size === 'xl' ? 'minus' : 'plus'}`"/>
				</button>

					
				<template v-if="settingsToggle">
					<div v-if="!this.taskUuid" class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="checkbox1" @click="loadClean=!loadClean">
						<label class="custom-control-label" for="checkbox1">Currently viewing {{loadClean ? 'Reviewed/Clean' : 'Unreviewed'}} Merge Tracks</label>
					</div>

					<div class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="checkbox2" @click="advancedFeatures=!advancedFeatures">
						<label class="custom-control-label" for="checkbox2">Advanced Features are turned {{advancedFeatures ? 'On' : 'Off'}}</label>
					</div>

					<div class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="checkbox3" @click="allowReassignLocalTracks=!allowReassignLocalTracks">
						<label class="custom-control-label" for="checkbox3">Track Reassignment is {{allowReassignLocalTracks ? 'On' : 'Off'}}</label>
					</div>
					<div v-if="isAdmin" class="custom-control custom-switch">
						<input type="checkbox" class="custom-control-input" id="showClusteredDetections" @click="toggleClusteredDetections=!toggleClusteredDetections">
						<label class="custom-control-label" for="showClusteredDetections">Clustered Local Tracks are {{toggleClusteredDetections ? 'Shown' : 'Hidden'}}</label>
					</div>
				</template>
				<button v-if="this.taskUuid!==''" class="btn btn-outline-success" @click="finishTask()" style="width:100%;margin-top:100px;">
					Finish Task  <i class="icon fas fa-check"></i>
				</button>
			</div>
		</div>
	</div>

	<div style="display:flex; flex-direction: coloumn; width: 75%;height:100%; margin-left: 12%;">
		
		<div v-if="globalMergedTracks" style="display:flex; flex-direction: column; overflow-y:scroll;height:80vh;">
			<div v-if="selectedLocalTracks.length>0" class="card border-secondary mb-3" style="width: fit-content;margin-bottom:100px;margin-top:100px;">
				<div  style="display: flex; flex-direction: row;" class="local-track-list">
					<div v-for="(localTrackUuid,index) in selectedLocalTracks" :key="index" class="local-track">
						<div class="card border-secondary mb-3" style="width: fit-content;" :aria-hidden="true">
							<div v-for="(detection,index2) in localTrackMap[localTrackUuid]" :key="index2" class="frame-item" :style="setTrackStyleByDetection(detection)" >
								<!-- {{localSetClipCss(subFramesMap.get(detection), sizeMap[detection].width, sizeMap[detection].height)}} -->
								<div style="padding: 20px" v-if="sizeMap[detection]!==undefined">
									

									<img v-onload="framePathsMap.get(detection)" :src="framePathsMap.get(detection)" :style="localSetClipCss(subFramesMap.get(detection), sizeMap[detection].width, sizeMap[detection].height)" @mouseover="setCurrentSubFrame(subFramesMap.get(detection));currentFramePath = framePathsMap.get(detection)">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="(loadMergeThumbnails === false)" class="local-track-list">
				<div v-for="(localTrack) in Object.keys(localTrackMap).map(lt=>{return{localTrackUuid: lt}})" :key="localTrack.localTrackUuid" :class="displayColumns" class="local-track">
					<hr/>

					<b-overlay :show="(pipeLineProgress['frames'] !== true)" variant="transparent" blur= "5px" rounded="sm" >
						<div class="card border-secondary mb-3" style="width: fit-content;" :aria-hidden="true" @click="handleLocalTrackClick(localTrack)"
						>
							<template v-if="!removalList.map(r=>r.localTrackUuid).includes(localTrack.localTrackUuid)">
								<!-- Local track actions -->
								<div class="card-header" :style="`${selectedLocalTracks.includes(localTrack.localTrackUuid) ? 'background-color: red;' : ''}`">
									<pre class="foo" v-if="advancedFeatures">Local Track: {{localTrack.localTrackUuid}}</pre>

									<!-- Simplified UI for supahands -->
									<template v-if="(!batchSelect) && (organisation === 'supahands')">
										<button style="float:left" class="btn btn-outline-warning" @click="removeLocalTrackFromMergeTrack(localTrack, 'error')">
											<i class="fas fa-user-times"></i>
										</button>
									</template>

									
									<template v-else>
										
										<!-- Disabling track swap for supahands temporarily as theft pipeline data should not have track swaps removed -->
										<button
											v-if="localTrackMap[localTrack.localTrackUuid].length>1 && (!batchSelect) && (organisation!=='supahands')"
											style="float:left;"
											@click="removeLocalTrackFromMergeTrack(localTrack, 'trackSwap')"
											class="btn btn-outline-warning">
											<i class="fas fa-user-friends"></i>
										</button>
										<!-- Adding trash action for review agents to handle reid agents not properly trashing images -->
										<button
											v-if="(!batchSelect) && mergeDataSource ==='acceptedMerges'"
											style="float:left;"
											@click="moveLocalTrackToTrash(localTrack)"
											:id="`add-to-trash-${localTrack.localTrackUuid}`"
											class="btn btn-outline-warning">
											<i class="fas fa-trash"></i>
											<b-tooltip :target="`add-to-trash-${localTrack.localTrackUuid}`" triggers="hover">
												Move image into the trash folder (only for images of non humans)
											</b-tooltip>
										</button>
										
										<template v-if="!batchSelect && dataBuffer[currentMergeUuid].localTracks.length>=1">
											<button 
												v-if="mergeDataSource==='acceptedMerges'" 
												:id="`remove-from-merge-track-${localTrack.localTrackUuid}`"
												style="float:left;" 
												@click="removeLocalTrackFromMergeTrack(localTrack, 'error')" 
												class="btn btn-outline-warning"
											>
												<i class="fas fa-user-times"></i>
												<b-tooltip :target="`remove-from-merge-track-${localTrack.localTrackUuid}`" triggers="hover">
													Remove image from merge track
												</b-tooltip>
											</button>
											<button 
												v-if="mergeDataSource==='garbage'"
												:id="`remove-from-trash-${localTrack.localTrackUuid}`" 
												style="float:left;" @click="removeLocalTrackFromTrash(localTrack)" 
												class="btn btn-outline-success"
											>
												<i class="fas fa-user-plus" v-b-tooltip.hover="'Move image out of trash folder'"></i>
												<b-tooltip :target="`remove-from-trash-${localTrack.localTrackUuid}`" triggers="hover">
													Move image out of trash folder
												</b-tooltip>
											</button>
										</template>
										<button 
											v-if="allowReassignLocalTracks" 
											style="float:left" 
											v-b-modal.modal-reassign
											class="btn btn-outline-warning"
											@mousedown="activeLocalTrack=localTrack"
										>
											<i class="fas fa-user-edit"></i>
										</button>
										<!-- Shows only trash icon when merge is only of length one to avoid agents from destroying mergeUuids as a result of removing a single remaining local track -->
										
										
									</template>
								</div>

								<!-- Local track image -->
								<div class="card-body">
									<div v-for="(detection,index2) in localTrackMap[localTrack.localTrackUuid]" :key="index2" :class="`frame-item ${(currentLocalTrackExpended===localTrack.localTrackUuid)?'expanded':''}`" :style="setTrackStyleByDetection(detection, localTrack.localTrackUuid)">
										<div :style="imageCardSize.css" class="frame-item" @contextmenu.prevent="$refs.menu.open">
											<img
												v-if="sizeMap[detection]!==undefined"
												@click="detectionSelected(localTrack.localTrackUuid, detection)"
												@mouseover="setCurrentSubFrame(subFramesMap.get(detection));currentFramePath = framePathsMap.get(detection);currentDetectionUuid = detection;"
												:src="framePathsMap.get(detection)"
												:style="localSetClipCss(subFramesMap.get(detection), sizeMap[detection].width, sizeMap[detection].height)"
											>
										</div>
									</div>
								</div>
							</template>
						</div>
					</b-overlay>

				</div>
				<vue-context ref="menu">
					<li>
						<a href="#" @click="showFullFrame()">View Full Frame</a>
					</li>
				</vue-context>
			</div>
		</div>
		

	</div>


	<ReassignmentModal id="modal-reassign"
		:localTrack="activeLocalTrack"
		:localTrackDetections="localTrackMap[(activeLocalTrack || {}).localTrackUuid]"
		:subFrames="Object.fromEntries(subFramesMap)"
		:framePaths="Object.fromEntries(framePathsMap)"
		:databaseName="db"
		:datasetName="currentLocalTrackList"
		@reassign="UpdateAfterReassignment"
	/>
</div>
