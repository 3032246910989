<template>
<div class="merge-track-editor">

	<b-modal
		:id="`${mergeUuid}-fullframe-modal`"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		hide-footer
	>	
		<FullFrameDisplay 
			style="height: 1000px; width: 500px;"
			:imageScale="2.7"	
			:framePath="currentFullFrameImage"
			:size="fullFramePathSizeMap[currentDetectionUuid]"
			:subFrame="currentFullFrameSubFrame"
		/>
	</b-modal>

	<div v-if="!minimal" id="data-entry-row" class="merge-selector">
		<h6>Merge Track Editor</h6>
		<div style="display: flex; flex-direction: row;">
			<b-input v-model="selectedMergeUuid" lazy placeholder="Enter merge UUID or select from above..."/>
		</div>
	</div>
	
	<div v-if="globalMergedTracks" class="overflow">
		<hr/>
		<div v-if="!minimal" class="information">
			<pre v-if="!selectedMergeUuid">Please input a merge UUID to edit.</pre>
			<pre v-else-if="framePathsMap">Merge UUID: {{currentMergeUuid}}</pre>
			<pre v-if="!framePathsMap">Loading merge: {{currentMergeUuid}}...</pre>
		</div>

		<b-overlay :show="(pipeLineProgress['frames'] !== true)" variant="transparent" blur="5px">
			<div class="local-track-list">
				<b-card
					v-for="(localTrack, index) in localTracks.slice(0, sampleSlice)"
					:key="index"
					class="local-track"
				>
					<!-- Detection images -->
					
					<div
						v-for="detectionUUID in localTrackMap[localTrack.localTrackUuid]"
						:key="detectionUUID"
						class="frame-item"
						@mousedown="currentDetectionUuid = detectionUUID"
						@mouseover="imageHover(detectionUUID)"
						:style="imageCardSize.css"
						@contextmenu.prevent="$refs.menu.open"
					>	
						<img v-if="sizeMap[detectionUUID]!==undefined" 
							:src="framePathsMap.get(detectionUUID)"
							
							:style="localSetClipCss(subFramesMap.get(detectionUUID), sizeMap[detectionUUID].width, sizeMap[detectionUUID].height)">
					</div>

					<!-- Removal button -->
					<b-button
						v-if="mode==='read/write'"
						size="sm"
						variant="warning"
						class="block-centered remove-button"
						v-b-tooltip.hover="'Remove from Merge'"
						@click="removeLocalTrackFromMergeTrack(localTrack, 'error')"
					>
						<i class="fas fa-times"></i>
					</b-button>
				</b-card>
			</div>
		</b-overlay>
	</div>
	<vue-context ref="menu">
		<li>
			<a href="#" @click="showFullFrame()">View Full Frame</a>
		</li>
	</vue-context>
</div>
</template>


<script>

import FullFrameDisplay from './tools/FullFrameDisplay.vue';
import VueContext from 'vue-context';

import { dataAPI, mlserver } from "../http-common";
import {setClipCss, getImageDimensions, createImageSizeMap} from '../utils/images.js';
import { BButton, BCard, BFormInput as BInput, BOverlay, VBTooltip } from 'bootstrap-vue'

export default {
	name: 'MergeTrackEditor',

	components: {
		BButton,
		BCard,
		BInput,
		BOverlay,
		VueContext,
		FullFrameDisplay
	},

	directives: {
		'b-tooltip': VBTooltip
	},

	props: {
		db: {type: String, default: ''},
		size: {type: String, default: 'xs'},
		mergeUuid: {type: String, default: ''},
		inputTable: {type: String, default: ''},
		currPage: {default: ''},
		format: {type: String, default: 'jpeg'},
		minimal: {type: Boolean, default: false},
		mode: {type: String, default: 'read/write'},
		sampleAmount: {type: Number, default: 0},
		portalDetails: {type: Object, default: () => {}}
	},

	data: function() {
		return {
			fixedCropWidth: 75,
			pipeLineProgress: {},
			isLoading: false,
			framePathsMap: null,
			recentlyMerged: [],
			globalMergedTracks: null,
			currentMergeTrack: 0,
			localTracks: [],
			workingLocalTracks: [], // currently unused but keeps track of removed tracks
			detectionData: [],
			localTrackMap: {},
			dataBuffer: {},
			selectedMergeUuid: this.mergeUuid,
			frameDistanceMatrix: [],
			segmentedImages: [],
			threshold: .9,
			experimentalFeatures: false,
			toggleClusteredDetections: true,
			sizeMap: {},
			currentDetectionUuid: '',
			fullFramePathSizeMap: {},
			currentFullFrameSubFrame: {},
			currentFullFrameImage: null,
		}
	},
	computed: {
		currentMergeUuid: function(){return this.globalMergedTracks?.[this.currentMergeTrack]},

		isAdmin(){
			return (localStorage.getItem('is_admin') === 'true');
		},
		sampleSlice(){
			return (this.sampleAmount <= 0) ? this.localTracks.length : this.sampleAmount;
		},
		imageCardSize(){
			
			if(this.size === 'xl'){
				const width = 120;
				return {
					fixedCropWidth: width,
					css: `width: ${width}px; height: ${width*1.7}px;`
				};
			}
			else{
				const width = 75;
				return {
					fixedCropWidth: 75,
					css: `width: ${width}px;`
				};
			}
		},
		hasPreCroppedFrames(){
			return this.dataBuffer[this.currentMergeUuid].hasPreCroppedFrames;
		}
	},

	watch: {
		currentMergeTrack: function() {
			this.getMergeUuidData(this.currentMergeUuid);
		},
		selectedMergeUuid: function(newVal){
			this.loadMergeUuid(newVal);
			this.updateCurrentMergeTrack();
		},
		toggleClusteredDetections: function(){
			this.getMergeUuidData(this.currentMergeUuid);
		}
	},

	mounted: function() {
		this.loadMergeUuid(this.mergeUuid);
	},

	methods: {
		mergeUuidUpdatePipeline: async function (mergeUuid){
			// data loading pipeline

			let {data, updateMerge, mergeUuidUpdated} = (await this.getLocalTracksForMergeUuid(mergeUuid));// Run query to get local tracks for all

			// handles non clustered datasets and allows toggling hidden detections
			let localTracks;
			const isReducedDataset = (data[0]['isClusterDetection']!==null);

			if(!isReducedDataset){

				localTracks = data;
			}
			else{
				localTracks = (this.toggleClusteredDetections) ? data.filter(r=>r.isClusterDetection === 'true') : data;
			}

			if(updateMerge){
				const oldMergeUuid = mergeUuid;
				mergeUuid = mergeUuidUpdated;
				this.globalMergedTracks[0]=mergeUuidUpdated;
				this.selectedMergeUuid = mergeUuidUpdated;
				this.mergeUuid = mergeUuidUpdated;
				this.mergeUuidUpdateEvent(oldMergeUuid, mergeUuidUpdated)
			}

			this.pipeLineProgress['localtracks'] = true;
			let detectionData = (await this.getDetectionsForMergeUuid(mergeUuid)).data


			// handles non clustered datasets and allows toggling hidden detections

			if(!isReducedDataset){
				detectionData = detectionData;
			}
			else{
				detectionData = (this.toggleClusteredDetections) ? detectionData.filter(r=>r.isClusterDetection === 'true') : detectionData;
			}
			// get detections for specific mergeUuid

			this.pipeLineProgress['detections'] = true;

			const detectionArray1 = detectionData.map(point => point.detectionUuid);
			const detectionArray2 = detectionData.map(point => {return {detectionUuid: point.detectionUuid, framePath: point.framePath, framePathCropped: point?.framePathCropped}});

			const detectionArrayPromises = await Promise.all([this.retrieveDetectionSubFramesForMerge(detectionArray1), this.retrieveFullFramesForMergeUuid(detectionArray2)])

			this.pipeLineProgress['subframes'] = true;
			const localTrackMap = this.createLocalTrackMap(detectionData);
			const subFramesMap = this.createSubFramesMap(detectionArrayPromises[0].data);
			const framePathsMap = this.createFramePathMap(detectionArrayPromises[1]);

			this.sizeMap = {...this.sizeMap, ...(await createImageSizeMap(framePathsMap))};

			this.pipeLineProgress['frames'] = true;
			
			const hasPreCroppedFrames = Object.keys(detectionData[0]).includes('framePathCropped');

			const dataForMergeUuid = { // All the data that this function stores and needs to use
				mergeUuid,
				localTracks,
				detectionData,
				localTrackMap,
				subFramesMap,
				framePathsMap,
				detectionArray2,
				hasPreCroppedFrames
			}
			
			this.isLoading=false;
			return dataForMergeUuid // this doesnt work as it always returns at the end
		},
		getMergeUuidData: async function(mergeUuid){
			let dataLoad = await this.mergeUuidUpdatePipeline(mergeUuid) // load data
			if (dataLoad.mergeUuid !== mergeUuid) {mergeUuid = dataLoad.mergeUuid}
			// allocate data to local variables
			this.localTracks = dataLoad.localTracks
			this.detectionData = dataLoad.detectionData
			this.localTrackMap = dataLoad.localTrackMap
			this.subFramesMap = dataLoad.subFramesMap
			this.framePathsMap = dataLoad.framePathsMap
			this.frameDistanceMatrix = dataLoad.frameDistanceMatrix
			this.segmentedImages = dataLoad.segmentedImages

			if (this.experimentalFeatures) {
				const testVar = await this.dataLoadPipelineForLocalTrackAllocation();
			}
			// technically don't need a check as the data will eventually reach the database as they are chained promises.
			if (dataLoad){
				// Store the data in the dataBuffer
				this.dataBuffer[mergeUuid] = dataLoad;
			} else {
				console.log('Data not writen as mergeUuidUpdatePipeline function did not return data')
			}

			this.$emit('dataLoaded', {detections: dataLoad.detectionData, mergeUuid: mergeUuid})

			// makes sure when merge is changed in parent component, if using consolidation tool, full screen preview changes accordingly to match new merge
			this.imageHover(dataLoad.detectionData[0].detectionUuid);

		},
		loadMergeUuid: function(mergeUuid){
			if (!mergeUuid)
			{
				console.log('In MergeTrackEditor: No merge UUID selected, not loading.');
				return;
			}

			this.getMergeUuidData(mergeUuid);
			this.globalMergedTracks = [mergeUuid];
		},
		getLocalTracksForMergeUuid: function(mergeUuid){
			return dataAPI.post(`/${this.db}/getMergeTrackInfo`, {mergeUuid: mergeUuid})
				.then(response => {

					if(mergeUuid !== response.data.mergeUuid){
						// mergeUuid has been updated, now update the components mergeUuid reference to the new 'head' mergeUuid
						return {data: response.data.mergeInfo, updateMerge: true, mergeUuidUpdated: response.data.mergeUuid}
					}
					else{
						return {data: response.data.mergeInfo, updateMerge: false, mergeUuidUpdated: mergeUuid}
					}
				}).catch(error=>{
					this.$noty.error(`Unable to fetch local tracks for merge: ${mergeUuid} :${error}`)
				})
		},
		async retrieveFramePaths(detections, cropped, datasetName, withSubframes=false){
			try{
				const portalDetails = this.portalDetails??{};
				const { data } = await dataAPI.post(`${this.db}/frames/frameDetails`, { detections, cropped, datasetName, withSubframes, portalDetails });
				return data;
			}
			catch(error){
				console.error(error);
				this.$noty.error("Failed to retrieve image urls");
			}
		},
		async retrieveFullFramesForMergeUuid(detections) {
			const hasPreCroppedFrames = detections[0].framePathCropped !== undefined;
			const framePaths = await this.retrieveFramePaths(detections, hasPreCroppedFrames, this.inputTable);
			return framePaths;
		},
		getDetectionsForMergeUuid: function(mergeUuid) {
			return dataAPI
				.post(`/${this.db}/detections/mt-uuid/${mergeUuid}`, { datasetName: this.inputTable })
				.then((response)=>{
					return response})
				.catch(e => {
					console.log(e)});
		},
		retrieveDetectionSubFramesForMerge: function(detectionUUIDs) {
			return dataAPI
				.post(`/${this.db}/detections/subframes/`, { detections: detectionUUIDs, reidInput: 'reidInput_'+this.inputTable})
				.catch(e => {
					console.log(e);
				})
		},
		removeLocalTrackFromMergeTrack: function(localTrack, type){
			this.workingLocalTracks.push(localTrack); // add removed local track to working variable
			this.localTracks = this.localTracks.filter(obj => obj.localTrackUuid !== localTrack.localTrackUuid);

			console.log(`Removing localtrack with id: ${localTrack.id} from merge`)
			const body = {
				fromComponent: 'editor'
			}
			dataAPI
				.post(`/${this.db}/review/removeLocalTrack/id/${localTrack.id}`, body) // update to id: localTrack.id
				.then(response => {
					// refresh gallery in parent gallery
					if(response.data.status === "errorRemovedFromMergeFromEditor"){
						this.$emit('retrieveTrackEndPoints', parseInt(this.currPage));
					}
				})
				.catch(error => {
					console.error(error);
					this.$noty.error(`Failed to remove from local track ${error}`)
				});
		},
		updateCurrentMergeTrack: function(){
			this.$emit('updateCurrentMergeTrack', this.globalMergedTracks[0]);
		},
		mergeUuidUpdateEvent: function(oldMergeUuid, newMergeUuid){
			// tell the parent component to update their old merge uuid reference
			this.$emit('mergeUuidUpdateEvent',oldMergeUuid ,newMergeUuid)
		},

		/* Helper functions */
		// TODO: change to use vuex
		createFramePathMap: function(rawData) {
			let _framePathsMap = new Map();

			rawData.forEach(path => _framePathsMap.set(path.detectionUuid, path.frame));
			if (this.recentlyMerged !== null){
				this.recentlyMerged.forEach(entry => {
					_framePathsMap.set(entry.firstDetectionUuid, entry.thumbNail);
				});
			}
			return _framePathsMap;
		},
		createSubFramesMap: function(rawData) {
			let _subFramesMap = new Map();
			rawData
				.filter(subFrame => subFrame.label === 'person' && subFrame.textureLabel === 'rgb')
				.forEach(subFrame => _subFramesMap.set(subFrame.detectionUuid, {
					top: subFrame.minimumY,
					right: subFrame.maximumX,
					bottom: subFrame.maximumY,
					left: subFrame.minimumX
				}));
			return _subFramesMap;
		},
		createLocalTrackMap: function(detections){
			let localTrackMap = {}

			detections.forEach(detection => { //create detections association
				let localTrack = detection.localTrackUuid;
				let detectionUuid = detection.detectionUuid;
				if (!(localTrack in localTrackMap)) { // if local track no in object
					localTrackMap[localTrack] = [detectionUuid]; // if not in object create new object key and value pair
				} else {
					localTrackMap[localTrack].push(detectionUuid); // if in object add to list at the end
				}
			});

			let localTrackIndexes = Object.keys(localTrackMap); // get keys

			// only select up to 5 frames for each localTrack
			localTrackIndexes.forEach((localTrack, index) => {
				let arrayOfDetectionUuids = localTrackMap[localTrack];
				let length = arrayOfDetectionUuids.length;
				let numberOfFrames = 1;
				let numberOfBlocks = Math.ceil(length/numberOfFrames);
				let newArray = [];
				for(let i = 0; i<length; i+=numberOfBlocks) {
					newArray.push(arrayOfDetectionUuids[i]);
				}
				localTrackMap[localTrack] = newArray; // assign new Array to localTrackMap
			});
			return localTrackMap
		},

		localSetClipCss: function(coords, width, height) {
			return setClipCss(coords, width, height, this.imageCardSize.fixedCropWidth, this.hasPreCroppedFrames);
		},  

		getDistancesFromML: async function(detectionArray){
			// array of framePaths
			const framePaths = await this.retrieveFullFramesForMergeUuid(detectionArray); // get image paths for array of detections

			const framePathsMap = await this.createFramePathMap(framePaths.data); // assign to framePathsMap;

			// array of subFrame data
			const subFrames = await this.retrieveDetectionSubFramesForMerge(detectionArray); // get subFrames

			const subFramesMap = await this.createSubFramesMap(subFrames.data);

			const data = [];

			detectionArray.forEach((uuid)=> {
				data.push({
					detectionUuid: uuid,
					framePath: framePathsMap.get(uuid),
					subFrame: subFramesMap.get(uuid)
				})
			})

			const distances = await mlserver
				.post(`http://127.0.0.1:5000/getScore`, data)
				.then(response => {
					return response.data
				})
				.catch(e => {
					console.log(e);
				});

			return distances
		},

		segmentImagesBasedOnDistance(frameDistanceMatrix){
			const segmentedImages = [[0]];

			frameDistanceMatrix.forEach((singleImageComparisonVector, index)=>{ // take each image vector at a time and determine which image is best match
				if (index === 0){ // first image was already allocated above
				} else {
					const distancesForEachBucket = [];
					segmentedImages.forEach((bucket, bucketIndex)=>{ // Loop over each bucket of images and output distance to array
						if (typeof(bucket) === "number"){ // handle case where only one example in the list
							distancesForEachBucket.push(singleImageComparisonVector[bucket]);
						} else { // multiple examples in the list
							distancesForEachBucket.push(singleImageComparisonVector[bucket[0]]); // we use first image that has been allocated to the bucket as the representative image to compare to
						}
					})
					// check which score is minimum ** has to ignore its own value, as this will be 0.
					let minimumValueInArray = Math.min.apply(null, distancesForEachBucket.filter(Boolean)); // filter for zero as this distance refers to image comparison to itself.

					// check if distance is below a certain threshold
					if (minimumValueInArray < this.threshold){ // if below threshold, allocate to that bucket
						const bucketAssignmentIndex = distancesForEachBucket.findIndex(arr => arr === minimumValueInArray); // get index of image that has minimum score
						segmentedImages[bucketAssignmentIndex].push(index);
					} else {
						segmentedImages.push([index]); // if above threshold, allocate to new bucket
					}
				}
			})

			return segmentedImages
		},
		imageHover(detectionUUID){
			const point =  {
				detectionUuid: detectionUUID,
				framePath: this.framePathsMap.get(detectionUUID),
				subFrame: this.subFramesMap.get(detectionUUID),
				dimensions: this.sizeMap[detectionUUID],
			};

			this.$emit('imageHover', point);
		},
		async showFullFrame(){

			const { fullFramePath, sizeMap, subFrame } = await this.retrieveFullFrameDetails(this.currentDetectionUuid);

			this.currentFullFrameImage = fullFramePath;
			this.fullFramePathSizeMap = sizeMap;

			this.currentFullFrameSubFrame = {
				...subFrame
			}

			
			this.$root.$emit('bv::show::modal', `${this.mergeUuid}-fullframe-modal`);
		},
		async retrieveFullFrameDetails(detectionUuid){
			const framePathMap = new Map();
			const frameWithSubframes = await this.retrieveFramePaths([ {detectionUuid} ], false, this.inputTable, true);

			const [{ minimumY: top, maximumX: right, maximumY: bottom, minimumX: left }] = frameWithSubframes;
		
			frameWithSubframes.forEach(detection => framePathMap.set(detection.detectionUuid, detection.frame));

			const fullFramePath = framePathMap.get(detectionUuid);

			const sizeMap = await createImageSizeMap(framePathMap, true);
	
			return {
				fullFramePath, 
				sizeMap,
				subFrame: {
					top, right, bottom, left
				}
			}
		}
	}
}
</script>


<style scoped>
.merge-track-editor {
	width: 100%;
	/* padding: 1.25rem; */
	padding: 1.25rem 0rem 1.25rem 1.25rem;

	/* margin-left:16px; */

	overflow-y: auto;
	border-radius: 5px;
	border: 1px solid #A9BDBD;
}

.merge-selector {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
}

.local-track-list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	min-height: 100px;
}

.local-track-list .local-track {
	box-sizing: border-box;
	/* background: #e0ddd5;
	color: #171e42; */
	/* padding: 10px; */
}

.local-track .card-body {
	padding: 0;
}

.local-track .frame-item {
	float: left;
}

.local-track-list pre {
	float: left;
	font-size: 12px;
}

.remove-button {
	z-index: 9999;
	visibility: hidden;
}
.local-track:hover .remove-button {
	visibility: visible;
}
</style>
