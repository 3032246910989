<template>
<div id="body">
	<section>
		<div class="row" style="display:flex; flex-direction: row;">
			<!-- SIDE BAR SECTION -->
			<SideBar
				:icons="iconsForUser"
				@setCurrentSection="setCurrentSection"
			>
			</SideBar>


			<div style="height:100%;">
				<!-- TASK SECTION -->
				<!-- temp change to only allow admins to view datasets-->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: column; height: 100%;" v-if="(currentSection === 'tasks') && (!isNew)">
					<div class="card mb-3" style="height:100%;width:fit-content;display: flex; flex-direction: row; background-color: transparent;border:0px transparent">
						<!-- <h3 class="card-header">Tasks</h3> -->
						<div class="card-body" @click="activeTasks = true; toggleMyTasks=false;" :style="`border-bottom: 3px solid ${(activeTasks) ? '#007fff' : '#001014' };  height:60px; padding:12px;`">
							<h5 class="card-title" style="font-size: 15px;">Active Labelling Projects</h5>
							<h6 v-if="reidincompleteReidTasks" class="card-subtitle text-muted" style="font-size:12px;" >
								<RealTimeValue :value="reidincompleteReidTasks.length+' Datasets In Queue'"/>
							</h6>
							<h6 v-else class="card-subtitle text-muted" style="font-size:12px;">
								Loading tasks...
							</h6>
						</div>
						<div class="card-body" @click="activeTasks = false; toggleMyTasks=true;" :style="`border-bottom: 3px solid ${(toggleMyTasks) ? '#007fff' : '#001014' }; height:60px; padding: 12px;`">
							<h5 class="card-title" style="font-size: 15px;">My Tasks <span class = "badge badge-success" style = "color: red"></span></h5>
							<h6 v-if="myReidTasks" class="card-subtitle text-muted" style="font-size:12px;">
								<RealTimeValue :value="aggregatedTasks.length+' Assigned Tasks'"/>
							</h6>
							<h6 v-else class="card-subtitle text-muted" style="font-size:12px;">
								Loading tasks...
							</h6>
						</div>
					</div>

					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="activeTasks === true">
						<!-- {{reidincompleteReidTasks.length}} -->
						<div class="card mb-3" style="height:100%; width:fit-content; background-color: transparent; border: 0px solid transparent;">
							<div style="display:flex; flex-direction: column">
								<div style="display: flex; flex-direction: row;width:fit-content">
									<div v-if="['all_tasks', 'reid', 'admin', 'consolidation', 'review'].includes(accountType)" class="card-body" :style="`border-bottom: 3px solid ${(currentLabellingSection==='ReID') ? '#007fff' : '#001014' }`" @click="currentLabellingSection = 'ReID'">
										<h5  class="card-title" >ReID</h5>
										<h6 v-if="reidincompleteReidTasks" class="card-subtitle text-muted">
											{{ reidincompleteReidTasks.length }} Datasets
										</h6>
										<h6 v-else class="card-subtitle text-muted">
											Loading tasks...
										</h6>
									</div>
								</div>

								<div v-if="currentLabellingSection==='ReID'" class="dataset-list">
									<div class="dataset-card"  v-for="project in reidincompleteReidTasks" v-bind:key="project['tableName']" >
										<div class="card-body no-hover">
											ReID Dataset
											{{taskTypeMenu[project.tableName]}}
											<div style="display:flex;flex-direction:column;float:right">


												<button
													v-if="accountType!=='all_tasks'"
													type="button"
													class="btn btn-outline-info"
													style="border: 2px solid #007fff;"
													@click="startLabelling(accountType, project.tableName, 'reid', project.siteDB);"
												>

													Start Labelling

												</button>
												<div v-else>
													<!-- Only  admins and 'all_tasks' agents are allowed to select task type on task request-->
													<b-dropdown text="Start Labelling" variant="outline-info">
														<div v-for="taskType in ['ReID', 'Consolidation', 'Review']" v-bind:key="taskType"
															style="margin-left:10px;width: 88%;padding:10px;border-bottom:1px solid #001014;">
															<div class="dropdown-button"
																@click="startLabelling(taskType, project.tableName, 'reid', project.siteDB)"
															>
																{{taskType}}

															</div>
														</div>

													</b-dropdown>
												</div>

											</div>
											<h5 v-if="project['datasetType']!=='reid-benchmark'" class="card-title" >Standard: {{project['tableName']}} </h5>
											<h5 v-else>Benchmark: {{project['tableName']}}</h5>
											
											<div style="display:flex; flex-direction: row;">
												<div class="card mb-3" style="background-color: transparent; border-top: 1px solid #007fff;border-radius:0px;width: fit-content;height:130px;padding:10px;border-bottom:none;border-left:none;border-right:none">
													<h5 style="color:white;font-size:1em;">Local Track Assignment</h5>
													<RealTimeValue :value="project.localTracksRemaining">
														Images Remaining:
													</RealTimeValue>
													<RealTimeValue :value="`${(project.completionPercentage*100).toFixed(2)}%`">
														Progress:
													</RealTimeValue>
												</div>
												<div class="card mb-3" style="background-color: transparent;border-top: 1px solid #007fff;border-radius:0px ;width: fit-content;height:130px;padding:10px;border-bottom:none;border-left:none;border-right:none">
													<h5 style="color:white;font-size:1em;">Consolidation</h5>

													<RealTimeValue :value="project.consolidationProgress && project.consolidationProgress.mergesRequiringConsolidationFromReID">
														Merges Requiring Consolidation From ReID:
													</RealTimeValue>

													<RealTimeValue :value="project.consolidationProgress && project.consolidationProgress.mergesRequiringConsolidationFromReview">
														Merges Requiring Consolidation From Review:
													</RealTimeValue>

												</div>
												<div class="card mb-3" style="background-color: transparent;border-top: 1px solid #007fff;border-radius:0px;width: fit-content;height:130px;padding:10px;border-bottom:none;border-left:none;border-right:none">
													<h5 style="color:white;font-size:1em;">Review</h5>

													<RealTimeValue :value="project.unreviewed">
														Unreviewed Tracks:
													</RealTimeValue>
													<RealTimeValue :value="project.reviewed">
														Reviewed Tracks:
													</RealTimeValue>
													<RealTimeValue :value="`${(project.reviewProgress*100).toFixed(2)}%`">
														Progress:
													</RealTimeValue>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					<div style="display: flex; flex-direction:row;" v-if="toggleMyTasks === true">
						<div style="display:flex; margin-left:0.01rem;flex-direction: column; height: 100%;">
							<div style="display:flex;flex-direction:row;margin-bottom:10px;">

								<div class="card-body" :style="`border-bottom: 3px solid ${(currentTaskView==='active') ? '#007fff' : '#001014' }`" @click="currentTaskView = 'active'">
									<h5 class="card-title" >Active Tasks</h5>

									<h6 v-if="aggregatedTasks" class="card-subtitle text-muted">
										{{ aggregatedTasks.length }} Tasks
									</h6>

									<h6 v-else class="card-subtitle text-muted">
										Loading tasks...
									</h6>
								</div>

								<div class="card-body" :style="`border-bottom: 3px solid ${(currentTaskView==='past') ? '#007fff' : '#001014' }`" @click="currentTaskView = 'past'">
									<h5  class="card-title" >Historical Tasks</h5>
									<h6 v-if="pastTasks" class="card-subtitle text-muted">
										{{ pastTasks.length }} Tasks
									</h6>
									<h6 v-else class="card-subtitle text-muted">
										Loading tasks...
									</h6>
								</div>
							</div>
							<div style="overflow-y: auto; max-height:900px;">
								<div v-for="task in tasksToDisplay" class="card mb-3 task-row" style="display: flex; flex-direction: row;" v-bind:key="task.taskUuid">

									<div class="row-item" style="width: 300px;">
										<div class="row-cell-header" style="">Task ID</div>
										<div class="row-cell-item" style=""><p>{{task.taskUuid}}</p></div>
									</div>

									<div class="row-item" style=" max-width: 250px; width: fit-content;min-width: 250px;overflow-wrap: break-word;">
										<div class="row-cell-header" style="">Dataset Name</div>
										<div class="row-cell-item" style=""><p>{{task.tableName}}</p></div>
									</div>

									<div class="row-item" style=" max-width: 125px; width: fit-content;min-width: 125px;overflow-wrap: break-word;">
										<div class="row-cell-header">Type</div>
										<div class="row-cell-item"><p>{{camelToTitleCase(task.taskType)}}</p></div>
									</div>

									<div class="row-item">
										<div class="row-cell-header">Time Created</div>
										<div class="row-cell-item"><p>{{formatDuration(calculateDuration(Date.now(), task.taskCreatedAt))}}</p></div>
									</div>

									<template v-if="currentTaskView==='active'">
										<button
											type="button"
											class="btn btn-outline-info"
											style="border: transparent; float: right; height:35px;padding-top:4px;margin-top:5px;margin-right:-5px;"
											@click="openTask(task, task.taskType)"
										>
											<i class="fas fa-external-link-square-alt fa-1x">
											</i>
										</button>

										<button
											type="button"
											v-if="isAdmin"
											class="btn btn-outline-info"
											style="border: transparent;float: right; height:35px;padding-top:4px;margin-top:5px;margin-right:-5px;"
											@click="finishTask(task.taskUuid, task.taskType)"
										>
											<i class="fas fa-check fa-1x" style="color: #93E9BE;">
											</i>
										</button>
									</template>
									
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- ADMIN SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="currentSection === 'manager'">
					<div class="card mb-3" style="height:100%; background-color: #001014;" id="sidebar">
						<!-- <h3 class="card-header">Admin</h3> -->

						<div class="card-body" :style="`border-left: 3px solid ${(taskView) ? '#007fff' : '#002B36' }`" @click="taskView = true;agentView=false;statView=false;reAssignmentView=false;">
							<h5 class="card-title">Tasks</h5>
							<h6 class="card-subtitle text-muted">{{tasksInProgress.length}} task(s) in progress</h6>
							<br>
							<h6 class="card-subtitle text-muted">{{tasksCompleted.length}} task(s) completed</h6>
						</div>
						<div class="card-body" @click="taskView = false;agentView=true;statView=false;reAssignmentView=false;">

							<h5 class="card-title" >Agents</h5>
							<h6 v-if="agents" class="card-subtitle text-muted">{{agents.filter(agent=> agent.isLoggedIn).length}} agents online</h6>
							<br>
							<h6 v-if="agents" class="card-subtitle text-muted">{{agents.length}} agents total</h6>
							<h6 v-else class="card-subtitle text-muted">Loading agent info...</h6>

						</div>

						<div class="card-body" @click="taskView = false;agentView=false;statView=true;reAssignmentView=false;">

							<h5 class="card-title" >Agent Metrics</h5>
							<h6 v-if="(statsPerTask.length > 1) || (statsPerTask.length === 0)" class="card-subtitle text-muted">{{statsPerTask.length}} available stats</h6>
							<h6 v-if="statsPerTask.length===1" class="card-subtitle text-muted">{{statsPerTask.length}} available stat</h6>

						</div>

						

						<div class="card-body" @click="reAssignmentView=true;taskView = false;agentView=false;statView=false;">

							<h5 class="card-title" >Task Re Assignment</h5>
							<h6 class="card-subtitle text-muted">{{tasksInProgress.length}} task(s) reassignable</h6>

						</div>
					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="taskView === true">
						<div class="card mb-3" style="height:100%;background-color: transparent;">

							<div class="card-body">

								<b-tabs content-class="mt-3">
									<b-tab title="Tasks" :class="{active: taskTabSection === 'Tasks'}" @click="taskTabSection='Tasks'"/>
									<b-tab title="Audit" :class="{active: taskTabSection === 'Audit'}" @click="taskTabSection='Audit'"/>
								</b-tabs>

								<div v-if="taskTabSection==='Tasks'">
									<button class="btn btn-outline-success" style = "margin-bottom:10px;"><i class="fas fa-save fa-1x"  @click="saveAsCSV(tasksTableView, 'tasks')"></i></button>
									<button @click="refreshTable('tasks')" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-sync fa-1x"></i></button>
									<input v-if="toggleFilterTable">
									<input id="name" type="text" placeholder="Start typing to filter by keyword..." v-model="message" required autocomplete="off"  class="form-control" style= "width:50%;">

									<b-pagination
										style="background-color: white;"
										v-model="currentPage"
										:total-rows="tasksTableView.length"
										:per-page="20"
										align="fill"
										size="md"
										class="my-0"
									></b-pagination>

									<b-table
										striped hover
										id="table-transition-example"
										:items="tasksTableView"
										:fields="taskTableFields"
										:perPage="20"
										:current-page="currentPage"
										:tbody-transition-props="transProps"
										:filter="message"
										:filterIncludedFields="[]"
										sort-icon-right
										sticky-header="700px"
										style="overflow-y: scroll;"
									></b-table>
									<div class="card-footer text-muted">
										Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
									</div>
								</div>
								<LabellingAudit v-else-if="taskTabSection==='Audit'" />
							</div>
						</div>
					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="agentView === true">

						<!-- Agents View -->
						<AgentView/>

					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="statView === true">
						<div class="card mb-3" style="height:100%;">
							<h3 class="card-header">Statistics View</h3>
							<div class="card-body">

								<button class="btn btn-outline-success" style = "margin-bottom:10px;"  @click="saveAsCSV(statsPerTaskView, 'stats')"><i class="fas fa-save fa-1x"></i></button>
								<button @click="refreshTable('stats')" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-sync fa-1x"></i></button>

								<input id="name" type="text" placeholder="search" v-model="message" autocomplete="off" required  class="form-control">

								<b-pagination
									style="background-color: white;"
									v-model="currentPage"
									:total-rows="tasksTableView.length"
									:per-page="20"
									align="fill"
									size="md"
									class="my-0"
								></b-pagination>

								<b-table
									striped hover
									:items="statsPerTaskView"
									:fields="Object.keys(statsPerTaskView[0])"
									sort-icon-right
									:per-page="20"
									:current-page="currentPage"
									:filter="message"
									:filterIncludedFields="[]"
								>
								</b-table>

								<div class="card-footer text-muted">
									Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
								</div>

							</div>

						</div>
					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%" v-if="reAssignmentView === true">
						<div class="card mb-3" style="height:100%;background-color: transparent;">
							<div class="card-body">


								<button @click="refreshTable('tasks')" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-sync fa-1x"></i></button>
								<input v-if="toggleFilterTable">


								<b-table
									striped hover

									:items="tasksTableView2"
									:fields="['taskUuid','assignedUser', 'taskCreatedAt']"
									sortable
									filter="In Progress"
									selectable
									select-mode="single"
									@row-selected="reAssignmentSelect"
									sticky-header="700px"
									style="overflow-y: scroll;"
									sort-icon-right
								>

								</b-table>

								<div class="card-footer text-muted">
									Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
								</div>

							</div>

						</div>
						<div v-if="selectedPendingTask" class="card mb-3" style="height:100%;background-color: transparent;">
							<h3 class="card-header">Reassign</h3>
							<div class="card-body">

								<h5 class="card-title" >Choose an agent below</h5>
								<h6 class="card-subtitle text-muted">
									{{agents.length}} available agents
								</h6>
								<ul class="list-group list-group-flush" v-for="agent in agents" v-bind:key="agent">
									<li @click="reAssignTask(selectedPendingTask[0]['taskUuid'], agent['email'])" class="list-group-item">{{agent['email']}}</li>
								</ul>


							</div>

						</div>
					</div>
				</div>
				<!-- DATASETS SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%; width:fit-content;" v-if="currentSection === 'datasets'">
					<keep-alive>
						<DatasetManager></DatasetManager>
					</keep-alive>
				</div>
				<!-- BROADCAST SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="currentSection === 'broadcast'">
					<div style="display:flex;flex-direction:row">

						<input id="name" type="text" placeholder="message" v-model="message" required autofocus class="form-control" style="margin-right:10px;">
						<button class="btn btn-outline-success" @click="sendMessage(message)">send</button>
					</div>

				</div>
				<!-- SETTINGS SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="currentSection === 'settings'">
					<div class="card mb-3" style="height:100%;background-color: transparent;">
						<h3 class="card-header">Settings</h3>
						<div class="card-body">
							<b-table
								striped hover
								id="table-transition-example"
								:items="mySettingsTableView"
								:fields="['Property','Value']"
								selectable
								select-mode="single"
								@row-selected="settingsRowSelect"
								:filterIncludedFields="[]"
								sort-icon-right
							>
							</b-table>

							<div class="card-footer text-muted">
								Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
							</div>

							<div v-if="selectedSetting"  class="card mb-3" style="height:100%;" id="sidebar">
								<div class="card-body">
									<h5 class="card-header">Edit {{selectedSetting['Property']}}</h5>
									<p>{{selectedSetting['Property']}}: <input style="width:80px;" id="name" type="text" :placeholder="selectedSetting['Value']" v-model="newSetting" required autocomplete="off" class="form-control"> </p>
									<button v-if="((newSetting!==''))&&(newSetting!==null)&&(parseInt(newSetting) !== parseInt(selectedSetting['Value']))" @click="editSettings(selectedSetting, newSetting)" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-save fa-1x"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
</template>

<script>
import { dataAPI } from "../../http-common";
import {ToCamelCase} from "@/utils/strings";
import Login from "./Login.vue";
import { v1 as uuidv1 } from 'uuid';
import {ExportTableAsCSV} from '@/utils/files';
import DatasetManager from "@/components/dashboard/DatasetManager.vue";
import SideBar from "@/components/dashboard/SideBar.vue";
import TrackListTask from "@/components/TrackListTask.vue";

import AgentView from '@/components/dashboard/AgentView.vue';
import RealTimeValue from "@/components/common/RealTimeValue.vue";
import LabellingAudit from "@/components/dashboard/LabellingAudit.vue"
import { BOverlay, BTable, BSpinner, BAlert, BFormInput, BButton, BPagination, BDropdown, BTabs, BTab} from 'bootstrap-vue';
import Vue from 'vue';
Vue.component('b-tabs', BTabs);
Vue.component('b-tab', BTab);
Vue.component('b-table', BTable);

import config from '@/config';
import DataExplorer from '@/components/dashboard/DataExplorer.vue';
import {Delay} from '@/utils/promises';


export default {
	name: "LabellingClient",
	components: {
		Login,
		TrackListTask,
		AgentView,
		DatasetManager,
		DataExplorer,
		RealTimeValue,
		SideBar,
		BDropdown,
		BTabs,
		BOverlay,
		LabellingAudit,
	},
	props: {
		db: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			selectedDatabase: null,
			toggleHFM: null,
			agents: null,
			transProps: {
				// Transition name
				name: 'flip-list'
			},
			databasesAvailable: null,
			toggleSettings: false,
			toggleBroadcast:false,
			toggleAdmin:false,
			msg:null,
			message:null,
			myReidTasks: null,
			toggleMyTasks: false,
			selectedReidTask: null,
			reidReviewSelected: false,
			lastUpdate: null,
			toggleTasks:true,
			reidSelected: false,
			username: "",
			reAssignmentView: false,
			databaseSelected: "",
			databasesAvailable: [],
			newSetting: null,
			days: null,
			taskToDatasetMap: null,
			selectedPendingTask: null,
			selectedDay: "",
			labellerInfo: null,
			labellerStats: { stats: [] },
			settingDescriptionMap: {'ReID Task Size': 'How many pages are assigned to an agent during a ReID task request',
				'QA Task Size': 'How many merge tracks are assigned to a user to clean',
				'QA Finished Tasks': 'When enabled, requesting QA work will only poll merges from finished reid tasks'},
			reidLocalTrackLists: null,
			labellingTaskInfoMap: null,
			costPerLabeller: null,
			preprocessedVisitTable: null,
			tabSelected: null,
			allTasks: null,
			reidincompleteReidTasks: null,
			activeTasks: true,
			defaultDb: "hfm1data2020cloud",
			videoDatasets: null,
			stages: {},
			selectedSetting:null,
			stages : 	[{'Stage 1': {'description':'Create Interactions'}},
				{'Stage 2': {'description':'Review Interactions'}},
				{'Stage 3': {'description':'Create Point Labels'}},
				{'Stage 4': {'description':'Review Point Labels'}}],
			labellers: [],
			labellerMetricMap: null,
			labellerStats: [],
			myReidReviewTasks: null,
			taskView: null,
			toggleFilterTable: false,
			statsPerTask: [],
			agentView: false,
			statView: false,
			userTaskMetricMap: null,
			selectedAgent: null,
			settings: [],

			toggleRecord: false,
			toggleAlerts: false,
			externalDatasets: null,
			experimentalFeatures: false,
			externalReviewSelected: false,
			myConsolidationTasks: [],
			consolidationSelected: null,
			consolidationOnlyAccounts: ['canago',
				'joynor',
				'eyelie',
				'sadear',
				'uplay'],
			currentPage: 1,
			toggleDatasets: false,
			mergesRequiringConsolidation: null,
			consolidationTasksInQueue: null,
			isUpdated: '',
			realTimeUpdates: [],
			currentSection: 'tasks',
			currentLabellingSection: 'ReID',
			currentTaskView: 'active',
			currentTaskUuid: '',
			taskTypeMenu: {},
			pastTasks: [],
			taskBreakDown: [],
			taskTabSection: 'Tasks',
			imageDatasets: [],
			userImageTasks: [],
		};
	},
	watch: {
		username(newVal) {
			this.retrieveReidTasks();
			this.retrieveReidReviewTasks();
			this.retrieveConsolidationTasks();
		},
		defaultDb(newVal){
			localStorage.setItem('dbSelected',newVal);
			this.$emit('dbSelected', newVal)
			this.retrieveReidTasks();
			this.retrieveTasksFromDatabase();
			this.retrieveIncompleteReid();
		},
	},
	computed: {
		mySettingsTableView(){

			const rowObj= Object.keys(this.settings[0]).map(key=>{
				return {"Property": this.camelToTitleCase(key),
					"Value": this.settings[0][key],
					"Description": this.settingDescriptionMap[key]
				};
			})

			return rowObj;

		},
		aggregatedTasks(){
			return [this.myReidTasks, this.myReidReviewTasks, this.myConsolidationTasks, this.userImageTasks].flat();
		},
		tasksToDisplay(){
			if(this.currentTaskView === 'active'){
				return this.aggregatedTasks;
			}
			else{
				return this.pastTasksSorted
			}
		},
		isAdmin(){
			return (localStorage.getItem('is_admin') === 'true');
		},
		isChecker(){
			return (localStorage.getItem('is_checker') === 'true');
		},
		myReidReviewTasksComputed(){
			return this.myReidReviewTasks;
		},
		tasksInProgress(){
			return this.allTasks.filter(task=>task.status === 'assigned')
		},
		tasksCompleted(){
			return this.allTasks.filter(task=>task.status === 'pendingApproval')
		},
		taskTableFields(){
			const colsToDisplay = ['taskUuid',
				'tableName',
				'taskType',
				'assignedUser',
				'taskCompleted',
				'taskCreatedAt',
				'status',
				'taskDuration']

			return colsToDisplay.map(col => {
				if(col === "taskCompleted") return {key: col,label: "Task Completed (UTC)", sortable: true};
				else if(col === "taskDuration") return {key: col,label: "Task Duration (HH:MM:SS)", sortable: true};
				else if(col === "tableName") return {key: col,label: "Dataset Date (YYYY/MM/DD)", sortable: true};
				else return {key: col, sortable: true}
			})


		},
		organisation(){
			return (localStorage.getItem('email').split('@')[1].replace('.com',''));
		},
		tasksTableView(){
			const colsToDisplay = ['taskUuid','tableName', 'taskType', 'assignedUser', 'taskCreatedAt','taskCompleted', 'status']
			let _this = this;
			const formatRowValue = function(value, columnName,_this){
				if(columnName === 'taskType'){
					if(value === 'reidReview'){
						return 'ReID QA'
					}
					else if(value === 'reid'){
						return 'ReID'
					}
					else{
						return value
					}
				}
				else if(columnName === 'status'){
					if(value === 'assigned'){
						return 'In Progress'
					}
					else if(value === 'pendingApproval'){
						return 'Completed'
					}
					else{
						return value
					}
				}
				else if(columnName === 'tableName'){
					return _this.tableNameToDate(value);
				}
				else{
					return value
				}
			}

			return this.allTasks.map(row=>{
				const rowObj = {}
				colsToDisplay.forEach(col=>{
					rowObj[col] = formatRowValue(row[col], col, this);
				})
				if (rowObj['status'] === 'In Progress'){
					rowObj['taskCompleted'] = 'N/A';
					rowObj['taskDuration'] = 'N/A';
				}
				if (rowObj['status'] === 'Completed'){
					rowObj['taskDuration'] = this.calculateDuration(rowObj['taskCompleted'], rowObj['taskCreatedAt'])
				}

				return rowObj;
			})
		},
		tasksTableView2(){
			const colsToDisplay = ['taskUuid','tableName', 'taskType', 'assignedUser', 'taskCompleted', 'taskCreatedAt', 'status']
			let _this = this;
			const formatRowValue = function(value, columnName,_this){
				if(columnName === 'taskType'){
					if(value === 'reidReview'){
						return 'ReID QA'
					}
					else if(value === 'reid'){
						return 'ReID'
					}
					else{
						return value
					}
				}
				else if(columnName === 'status'){
					if(value === 'assigned'){
						return 'In Progress'
					}
					else if(value === 'pendingApproval'){
						return 'Completed'
					}
					else{
						return value
					}
				}
				else if(columnName === 'tableName'){
					return _this.tableNameToDate(value);
				}
				else{
					return value
				}
			}
			return this.allTasks.map(row=>{
				const rowObj = {}
				colsToDisplay.forEach(col=>{
					rowObj[col] = formatRowValue(row[col], col, this);
				})
				if (rowObj['status'] === 'In Progress'){
					rowObj['taskCompleted'] = 'N/A';
					rowObj['taskDuration'] = 'N/A';
				}
				if (rowObj['status'] === 'Completed'){
					rowObj['taskDuration'] = this.calculateDuration(rowObj['taskCompleted'], rowObj['taskCreatedAt'])
				}

				return rowObj;
			})
		},
		agentTableView(){
			const colsToDisplay = ['name','email','is_admin','hasQAPrivledges','isLoggedIn'];
			return this.agents.map(agent => {
				const rowObj = {};
				colsToDisplay.forEach(col=>{
					rowObj[col] = agent[col]
				})
				return rowObj;
			})
		},
		statsPerTaskView(){

			const colsToDisplay = ['taskUuid','dataset', 'user','uniquePeopleMerged','costPerLabel','costPerUniquePerson', 'errorRate'];
			return this.statsPerTask.map(stat => {
				const rowObj = {};
				colsToDisplay.forEach(col=>{
					if(col === 'errorRate'){
						rowObj['Error Rate %'] = this.calculateErrorRate({
							errorCount: stat['errorCount'],
							totalCount : stat['totalCount']
						})
					}
					else if(col === 'dataset'){
						rowObj['Dataset'] = this.tableNameToDate(this.taskToDatasetMap.get(stat['taskUuid']))
					}
					else{
						rowObj[col] = stat[col]
					}

				})
				return rowObj;
			})
		},
		isNew(){
			return (localStorage.getItem('is_new') === 'true');
		},
		accountType(){
			return (localStorage.getItem('account_type'));
		},
		iconsForUser(){
			if(this.isAdmin){
				return ['tasks', 'manager', 'datasets', 'broadcast','settings']
			}
			else{
				return ['tasks']
			}
		},
		pastTasksSorted(){
			return this.pastTasks.sort((a,b) => new Date(b.taskCreatedAt)-new Date(a.taskCreatedAt))
		},

	},
	async mounted() {
		this.retrieveTasksFromDatabase();
		this.retrieveIncompleteReid();
		this.retrievePastTasks();
		this.username = localStorage.getItem("username");
	},
	methods: {
		openTask(taskObject, taskType){
			if(taskType==='reid'){
				this.openReidTask(taskObject);
			}
			if(taskType==='consolidation'){
				this.openConsolidationTask(taskObject);
			}
			if(taskType==='reidReview'){
				this.openReidReviewTask(taskObject);
			}
			
		},
		startLabelling(accountType, dataset, datasetType, databaseName){
			const datasetInfo= this.reidincompleteReidTasks.find(d => d.tableName === dataset);
			if(datasetType==='reid'){
				if(accountType.toLowerCase() === 'reid'){
					this.requestReidTask(dataset, databaseName);
				}
				if(accountType.toLowerCase() === 'consolidation'){
					if(!this.settings[0].workflowBlocker){
						this.requestConsolidationTask(dataset, databaseName);
					}
					else{
						if(datasetInfo.localTracksRemaining <= 0){
							this.requestConsolidationTask(dataset, databaseName);
						}
						else{
							console.log('are we here?')
							this.$noty.error(`Consolidation unavailable until Local Track Assignment (ReID) is complete, refresh and try again later`, {layout:'bottomLeft'})
						}
					}
				}
				if(accountType.toLowerCase() === 'review'){
					if(!this.settings[0].workflowBlocker){
						this.requestReidReviewTask(dataset, false, databaseName);
					}
					else{
						if((datasetInfo.localTracksRemaining <= 0 ) && (datasetInfo.consolidationProgress.mergesRequiringConsolidationFromReID === 0)){
							this.requestReidReviewTask(dataset, false, databaseName);
						}
						else{
							this.$noty.error(`Review unavailable until Consolidation has completed, refresh and try again later`, {layout:'bottomLeft'})
						}
					}
				}
			}
		},
		formatDuration(durationString){
			const timeUnits = durationString.split(':').map(r=>parseFloat(r));
			if(timeUnits[0] > 0){
				if(timeUnits[0] >= 24){
					return `${(timeUnits[0]/24).toFixed(0)} days ago`;
				}
				else{
					return `${timeUnits[0]} hours ago`;
				}
			}
			else if(timeUnits[1] > 0){
				return `${timeUnits[0]} minutes ago`;
			}
			else if(timeUnits[2] > 0){
				return `${timeUnits[0]} seconds ago`;
			}
			else{
				return `Created just now`
			}
		},
		calculateDuration(timeAString, timeBString){
			const timeStampA = new Date(timeAString);
			const timeStampB = new Date(timeBString);

			return (this.timeConversion(timeStampA-timeStampB));
		},
		timeConversion(ms) {

			let seconds = ms / 1000;
			// 2- Extract hours:
			let hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
			seconds = seconds % 3600; // seconds remaining after extracting hours
			// 3- Extract minutes:
			let minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
			// 4- Keep only seconds not extracted to minutes:
			seconds = seconds % 60;

			if(seconds<10){
				seconds = '0'+seconds
			}
			if(minutes<10){
				minutes = '0'+minutes
			}
			if(hours<10){
				hours = '0'+hours
			}

			return(`${hours}:${minutes}:${seconds}`);
		},
		camelToTitleCase(str){

			let result = str.replace( /([A-Z])/g, " $1" );

			return result.charAt(0).toUpperCase() + result.slice(1);
		},
		calculateErrorRate(metricObj){
			const errorPerTask = metricObj['errorCount'];
			const totalCountOfLocalTracks = metricObj['totalCount'];
			const totalCountWithErrors = errorPerTask+ totalCountOfLocalTracks;
			return (100*errorPerTask/totalCountWithErrors);
		},
		retrieveDatabases() {
			// Clear existing results
			const url = `/databases`;
			dataAPI
				.get(url)
				.then(response => {
					let database_options = response.data;
					if (database_options.length > 0) {
						// create track UUID list for caching tracks
						this.databasesAvailable = database_options;
					}
				})
				.catch(e => {
					this.t_error += e;
					this.database_options = [];
					console.log(e);
				});
		},
		agentOnline(item, type){
			if (!item || type !== 'row') return
			if (item.isLoggedIn === 1) return 'table-success';
		},
		agentRowSelected(row){
			if(row === this.selectedAgent){
				this.selectedAgent=null;
			}
			else{
				this.selectedAgent=row;
			}
		},
		setDatabase(selectedDatabase){
			this.defaultDb = selectedDatabase;
			// this.$noty.info(`Database changed to ${selectedDatabase}`, {layout:'bottomLeft'});
		},
		toggledDatabaseStyle(db){
			if(db === this.defaultDb){
				return {"style": 'color: white; background-color: #839496;border: 1px green solid', "text": "Selected"}
			}
			else{
				return  {"style": '', "text": ""}
			}

		},
		tableNameToDate(tableName){
			const yyyymmdd = tableName.replace(/reidlocaltracks/g,'');
			return yyyymmdd.slice(0,4)+"/"+yyyymmdd.slice(4,6)+"/"+yyyymmdd.slice(6,8);
		},
		saveAsCSV(items, tableName){

			let dataClone = [...items];
			ExportTableAsCSV(dataClone, tableName);
		},
		convertToCSV(objArray) {
			let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
			let str = '';

			for (let i = 0; i < array.length; i++) {
				let line = '';
				for (let index in array[i]) {
					if (line !== '') line += ','

					line += array[i][index];
				}

				str += line + '\r\n';
			}

			return str;
		},
		exportCSVFile(headers, items, fileTitle) {
			if (headers) {
				items.unshift(headers);
			}
			// Convert Object to JSON
			let jsonObject = JSON.stringify(items);
			let csv = this.convertToCSV(jsonObject);
			let exportedFilenmae = fileTitle + '.csv' || 'export.csv';
			let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

			if (navigator.msSaveBlob) { // IE 10+
				navigator.msSaveBlob(blob, exportedFilenmae);
			}
			else {
				let link = document.createElement("a");
				if (link.download !== undefined) { // feature detection
					// Browsers that support HTML5 download attribute
					let url = URL.createObjectURL(blob);
					link.setAttribute("href", url);
					link.setAttribute("download", exportedFilenmae);
					link.style.visibility = 'hidden';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			}
		},
		settingsRowSelect(row){
			this.selectedSetting=row[0];
		},
		reAssignmentSelect(row){

			console.log('row', row);

			if(row.length!==0){
				this.selectedPendingTask=row;
			}


		},
		async reAssignTask(taskUuid, newUser){
			const confirm = window.confirm(`Confirm reassignment to user ${newUser}`);
			if(confirm){
				const  url = `/reAssignTask/`;

				const body = {
					retainInfo: true,
					newUser: newUser.split('@')[0],
					taskUuid: taskUuid
				}
				const result = await dataAPI.post(url,body);
				this.$noty.success(`Moved task ${taskUuid} to user ${newUser}`, {layout: "bottomLeft"});
			}

		},
		editSettings(settingRow, newSetting){
			const body = {
				setting: ToCamelCase(settingRow['Property']),
				value: newSetting
			}
			dataAPI.post(`/editSetting/`,body).then(response=>{this.settings=response.data;})
		},
		openReidTask(taskObject){
			const db = taskObject['databaseName'].split('_')[0];
			const taskUuid = taskObject['taskUuid'];

			this.$router.push({ name: "track-scatter-task", params: { db: db, taskUuid: taskUuid} });
		},
		openReidReviewTask(taskObject){
			const db = taskObject['databaseName'].split('_')[0];
			const taskUuid = taskObject['taskUuid'];

			this.$router.push({ name: "reid-review-task", params: { db: db, taskUuid: taskUuid} });
		},
		openConsolidationTask(taskObject){
			const db = taskObject['databaseName'].split('_')[0];
			const taskUuid = taskObject['taskUuid'];

			window.location.replace(`consolidate/taskUuid/${taskUuid}`);
		},
		getActiveStages(tableName){
			const stages = 	[{'Stage 1': {'description':'Create Interactions'}},
				{'Stage 2': {'description':'Review Interactions'}},
				{'Stage 3': {'description':'Create Events'}},
				{'Stage 4': {'description':'Review Events'}}]

			const stageInfo = this.projects.filter(row=>row['tableName'] === tableName)[0]['stageInfo'];
			console.log('stages.filter((row, index) => ', stages.filter((row, index) => stageInfo['stageSize'][index.toString()] !== 0));
			// return stages

			return stages.filter((row, index) => stageInfo['stageSize'][(index+1).toString()] !== 0)
		},
		getCompletionClass(percentage){
			console.log(typeof percentage)
			if(parseFloat(percentage) === 0.0000){

				return 'badge badge-warning';
			}else if(parseFloat(percentage) > 0.8){
				return 'badge badge-info';
			}
			else{
				console.log(percentage)
				return 'badge badge-success';
			}
		},
		retreieveErrorRates(){
			dataAPI.get(`${this.defaultDb}/getErrorRatePerTask/`).then(response=>{this.userTaskMetricMap = response.data.userTaskMetricMap;})
		},
		sendMessage(message){
			this.$socket.emit('message', message);
		},
		requestReidTask(reidTable, databaseName) {
			const url = `${databaseName}/task/requestReidTask/`;

			const body = {
				tableName: reidTable,
				user: this.username,
				settings: this.settings
			};

			dataAPI.post(url, body).then(result => {
				if(result.data.msg !== undefined){
					this.$noty.error(`${result.data.msg}`, {layout:'bottomLeft'});
				}
				else{
					this.$noty.info('New Task Available!', {layout: 'bottomLeft'})
					this.myReidTasks.push(result.data)
				}
			});

		},
		finishTask(taskUuid, taskType){
			let confirm  = window.confirm('Submit task as finished?');
			if (confirm){
				dataAPI.post(`/updateTask`,{status: "pendingApproval", taskUuid: taskUuid, taskType: taskType}).then(response=>{
					if(taskType==='reid'){
						this.retrieveReidTasks();
					}
					if(taskType==='consolidation'){
						this.retrieveConsolidationTasks();
					}
					if(taskType==='reidReview'){
						this.retrieveReidReviewTasks();
					}
				})
			}
		},
		requestReidReviewTask(reidTable, isExternal, databaseName){


			const url = (isExternal) ? `${databaseName}/externalReview/requestReidReviewTask`: `${databaseName}/task/requestReidReviewTask`;

			const body = {
				tableName: reidTable,
				user: this.username,
				settings: this.settings
			};
			const reidStage1CompletionProgress = parseFloat(this.reidincompleteReidTasks.filter(r=>r.tableName===reidTable)[0].completionPercentage);

			if(reidStage1CompletionProgress < 1 && this.settings[0]['consolidationBlocker'] === 'on'){
				this.$noty.error(`First stage of labelling isnt done yet, please try again soon. Stage one progress: ${100*reidStage1CompletionProgress}%`, {layout:'center'});
			}
			else{
				dataAPI.post(url, body).then(result => {
					if(result.data.msg === "User already has task"){
						this.$noty.error(`Finish your current task before requesting another!`, {layout:'bottomLeft'});
					}
					else if(result.data.msg === "No jobs available"){
						this.$noty.error(`No more merges to review!`, {layout:'bottomLeft'});
					}
					else if (result.data.data.taskUuid!== undefined){
						this.$noty.info(`New task available!`, {layout:'bottomLeft'});
						this.myReidReviewTasks.push(result.data.data);
					}
				});
			}
		},
		requestConsolidationTask(datasetName, databaseName) {


			const url = `${databaseName}/task/requestConsolidationTask`;

			const body = {
				datasetName,
				username: this.username
			};
			const reidStage1CompletionProgress = parseFloat(this.reidincompleteReidTasks.filter(r=>r.tableName===datasetName)[0].completionPercentage);

			if(reidStage1CompletionProgress < 1 && this.settings[0]['consolidationBlocker'] === 'on'){
				this.$noty.error(`First stage of labelling isnt done yet, please try again soon. Stage one progress: ${100*reidStage1CompletionProgress}%`, {layout:'center'});
			}
			else{
				dataAPI.post(url, body).then(result => {
					if(result.data.msg === "User already has task"){
						this.$noty.error(`Finish your current task before requesting another!`, {layout:'bottomLeft'});
					}
					else if(result.data.msg === "No jobs available"){
						this.$noty.error(`No more merges to review!`, {layout:'bottomLeft'});
					}
					else if(result.data.msg === "No jobs available"){
						this.$noty.error(`No more merges to review!`, {layout:'bottomLeft'});
					}
					else if(result.data.msg !== undefined){
						this.$noty.error(`${result.data.msg}`, {layout:'bottomLeft'});
					}
					else if (result.data.taskUuid !== undefined){
						this.$noty.info(`New task available!`, {layout:'bottomLeft'});
						this.myConsolidationTasks.push(result.data);
					}
				});
			}
		},
		async requestImageTask(datasetName, databaseName){
			const { settings } = this;
			try {
				const response = (await dataAPI.post(`${databaseName}/task/requestImageLabellingTask`, {datasetName, settings})).data;
				if(response === 'No tasks available'){
					this.$noty.error('No tasks available', {layout: 'bottomLeft'});
					return;
				}
				this.$noty.info('New task available', {layout: 'bottomLeft'});
				await this.retrieveImageTasks();
			}
			catch(error){
				this.$noty.error('error', error);
			}
		},
		retrieveReidTasks(){
			const url = `/getReidTasks/user/${this.username}`;
			dataAPI
				.get(url)
				.then(result => {
					this.myReidTasks = result.data;
				});
		},
		retrieveReidReviewTasks(){
			const url = `/getReidReviewTasks/user/${this.username}`;
			dataAPI
				.get(url)
				.then(result => {

					if(result.data !== "User has no review tasks"){
						this.myReidReviewTasks = result.data;
					}
					else{
						this.myReidReviewTasks = []
					}

				});
		},
		retrieveConsolidationTasks(){
			const url = `/getConsolidationTasksForUser`;
			dataAPI
				.post(url, {username: this.username})
				.then(result => {

					this.myConsolidationTasks = result.data;
				});
		},
		async retrieveIncompleteReid(){
			const url = `/datasets/getReidProjectsWithProgress`;
			this.$root.$emit('setIsLoading', true)
			await dataAPI.get(url).then(result=>{

				this.reidincompleteReidTasks = result.data
					.filter(row => row.completionPercentage !== null)
					.sort((a, b) => (a["completionPercentage"] > b["completionPercentage"]) ? 1 : -1);

				Promise.all(this.reidincompleteReidTasks.map(async task => {

					const consolidationProgress = await this.retrieveConsolidationProgress(task.tableName, task.siteDB);
					const reviewProgress = await this.retrieveReviewProgress(task.tableName, task.siteDB);
					task.id = uuidv1();

					this.$set(task, 'consolidationProgress', consolidationProgress);
					this.$set(task, 'reviewProgress', ""+reviewProgress.progressPercentage/100); // why is this inconsistent?
					this.$set(task, 'unreviewed', reviewProgress.unreviewed);
					this.$set(task, 'reviewed', reviewProgress.reviewed);
				}));
			})
			this.$root.$emit('setIsLoading', false)

		},
		retrieveConsolidationProgress(dataset, dbName){
			const url = `${dbName}/getConsolidationProgress`;
			return dataAPI
				.post(url, {dataset: dataset})
				.then(result => {
					return result.data.progressObj;
				});
		},
		retrieveReviewProgress(dataset, dbName){
			const url = `${dbName}/getReidReviewProgress`;
			return dataAPI
				.post(url, {dataset: dataset})
				.then(result => {

					return result.data;
				});

		},
		retrieveExternalDatasets(){
			const url = `${this.defaultDb}/externalReview/getExternalDatasets`;
			dataAPI.get(url).then(result=>{
				this.externalDatasets=result.data.result;
			})
		},
		goToReidTask(taskUuid) {
			this.$router.push({
				name: "track-scatter-task",
				params: { db: this.databaseSelected, taskUuid: taskUuid }
			});
		},
		retrieveLabellerStatsMostRecentHour(labeller) {
			this.retrieveStatUniquePeopleMerged(labeller);
			this.retrieveStatCostPerLabel(labeller);
			this.retrieveStatCostPerUniquePerson(labeller);
		},
		retrieveStatUniquePeopleMerged(labeller) {
			const url = `/${this.databaseSelected}/getLabellerStats/${labeller}`;
			dataAPI
				.get(url)
				.then(response => {
					const metricObj = {
						uniquePeopleMerged:
							response.data[0]["uniquePeopleMerged"]
					};
					this.labellerStats["stats"].push(metricObj);
				})
				.catch(e => {
					console.log(e);
				});
		},
		retrieveStatCostPerLabel(labeller) {
			const url = `/${this.databaseSelected}/getCostPerLabel/${labeller}`;
			dataAPI
				.get(url)
				.then(response => {
					const metricObj = {
						costPerLabel: response.data["costPerLabel"]
					};
					this.labellerStats["stats"].push(metricObj);
				})
				.catch(e => {
					console.log(e);
				});
		},
		retrieveStatCostPerUniquePerson(labeller) {
			const url = `/${this.databaseSelected}/getCostPerUniquePerson/${labeller}`;
			dataAPI
				.get(url)
				.then(response => {
					const metricObj = {
						costPerUniquePerson:
							response.data["costPerUniquePerson"]
					};
					this.labellerStats["stats"].push(metricObj);
				})
				.catch(e => {
					console.log(e);
				});
		},
		refresh() {
			this.labellerStats = { stats: [] };
			this.retrieveLabellerStatsMostRecentHour(this.username);
			this.retrieveCostPerLabel(this.username);
			this.retrieveReidLabellingProgress(this.reidLocalTrackLists);
			this.retrieveTasksFromDatabase(this.databaseSelected);
		},
		refreshTable(tableView){
			this.retrieveTasksFromDatabase()
			this.lastUpdate=Date.now();
		},
		toggleTableCreation() {
			this.tabSelected = "table_creation";
		},
		toggleLabellerInfo() {
			this.tabSelected = "labeller_info";
		},
		toggleLabellingAssignment() {
			this.tabSelected = "labelling_assignment";
		},
		toggleLabellingTools() {
			this.tabSelected = "labelling_tools";
		},
		calculateProgress(reidLocalTrackList) {
			const remaining = this.labellingTaskInfoMap.filter(
				list => list.reidLocalTrackList === reidLocalTrackList
			)[0]["info"]["localTracksRemaining"];

			const total = this.labellingTaskInfoMap.filter(
				list => list.reidLocalTrackList === reidLocalTrackList
			)[0]["info"]["total"];

			return (100 * (1 - remaining / total)).toFixed(2);
		},
		retrieveLabellers(newVal) {
			this.labellers = [];

			this.labellerStats = [];
			const url = `/${newVal}/getLabellers`;

			dataAPI
				.get(url)
				.then(response => {
					response.data.forEach(userObj => {
						this.labellers.push({
							user: userObj["user"]

						});

						this.labellerStats.push({
							user: userObj["user"],
							status: 'active',
							stats: []
						});
					});
					this.retrieveLabellerStatsMostRecentHour(response.data);
				})
				.catch(e => {
					this.labellers = null;
					console.log(e);
				});
		},
		async retrieveTasksFromDatabase() {
			if(this.isAdmin){
				const url = `/getAdminInfo`;
				dataAPI
					.get(url)
					.then(response => {
						this.allTasks = response.data.tasks;
						this.agents = response.data.agents;
						this.settings = response.data.settings;
						this.taskToDatasetMap=new Map();
						response.data.tasks.forEach(task => {
							this.taskToDatasetMap.set(task['taskUuid'], task['tableName']);
						})
					})
					.catch(error => {
						console.log(error);
					});
			}
			else{
				dataAPI
					.post(`/getSettings`)
					.then(response => {

						this.settings = response.data.settings;


					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		async retrievePastTasks() {

			dataAPI
				.get(`/getTasksForUser/${localStorage.getItem("username")}/pendingApproval`,)
				.then(response => {

					this.pastTasks = response.data;

				})
				.catch(error => {
					console.log(error);
				});
		},
		retrieveStatsPerTask(tasks){

			this.statsPerTask=[]
			let requestObj = [];
			for(const task of tasks.filter(taskRow=>taskRow['status'] === 'pendingApproval').filter(taskRow=>taskRow['taskType'] === 'reid')){

				const assignedUser = task['assignedUser'];

				const url = `${this.defaultDb}/getLabellerStatsFromTimeRange/${assignedUser}`;

				const upperBoundTime = task['taskCompleted'].replace('T',' ').replace('Z','');
				const lowerBoundTime = task['taskCreatedAt'].replace('T',' ').replace('Z','');

				const timeStampA = new Date(upperBoundTime);
				const timeStampB = new Date(lowerBoundTime);
				const duration = (timeStampA-timeStampB);

				requestObj.push({
					upperBoundTime: upperBoundTime,
					lowerBoundTime: lowerBoundTime,
					taskUuid: task['taskUuid'],
					taskDuration: duration,
					selectedLabeller: assignedUser
				})


			}

			const url = `${this.defaultDb}/getLabellerStatsFromTimeRange`;
			dataAPI.post(url, {agentInputObj: requestObj}).then(result =>{
				result.data.forEach(r=>{
					this.statsPerTask.push(r);
				});

			}).catch(error=>{
				console.log(error)
			});

		},
		retrieveCostPerLabel(labeller) {
			const url = `/${this.databaseSelected}/getCostPerLabel/${this.username}`;

			dataAPI
				.get(url)
				.then(response => {

					this.costPerLabeller = response.data;
				})
				.catch(e => {
					this.costPerLabeller = null;
					console.log(e);
				});
		},
		retrieveReidLabellingProgress(reidLocalTrackLists) {
			this.labellingTaskInfoMap = [];

			reidLocalTrackLists.forEach(localTrackList => {
				const url = `/${this.databaseSelected}/getReidLabellingProgress/${localTrackList["tableName"]}`;
				console.log("url:", url);
				dataAPI
					.get(url)
					.then(response => {
						console.log(
							`Retrieving table... ${response.data.length} rows returned`
						);
						console.log("response.data[0]", response.data[0]);
						const labellingTaskInfoObj = {
							reidLocalTrackList: `${localTrackList["tableName"]}`,
							info: response.data[0]
						};
						this.labellingTaskInfoMap.push(
							labellingTaskInfoObj
						);
					})
					.catch(e => {
						console.log(e);
						// error handling required;
					});
			});
			dataAPI
				.get(`${this.databaseSelected}/externalReview/getExternalDatasets`)
				.then(response => {
					console.log(response.data);
				});
		},
		selectDay(dayString) {
			this.selectedDay = dayString;
		},
		createReidLocalTrackList() {
			console.log("this.selectedDay", this.selectedDay);
			const url = `/${this.databaseSelected}/createReidLocalTrackList`;

			const dateTime = `${this.selectedDay.split("-")[2]}-${
				this.selectedDay.split("-")[1]
			}-${this.selectedDay.split("-")[0]}`;

			const body = {
				dateTime: dateTime
			};
			dataAPI
				.post(url, body)
				.then(response => {
					console.log("response", response);
				})
				.catch(e => {
					this.days = null;
					console.log(e);
				});
		},
		createVisitTable: function(preprocessedVisitTable) {
			dataAPI
				.post(`/${this.databaseSelected}/visitTrack`, {
					startAndEndTable: preprocessedVisitTable
				})
				.then(response => {
					console.log(`Table Sent!`);
				})
				.catch(e => {
					console.log(e);
					console.log(`Table Not Sent!`);
				});
		},
		completeTask: function(taskUuid) {
			const confirm = window.confirm(
				"Request approval for this task?"
			);
			if (confirm) {
				const body = {
					taskUuid: taskUuid
				};
				const url = `/${this.databaseSelected}/markTaskAsPending/`;
				dataAPI
					.post(url, body)
					.then(response => {
						console.log("markAsPending result", response.data);
						this.refresh();
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		login: function() {
			let name = this.email;
			let password = this.password;
			let data = { name, password };
			this.$store
				.dispatch("login", data)
				.then(() => this.$router.push("/"))
				.catch(err => console.log(err));
		},
		register: function() {
			let data = {
				name: this.name,
				email: this.email,
				password: this.password,
				is_admin: this.is_admin
			};
			this.$store
				.dispatch("register", data)
				.then(() => this.$router.push("/"))
				.catch(err => console.log(err));
		},
		setCurrentSection(sectionName){
			this.currentSection = sectionName;
		},
	},
	sockets: {
		taskQueueUpdate(data){
			// retrieve progress on taskQueueUpdate event
			this.reidincompleteReidTasks.forEach(async task => {

				try{
					const consolidationProgress = await this.retrieveConsolidationProgress(task.tableName, task.siteDB);
					const reviewProgress = await this.retrieveReviewProgress(task.tableName, task.siteDB);
					task.id = uuidv1();

					this.$set(task, 'consolidationProgress', consolidationProgress);
					this.$set(task, 'reviewProgress', ""+reviewProgress.progressPercentage/100); // why is this inconsistent?
					this.$set(task, 'unreviewed', reviewProgress.unreviewed);
					this.$set(task, 'reviewed', reviewProgress.reviewed);
				}
				catch(error){
					console.error(`In taskQueueUpdate: unable to update task progress ${error}`);
				}
			});

		},
		broadcast(data){

			if(data?.info?.state === 'timedOut'){
				const {taskUuid} = data.info;
				this.myReidTasks = this.myReidTasks.filter(r=>r.taskUuid !== taskUuid);
				this.myConsolidationTasks = this.myConsolidationTasks.filter(r=>r.taskUuid !== taskUuid);
				this.myReidReviewTasks = this.myReidReviewTasks.filter(r=>r.taskUuid !== taskUuid);
			}
		}
	}
};
</script>

<style scoped>

	.list-group-item:hover {
		color: white;
		background-color: rgb(0, 35, 44);

	}
	.card-body {
		flex: 0 0 auto;
		border:1px solid transparent;
	}
	.card-body:hover {
		background-color: rgb(0, 35, 44);

	}
	.card-body.no-hover:hover {
		/* background-color: rgb(0, 35, 44); */
		background-color: transparent
	}
	.dataset-card{
		border: 1px solid rgb(36, 54, 58);
		margin-bottom:10px;
		height:100%;
		width:800px;
		border-radius: 5px;
		background-color: transparent;
	}
	.dataset-list {
		display: flex;
		flex-direction: column;
		width: fit-content;
		margin-top: 10px;
	}
	.dataset-card:hover{
		border-left: 1px solid #93E9BE;
	}
	/* .nav-tabs {
		margin-bottom: 20px;
	} */
	.badge {
		font-size:18px;
	}
	.badge-success{
			background-color: #313131
	}
	.badge-info{
			background-color: #19b13f;
	}
	.badge-warning {
		background-color: #FF1D10;
	}
	header{
		width: fit-content;
	}
	table#table-transition-example .flip-list-move {
		transition: transform 1s;
	}
	/* .breadcrumb {
		padding: 0;
		width: 100%;
	} */
	* {
	scrollbar-width: thin;
	scrollbar-color: blue #002B36;
	}
	*::-webkit-scrollbar {
		width: 12px;

	}
	*::-webkit-scrollbar-track {
		background: #002B36;
	}
	*::-webkit-scrollbar-thumb {
		background-color: #E6E9EA;
		border-radius: 20px;
		border: 3px solid #002B36;
	}
	.card-title{
		color: white;
	}
	.card.mb-3.task-row:hover{
		border-left: 1px solid #93E9BE;


	}

	.card.mb-3.task-row{
		grid-template-rows: 1fr;
		grid-template-columns: 48% 20% 1fr 2rem 2em;
		width:100%;
		height:15%;
		background-color: transparent;
		display:grid;
		flex-direction:row;
		padding:10px;
		border-radius:0px;
	}

	.row-item {
		display:flex;
		flex-direction: column;
		margin-right:30px;
	}

	.graphs {
		/* width: 50%; */
		margin-left: 111px;
		/* padding: 88px; */
	}

	.dropdown-button:hover{
		color: white;

		/* opacity: 0.5; */
	}


	.row-cell-header {
		margin-right:
		10px;
		margin-bottom:5px;
		font-size:1.2em;
		color:white;
	}

	.row-cell-item {
		margin-right:10px;
		font-size:0.9em;
		color:white;
	}

</style>
