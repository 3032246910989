/**
 * playground.store.js
 * Vuex module for playground-related data and methods, e.g.
 * - Completed training
 * - Active training tasks
 * - Ground-truthed data
 * - Comparing success/fail and reporting back to server
 */
import Vue from 'vue';
import { dataAPI } from '@/http-common';
import config from '@/config';

export default {
	namespaced: true,

	state: () => ({
		completedTraining: [],
		completedTrainingStorageKey: 'playground-completed-training',

		reviewTrainingData: null
	}),

	mutations: {
		SetCompleted(state, trainingName)
		{
			// Store in browser memory
			// Note: this means across computers, a particular user may OR may not have completed training
			localStorage.setItem(state.completedTrainingStorageKey, JSON.stringify(state.completedTraining));

			if (!state.completedTraining.includes(trainingName))
				state.completedTraining.push(trainingName);
		},

		SetReviewTrainingData(state, data)
		{
			state.reviewTrainingData = data;
		}
	},

	actions: {
		/**
		 * Initialise
		 * Listens on store root level. Initialises from local storage.
		 */
		Initialise: {
			root: true,
			async handler({state, commit})
			{
				const completedTraining = JSON.parse(localStorage.getItem(this.completedTrainingStorageKey)) ?? [];
				completedTraining.forEach(entry => commit('SetCompleted', entry));
			}
		},

		/**
		 * RequestNewReviewTrainingData
		 * Gets new review training data with settings provided.
		 * Data is commited to store, overwriting any existing.
		 * @param {Object} settings  Data passed into request.
		 */
		async RequestNewReviewTrainingData({state, commit, dispatch}, settings={})
		{
			if (!('difficulty' in settings))
			{
				throw new Error('Difficulty settings must be supplied to playground/RequestNewReviewTraining.');
			}

			try
			{
				// {mergeUUID: {correctLocalTracks, incorrectLocalTracks}, ...], ...}
				const {data} = await dataAPI.post(`/${config.playground.database}/groundTruth/review`, settings);
				console.log('Received training data:', data);

				// Cache all the detections, frames, etc.
				await Promise.allSettled( Object.values(data).map(  // for every mergeUUID
					({correctLocalTracks, incorrectLocalTracks}) =>   // join all trackUUIDs and get a promise for all cached data
						dispatch('cache/GetDataForLocalTracks', {
							localTracks: [...correctLocalTracks, ...incorrectLocalTracks]
						}, {root: true})
				) );

				commit('SetReviewTrainingData', data);
				return data;
			} catch(error)
			{
				console.error('Request for Review playground data failed:', error);
				return null;
			}
		}
	}
}