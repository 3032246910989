<template>

<div>
	<ul v-if="$store.state.tracks.length===0" class="list-group list-group-flush" style="max-width: 100%;">
		<li class="list-group-item"><code>No tracks left to label</code></li>
	</ul>
	<div v-if="stage && stage < 3" class="column">
		<!-- <h1 >Stage {{stageInfo}}</h1> -->
		<span v-if="retrieveTracksStatus.length > 0">{{ retrieveTracksStatus }}</span>

		<div class="card border-secondary mb-3" style="max-width: 100%;">
			<router-link v-for="(track, index) in $store.state.tracks"
				:class="{done: track.isDone}"
				class="track"
				:key="'task_interaction_link_' + index"
				tag="section"
				:to="{ path: `/viewer/${track.trackUuid}/${taskType}` }">
				<ul class="list-group list-group-flush" style="max-width: 100%;">
					<li class="list-group-item"><h6> {{index+1}} - Track Uuid: {{track.trackUuid}} </h6></li>
				</ul>
			</router-link>

		</div>

	</div>

	<div v-if="stage && stage > 2 && stage < 5" class="column">

		<h1 >{{(stage === 3) ? 'Label' : 'Review'}} Points</h1>

		<span v-if="retrieveTracksStatus.length > 0">{{ retrieveTracksStatus }}</span>
		<div class="card border-secondary mb-3" style="max-width: 100%;">

			<router-link v-for="(interaction, index) in $store.state.interactions" :class="{done: interaction.isDone}" class="track" :key="'task_interaction_link_' + index" tag="section" :to="{ path: `/db/${db}/review/${interaction.uuid}/${stage}` }">
				<h6> {{index+1}} - Interaction Uuid: {{interaction.uuid}} </h6>
			</router-link>
		</div>
	</div>
</div>
</template>
<script>
import { dataAPI } from "../http-common";
export default {
	name: "TrackListTask",
	props: {
		db: { type: String, default: 'hfm1data2020cloud'},
		taskUuid: {type: String, default: ''},
		taskType: {type: String, default: ''},
	},
	data() {
		return {
			stage: null,
			inputDataLoaded: false,
			toiTables: [],
			trackStatus: '',
			trackStatusInput: '',
			// tracks: [],
			pageSize: 50,
			retrieveTracksStatus: '',
			usernameInput: '',
			databaseSelected: '',
			toiTableSelected: '',
			username: '',
			trackIDs: [],
			initialTrackRetrievalFinished : false,
			databasesAvailable: [],
			tasks: null,
			useExperimental: true
		};
	},
	watch: {
		$route (to) {
			if (to.params.page !== undefined) {
				this.page = parseInt(to.params.page);
			} else {
			}
		}
	},
	mounted() {

		this.retrieveTracks();
		this.username = localStorage.getItem("username");
	},
	methods: {
		/* eslint-disable no-console */
		retrieveTracks() {
			this.$forceUpdate();
			const taskUuid = this.taskUuid;
			const url = `/getTracksFromTaskUuid/${taskUuid}`;
			this.$store.commit('clear');
			dataAPI
				.get(url)
				.then(response => {
					this.stage = response.data[0]['stage'];

					let task_objects = response.data.map(function(t){
						t.isActive = false;
						t.isDone = false;
						return t;
					});

					console.log("task_objects",task_objects);

					if (this.stage < 3) {
						console.log("stage:", this.stage);

						task_objects.forEach((track) => {
							track.trackUuid = track.foreignUuid;

							console.log("adding track:", track);

							this.$store.commit('addTrack', track);
						});
					} else if (this.stage < 6) {

						task_objects.forEach((interaction) => {

							interaction.category = "interaction";
							interaction.uuid = interaction.foreignUuid;

							this.$store.commit('addInteraction', interaction)
						});
					}
				})
				.catch(e => {
					this.retrieveTracksStatus = e;
					console.log(e);
				});

			// @todo: save and reload store state to localStorage.
		},
		refreshList() {
			this.retrieveTracks();
		}
	}
};
</script>

<style scoped>

	/**
	* CSS HERE
	*/

	#body {
		float:left;
	}

	.nav {
		margin-bottom: 10px;
	}

	.nav > span {
		width: 100%;
		text-align:left;
		vertical-align:middle;
		line-height: 60px;
		font-size: 60px;
	}

	.nav > span > i::before {
		margin-right: 20px;
	}

	.nav > span:hover {
		color: #0000009c;
		cursor: pointer;
	}
	/* .column{
		float:center;
	} */
	.statistics ul.options {
		max-width: 600px;
	}

	.statistics ul.options li {
		margin-bottom: 10px;
	}

	section.status.inset {
		-webkit-box-shadow: inset 0px -3px 5px -3px rgba(0,0,0,0.75);
		-moz-box-shadow: inset 0px -3px 5px -3px rgba(0,0,0,0.75);

	box-shadow: inset 0px -3px 5px -3px rgba(0,0,0,0.75);
	}

	section.track:not(.viaurl) {
		/* background-color: #000; */

		color: #BBC7C9;
		width: 100%;
		cursor: pointer;
		vertical-align: middle;
	}

	section.track:not(.viaurl):hover {
		color: white;
		background-color: #839496;

	}

	section.track.done {
		background-color: rgba(0, 30, 37, 0.25);
		pointer-events: none;
	}

	section.track h2,
	section.track strong {
		color: white;

	}

	.options {
		max-width: 98vm;
		overflow:auto;
		/* padding-bottom: 20px; */
	}

	.options li.hide {
		display: none;
	}

	.column {
		float: right;
		width:100%;
		/* padding-right: 20px; */
	}

	#trackList nav button:first-of-type {
		margin-right: 20px;
	}
	.trackList {
		border-top:1px solid #839496;
	}
	.local-track-list .local-track.col3 {
	flex: 1 0 33%;
}
</style>