<template>

<div>
	<h4>Audit agent work for invoicing</h4>
	
	<div class="my-2">
		<button
			@click="aggregateOnUser=!aggregateOnUser"
			class="btn btn-outline-success"
			:class="{active: aggregateOnUser}"
		>
			Aggregate On User
		</button>
	</div>

	<div class="date-range my-4">
		<b-form-input v-model="auditBillingStart" placeholder="Billing period start (YYYY-MM-DD)"></b-form-input>
		<i class="fas fa-arrow-right fa-1x my-2 mx-4"/>
		<b-form-input v-model="auditBillingEnd" placeholder="Billing period end (YYYY-MM-DD)"/>
	</div>
	
	<div>
		<b-form-select v-model="datasetSelected" :options="datasets.map(r=>r.datasetName)" placeholder="Select Dataset"/>
	</div>
	
	<div class="my-2">
		<b-overlay
			:show="loadingAuditResults"
			rounded
			opacity="1"
			spinner-small
			spinner-variant="info"
			class="d-inline-block"
			@hidden="onHidden"
		>
			<b-button variant="outline-success" @click="retrieveAudit(auditBillingStart, auditBillingEnd)">
				Generate Audit
			</b-button>
		</b-overlay>
		<button
			v-if="auditResults.length>0"
			@click="saveAsCSV()"
			class="btn btn-outline-success mx-2">
			<i class="fas fa-save fa-1x"/>
		</button>
	</div>

	<div v-if="auditResults.length>0 && aggregateOnUser" class="audit-summary my-3">
		<h1>{{moment(auditBillingStart).format('MMMM Do YYYY')}} to {{moment(auditBillingEnd).format('MMMM Do YYYY')}}</h1>
		<p>{{auditSummary}}</p>
	</div>

	<b-table
		striped hover
		:items="auditResults"
	/>

</div>

</template>

<script>
import { BButton, BCard, BFormInput as BInput, BFormSelect, BFormSelectOption, BTable } from 'bootstrap-vue';
import Vue from "vue";
Vue.component('b-table', BTable);
import { dataAPI } from "../../http-common";
import {ExportTableAsCSV} from '@/utils/files';
import moment from 'moment-timezone';

export default {
	name: 'LabellingAudit',

	components: {
		BButton,
		BCard,
		BInput,
		BFormSelect,
		BFormSelectOption
	},

	data: () => ({
		userTimezone: '',
		auditBillingStart: '',
		auditBillingEnd: '',
		auditResults: [],
		moment: moment,
		loadingAuditResults: false,
		aggregateOnUser: false,
		datasetSelected: '',
		datasets: []
	}),

	watch: {
		aggregateOnUser(newVal){
			this.clearResults();
		},
		auditBillingStart(newVal){
			this.clearResults();
		},
		auditBillingEnd(newVal){
			this.clearResults();
		},
	},

	computed: {
		auditSummary(){
			if (this.auditResults.length) {
				const totalHours = this.auditResults.reduce((sum, x)=>{return sum+=x.hours},0);
				return `${totalHours.toFixed(2)} hours total, $${(totalHours*6).toFixed(2)} USD for this period`
			}
			return '';
		},
		organisation(){
			return (localStorage.getItem('email').split('@')[1].replace('.com',''));
		},
	},

	methods: {
		pluralSuffix(number, suffix) {
			const plural = (number === 1) ? '' : 's';
			return `${number} ${suffix}${plural}`;
		},
		clearResults(){
			this.auditResults = [];
		},
		async retrieveAudit(start, end){
			const { aggregateOnUser, userTimezone } = this;
			try{
				this.loadingAuditResults = true;
				this.auditResults = (await dataAPI.post('/retrieveAgentHours', {timezone: userTimezone, from: start, to: end, aggregateOnUser, datasetName: this.datasetSelected})).data;

			}
			catch(error){
				this.$noty.error(`Unable to retrieve audit for ${start} ${end}: ${error}`)
			}
			this.loadingAuditResults = false;
		},
		saveAsCSV(){
			const items = this.auditResults;
			const tableName =  `${this.organisation}_black_ai_labelling_work_summary_${this.auditBillingStart}_${this.auditBillingEnd}`
			let dataClone = [...items];
			ExportTableAsCSV(dataClone, tableName);
		},
		async retrieveDatasets(){
			try{
				this.datasets = (await dataAPI.post('/datasets/getDatasets')).data;
			}
			catch(error){
				this.$noty.error(`Unable to retrieve datasets: ${error}`)
			}
			
		}
	},

	async mounted(){
		this.userTimezone = moment.tz.guess();
		this.retrieveDatasets();
	},
}
</script>


<style scoped>
.date-range {
	display: flex;
	flex-direction: row;
	width: 50em;
}
</style>