
<div id="body">
	<section>
		<div class="row" style="display:flex; flex-direction: row;">
			<!-- SIDE BAR SECTION -->
			<SideBar
				:icons="iconsForUser"
				@setCurrentSection="setCurrentSection"
			>
			</SideBar>


			<div style="height:100%;">
				<!-- TASK SECTION -->
				<!-- temp change to only allow admins to view datasets-->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: column; height: 100%;" v-if="(currentSection === 'tasks') && (!isNew)">
					<div class="card mb-3" style="height:100%;width:fit-content;display: flex; flex-direction: row; background-color: transparent;border:0px transparent">
						<!-- <h3 class="card-header">Tasks</h3> -->
						<div class="card-body" @click="activeTasks = true; toggleMyTasks=false;" :style="`border-bottom: 3px solid ${(activeTasks) ? '#007fff' : '#001014' };  height:60px; padding:12px;`">
							<h5 class="card-title" style="font-size: 15px;">Active Labelling Projects</h5>
							<h6 v-if="reidincompleteReidTasks" class="card-subtitle text-muted" style="font-size:12px;" >
								<RealTimeValue :value="reidincompleteReidTasks.length+' Datasets In Queue'"/>
							</h6>
							<h6 v-else class="card-subtitle text-muted" style="font-size:12px;">
								Loading tasks...
							</h6>
						</div>
						<div class="card-body" @click="activeTasks = false; toggleMyTasks=true;" :style="`border-bottom: 3px solid ${(toggleMyTasks) ? '#007fff' : '#001014' }; height:60px; padding: 12px;`">
							<h5 class="card-title" style="font-size: 15px;">My Tasks <span class = "badge badge-success" style = "color: red"></span></h5>
							<h6 v-if="myReidTasks" class="card-subtitle text-muted" style="font-size:12px;">
								<RealTimeValue :value="aggregatedTasks.length+' Assigned Tasks'"/>
							</h6>
							<h6 v-else class="card-subtitle text-muted" style="font-size:12px;">
								Loading tasks...
							</h6>
						</div>
					</div>

					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="activeTasks === true">
						<!-- {{reidincompleteReidTasks.length}} -->
						<div class="card mb-3" style="height:100%; width:fit-content; background-color: transparent; border: 0px solid transparent;">
							<div style="display:flex; flex-direction: column">
								<div style="display: flex; flex-direction: row;width:fit-content">
									<div v-if="['all_tasks', 'reid', 'admin', 'consolidation', 'review'].includes(accountType)" class="card-body" :style="`border-bottom: 3px solid ${(currentLabellingSection==='ReID') ? '#007fff' : '#001014' }`" @click="currentLabellingSection = 'ReID'">
										<h5  class="card-title" >ReID</h5>
										<h6 v-if="reidincompleteReidTasks" class="card-subtitle text-muted">
											{{ reidincompleteReidTasks.length }} Datasets
										</h6>
										<h6 v-else class="card-subtitle text-muted">
											Loading tasks...
										</h6>
									</div>
								</div>

								<div v-if="currentLabellingSection==='ReID'" class="dataset-list">
									<div class="dataset-card"  v-for="project in reidincompleteReidTasks" v-bind:key="project['tableName']" >
										<div class="card-body no-hover">
											ReID Dataset
											{{taskTypeMenu[project.tableName]}}
											<div style="display:flex;flex-direction:column;float:right">


												<button
													v-if="accountType!=='all_tasks'"
													type="button"
													class="btn btn-outline-info"
													style="border: 2px solid #007fff;"
													@click="startLabelling(accountType, project.tableName, 'reid', project.siteDB);"
												>

													Start Labelling

												</button>
												<div v-else>
													<!-- Only  admins and 'all_tasks' agents are allowed to select task type on task request-->
													<b-dropdown text="Start Labelling" variant="outline-info">
														<div v-for="taskType in ['ReID', 'Consolidation', 'Review']" v-bind:key="taskType"
															style="margin-left:10px;width: 88%;padding:10px;border-bottom:1px solid #001014;">
															<div class="dropdown-button"
																@click="startLabelling(taskType, project.tableName, 'reid', project.siteDB)"
															>
																{{taskType}}

															</div>
														</div>

													</b-dropdown>
												</div>

											</div>
											<h5 v-if="project['datasetType']!=='reid-benchmark'" class="card-title" >Standard: {{project['tableName']}} </h5>
											<h5 v-else>Benchmark: {{project['tableName']}}</h5>
											
											<div style="display:flex; flex-direction: row;">
												<div class="card mb-3" style="background-color: transparent; border-top: 1px solid #007fff;border-radius:0px;width: fit-content;height:130px;padding:10px;border-bottom:none;border-left:none;border-right:none">
													<h5 style="color:white;font-size:1em;">Local Track Assignment</h5>
													<RealTimeValue :value="project.localTracksRemaining">
														Images Remaining:
													</RealTimeValue>
													<RealTimeValue :value="`${(project.completionPercentage*100).toFixed(2)}%`">
														Progress:
													</RealTimeValue>
												</div>
												<div class="card mb-3" style="background-color: transparent;border-top: 1px solid #007fff;border-radius:0px ;width: fit-content;height:130px;padding:10px;border-bottom:none;border-left:none;border-right:none">
													<h5 style="color:white;font-size:1em;">Consolidation</h5>

													<RealTimeValue :value="project.consolidationProgress && project.consolidationProgress.mergesRequiringConsolidationFromReID">
														Merges Requiring Consolidation From ReID:
													</RealTimeValue>

													<RealTimeValue :value="project.consolidationProgress && project.consolidationProgress.mergesRequiringConsolidationFromReview">
														Merges Requiring Consolidation From Review:
													</RealTimeValue>

												</div>
												<div class="card mb-3" style="background-color: transparent;border-top: 1px solid #007fff;border-radius:0px;width: fit-content;height:130px;padding:10px;border-bottom:none;border-left:none;border-right:none">
													<h5 style="color:white;font-size:1em;">Review</h5>

													<RealTimeValue :value="project.unreviewed">
														Unreviewed Tracks:
													</RealTimeValue>
													<RealTimeValue :value="project.reviewed">
														Reviewed Tracks:
													</RealTimeValue>
													<RealTimeValue :value="`${(project.reviewProgress*100).toFixed(2)}%`">
														Progress:
													</RealTimeValue>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					<div style="display: flex; flex-direction:row;" v-if="toggleMyTasks === true">
						<div style="display:flex; margin-left:0.01rem;flex-direction: column; height: 100%;">
							<div style="display:flex;flex-direction:row;margin-bottom:10px;">

								<div class="card-body" :style="`border-bottom: 3px solid ${(currentTaskView==='active') ? '#007fff' : '#001014' }`" @click="currentTaskView = 'active'">
									<h5 class="card-title" >Active Tasks</h5>

									<h6 v-if="aggregatedTasks" class="card-subtitle text-muted">
										{{ aggregatedTasks.length }} Tasks
									</h6>

									<h6 v-else class="card-subtitle text-muted">
										Loading tasks...
									</h6>
								</div>

								<div class="card-body" :style="`border-bottom: 3px solid ${(currentTaskView==='past') ? '#007fff' : '#001014' }`" @click="currentTaskView = 'past'">
									<h5  class="card-title" >Historical Tasks</h5>
									<h6 v-if="pastTasks" class="card-subtitle text-muted">
										{{ pastTasks.length }} Tasks
									</h6>
									<h6 v-else class="card-subtitle text-muted">
										Loading tasks...
									</h6>
								</div>
							</div>
							<div style="overflow-y: auto; max-height:900px;">
								<div v-for="task in tasksToDisplay" class="card mb-3 task-row" style="display: flex; flex-direction: row;" v-bind:key="task.taskUuid">

									<div class="row-item" style="width: 300px;">
										<div class="row-cell-header" style="">Task ID</div>
										<div class="row-cell-item" style=""><p>{{task.taskUuid}}</p></div>
									</div>

									<div class="row-item" style=" max-width: 250px; width: fit-content;min-width: 250px;overflow-wrap: break-word;">
										<div class="row-cell-header" style="">Dataset Name</div>
										<div class="row-cell-item" style=""><p>{{task.tableName}}</p></div>
									</div>

									<div class="row-item" style=" max-width: 125px; width: fit-content;min-width: 125px;overflow-wrap: break-word;">
										<div class="row-cell-header">Type</div>
										<div class="row-cell-item"><p>{{camelToTitleCase(task.taskType)}}</p></div>
									</div>

									<div class="row-item">
										<div class="row-cell-header">Time Created</div>
										<div class="row-cell-item"><p>{{formatDuration(calculateDuration(Date.now(), task.taskCreatedAt))}}</p></div>
									</div>

									<template v-if="currentTaskView==='active'">
										<button
											type="button"
											class="btn btn-outline-info"
											style="border: transparent; float: right; height:35px;padding-top:4px;margin-top:5px;margin-right:-5px;"
											@click="openTask(task, task.taskType)"
										>
											<i class="fas fa-external-link-square-alt fa-1x">
											</i>
										</button>

										<button
											type="button"
											v-if="isAdmin"
											class="btn btn-outline-info"
											style="border: transparent;float: right; height:35px;padding-top:4px;margin-top:5px;margin-right:-5px;"
											@click="finishTask(task.taskUuid, task.taskType)"
										>
											<i class="fas fa-check fa-1x" style="color: #93E9BE;">
											</i>
										</button>
									</template>
									
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- ADMIN SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="currentSection === 'manager'">
					<div class="card mb-3" style="height:100%; background-color: #001014;" id="sidebar">
						<!-- <h3 class="card-header">Admin</h3> -->

						<div class="card-body" :style="`border-left: 3px solid ${(taskView) ? '#007fff' : '#002B36' }`" @click="taskView = true;agentView=false;statView=false;reAssignmentView=false;">
							<h5 class="card-title">Tasks</h5>
							<h6 class="card-subtitle text-muted">{{tasksInProgress.length}} task(s) in progress</h6>
							<br>
							<h6 class="card-subtitle text-muted">{{tasksCompleted.length}} task(s) completed</h6>
						</div>
						<div class="card-body" @click="taskView = false;agentView=true;statView=false;reAssignmentView=false;">

							<h5 class="card-title" >Agents</h5>
							<h6 v-if="agents" class="card-subtitle text-muted">{{agents.filter(agent=> agent.isLoggedIn).length}} agents online</h6>
							<br>
							<h6 v-if="agents" class="card-subtitle text-muted">{{agents.length}} agents total</h6>
							<h6 v-else class="card-subtitle text-muted">Loading agent info...</h6>

						</div>

						<div class="card-body" @click="taskView = false;agentView=false;statView=true;reAssignmentView=false;">

							<h5 class="card-title" >Agent Metrics</h5>
							<h6 v-if="(statsPerTask.length > 1) || (statsPerTask.length === 0)" class="card-subtitle text-muted">{{statsPerTask.length}} available stats</h6>
							<h6 v-if="statsPerTask.length===1" class="card-subtitle text-muted">{{statsPerTask.length}} available stat</h6>

						</div>

						

						<div class="card-body" @click="reAssignmentView=true;taskView = false;agentView=false;statView=false;">

							<h5 class="card-title" >Task Re Assignment</h5>
							<h6 class="card-subtitle text-muted">{{tasksInProgress.length}} task(s) reassignable</h6>

						</div>
					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="taskView === true">
						<div class="card mb-3" style="height:100%;background-color: transparent;">

							<div class="card-body">

								<b-tabs content-class="mt-3">
									<b-tab title="Tasks" :class="{active: taskTabSection === 'Tasks'}" @click="taskTabSection='Tasks'"/>
									<b-tab title="Audit" :class="{active: taskTabSection === 'Audit'}" @click="taskTabSection='Audit'"/>
								</b-tabs>

								<div v-if="taskTabSection==='Tasks'">
									<button class="btn btn-outline-success" style = "margin-bottom:10px;"><i class="fas fa-save fa-1x"  @click="saveAsCSV(tasksTableView, 'tasks')"></i></button>
									<button @click="refreshTable('tasks')" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-sync fa-1x"></i></button>
									<input v-if="toggleFilterTable">
									<input id="name" type="text" placeholder="Start typing to filter by keyword..." v-model="message" required autocomplete="off"  class="form-control" style= "width:50%;">

									<b-pagination
										style="background-color: white;"
										v-model="currentPage"
										:total-rows="tasksTableView.length"
										:per-page="20"
										align="fill"
										size="md"
										class="my-0"
									></b-pagination>

									<b-table
										striped hover
										id="table-transition-example"
										:items="tasksTableView"
										:fields="taskTableFields"
										:perPage="20"
										:current-page="currentPage"
										:tbody-transition-props="transProps"
										:filter="message"
										:filterIncludedFields="[]"
										sort-icon-right
										sticky-header="700px"
										style="overflow-y: scroll;"
									></b-table>
									<div class="card-footer text-muted">
										Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
									</div>
								</div>
								<LabellingAudit v-else-if="taskTabSection==='Audit'" />
							</div>
						</div>
					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="agentView === true">

						<!-- Agents View -->
						<AgentView/>

					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="statView === true">
						<div class="card mb-3" style="height:100%;">
							<h3 class="card-header">Statistics View</h3>
							<div class="card-body">

								<button class="btn btn-outline-success" style = "margin-bottom:10px;"  @click="saveAsCSV(statsPerTaskView, 'stats')"><i class="fas fa-save fa-1x"></i></button>
								<button @click="refreshTable('stats')" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-sync fa-1x"></i></button>

								<input id="name" type="text" placeholder="search" v-model="message" autocomplete="off" required  class="form-control">

								<b-pagination
									style="background-color: white;"
									v-model="currentPage"
									:total-rows="tasksTableView.length"
									:per-page="20"
									align="fill"
									size="md"
									class="my-0"
								></b-pagination>

								<b-table
									striped hover
									:items="statsPerTaskView"
									:fields="Object.keys(statsPerTaskView[0])"
									sort-icon-right
									:per-page="20"
									:current-page="currentPage"
									:filter="message"
									:filterIncludedFields="[]"
								>
								</b-table>

								<div class="card-footer text-muted">
									Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
								</div>

							</div>

						</div>
					</div>
					<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%" v-if="reAssignmentView === true">
						<div class="card mb-3" style="height:100%;background-color: transparent;">
							<div class="card-body">


								<button @click="refreshTable('tasks')" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-sync fa-1x"></i></button>
								<input v-if="toggleFilterTable">


								<b-table
									striped hover

									:items="tasksTableView2"
									:fields="['taskUuid','assignedUser', 'taskCreatedAt']"
									sortable
									filter="In Progress"
									selectable
									select-mode="single"
									@row-selected="reAssignmentSelect"
									sticky-header="700px"
									style="overflow-y: scroll;"
									sort-icon-right
								>

								</b-table>

								<div class="card-footer text-muted">
									Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
								</div>

							</div>

						</div>
						<div v-if="selectedPendingTask" class="card mb-3" style="height:100%;background-color: transparent;">
							<h3 class="card-header">Reassign</h3>
							<div class="card-body">

								<h5 class="card-title" >Choose an agent below</h5>
								<h6 class="card-subtitle text-muted">
									{{agents.length}} available agents
								</h6>
								<ul class="list-group list-group-flush" v-for="agent in agents" v-bind:key="agent">
									<li @click="reAssignTask(selectedPendingTask[0]['taskUuid'], agent['email'])" class="list-group-item">{{agent['email']}}</li>
								</ul>


							</div>

						</div>
					</div>
				</div>
				<!-- DATASETS SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%; width:fit-content;" v-if="currentSection === 'datasets'">
					<keep-alive>
						<DatasetManager></DatasetManager>
					</keep-alive>
				</div>
				<!-- BROADCAST SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="currentSection === 'broadcast'">
					<div style="display:flex;flex-direction:row">

						<input id="name" type="text" placeholder="message" v-model="message" required autofocus class="form-control" style="margin-right:10px;">
						<button class="btn btn-outline-success" @click="sendMessage(message)">send</button>
					</div>

				</div>
				<!-- SETTINGS SECTION -->
				<div class="row" style="display:flex; margin-left:0.01rem;flex-direction: row; height: 100%;" v-if="currentSection === 'settings'">
					<div class="card mb-3" style="height:100%;background-color: transparent;">
						<h3 class="card-header">Settings</h3>
						<div class="card-body">
							<b-table
								striped hover
								id="table-transition-example"
								:items="mySettingsTableView"
								:fields="['Property','Value']"
								selectable
								select-mode="single"
								@row-selected="settingsRowSelect"
								:filterIncludedFields="[]"
								sort-icon-right
							>
							</b-table>

							<div class="card-footer text-muted">
								Updated {{new Date(Date.now() - lastUpdate).getMinutes()}} minute(s) ago
							</div>

							<div v-if="selectedSetting"  class="card mb-3" style="height:100%;" id="sidebar">
								<div class="card-body">
									<h5 class="card-header">Edit {{selectedSetting['Property']}}</h5>
									<p>{{selectedSetting['Property']}}: <input style="width:80px;" id="name" type="text" :placeholder="selectedSetting['Value']" v-model="newSetting" required autocomplete="off" class="form-control"> </p>
									<button v-if="((newSetting!==''))&&(newSetting!==null)&&(parseInt(newSetting) !== parseInt(selectedSetting['Value']))" @click="editSettings(selectedSetting, newSetting)" class="btn btn-outline-success" style = "margin-left:10px;margin-bottom:10px;"><i class="fas fa-save fa-1x"></i></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>
