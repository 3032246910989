<template>
<div class = "notifications" style="position: fixed; background: black; display: flex; border: 1px solid white;border-radius:5px">
	<b-card class="card mb-3" style="background: black; ; " body-class="scroll-wrap">
		<template #header><code class="mb-0" style="font-size:20px;">Notifications</code></template>
		<div style="width: 800px">
			<b-card v-if="notifications.length===0"  class="card-container">
				<code>
					No notifications to display
				</code>
			</b-card>
			<div v-else v-for="(notification, index) in notifications" v-bind:key="index"  class="card-container">
				<b-card>
					<template #header id="statusHeader">
						<code style="color: white;">Message</code>
						<i class="far fa-times fa-1x" style="float:right; margin-left:10px; color: #E93820" @click="notifications=notifications.filter((r, index_)=> index_ !== index)"></i>
					</template>
					<code style="color: white;">
						{{notification.data}}
					</code>
				</b-card>
			</div>
		</div>
	</b-card>
</div>
</template>

<script>
import { BButton, BCard, BFormInput as BInput, BOverlay, BTable, VBTooltip } from 'bootstrap-vue'
import { dataAPI, dagsterEndpoint} from "../../http-common";
import 'vue-progress-path/dist/vue-progress-path.css';
import {Delay} from '@/utils/promises';
import VueProgress from 'vue-progress-path';
export default {
	name: 'Notifications',
	props: {
		type: {default:'', type: String}
	},
	components: {
		BButton,
		BCard,
		BInput,
		BOverlay,
		BTable,
		VueProgress,
	},
	data: () => ({
		dagRunStatuses: [],
		moreInfo: {},
		isRefreshing: false,
		refreshClass: '',
		notifications: [],
	}),
	computed: {

	},
	watch: {

	},
	methods: {

	},
	sockets: {
		broadcast(data){
			this.notifications.push(data);
		}
	}
}
</script>

<style scoped>
	.notifications {
		z-index:9999999;
		margin-top: 860px;
		position: absolute;
		display: flex;
		width:fit-content;
		height:800px;
		/* border: 1px solid red; */
	}

	.statusHeader:hover{
		background-color: white;
		opacity: 0.8;
	}

	.scroll-wrap {
		overflow-y: auto;
	}

	.card-container {
		margin-bottom: 10px;
	}

	.done {
		color: #93E9BE;
	}

	@keyframes dots {
		0%, 20% {
			display: none;
		}
		40% {
			color: rgb(156,57,125);
			text-shadow:
			.25em 0 0 rgb(156,57,125),
			.5em 0 0 rgb(156,57,125);
		}
		60% {
			text-shadow:
			.25em 0 0 rgb(156,57,125),
			.5em 0 0 rgb(156,57,125);
		}
		80%, 100% {
			text-shadow:
			.25em 0 0 rgb(156,57,125),
			.5em 0 0 rgb(156,57,125);
		}
	}
	.spinning{

		-webkit-animation-name: spin;
		-webkit-animation-duration: 4000ms;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		-moz-animation-name: spin;
		-moz-animation-duration: 4000ms;
		-moz-animation-iteration-count: infinite;
		-moz-animation-timing-function: linear;
		-ms-animation-name: spin;
		-ms-animation-duration: 4000ms;
		-ms-animation-iteration-count: infinite;
		-ms-animation-timing-function: linear;
		animation-name: spin;
		animation-duration: 4000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@-ms-keyframes spin {
		from { -ms-transform: rotate(0deg); }
		to { -ms-transform: rotate(360deg); }
	}
	@-moz-keyframes spin {
		from { -moz-transform: rotate(0deg); }
		to { -moz-transform: rotate(360deg); }
	}
	@-webkit-keyframes spin {
		from { -webkit-transform: rotate(0deg); }
		to { -webkit-transform: rotate(360deg); }
	}
	@keyframes spin {
		from {
			transform:rotate(0deg);
		}
		to {
			transform:rotate(360deg);
		}
	}
	.loading:after {
		content: '.';
		animation: dots 1s steps(5, end) infinite;
	}
</style>