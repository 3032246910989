/**
 * admin.store.js
 * Vuex module for admin-related data, e.g.
 * - User management
 */
import { dataAPI } from '@/http-common'
import Vue from 'vue'

export default {
	namespaced: true,

	state: () => ({
		lastAgentsUpdateTime: null,
		agents: [],
		agentPasswords: {}, // kept when refreshing agents or generating new
		agentAccuracies: {},

		agentTypeFormat: { // used to format agent types for display (tables, dropdowns, etc.)
			'reid': 'Re-ID',
			'consolidation': 'Consolidation',
			'review': 'Re-ID Q/A',
			'all_tasks': 'All Tasks',
			'admin': 'Admin'
		},
	}),

	getters: {
		FormatAgentType: (state) => (type) => state.agentTypeFormat[type] ?? 'Unknown',
	},

	mutations: {
		SetAgents(state, agents)
		{
			if (!(agents instanceof Array))
			{
				console.warn('Not updating agents. Invalid data type.')
				return;
			}

			state.agents = agents;
			state.lastAgentsUpdateTime = Date.now();
		},

		SetAgentPassword(state, {email, password})
		{
			Vue.set(state.agentPasswords, email, password);
		},

		SetAgentAccuracies(state, {email, data})
		{
			// Organise by type
			// TODO: we could do this programatically with a reduce
			const taskTypes = ['reid', 'consolidation', 'review'];

			const organisedData = Object.fromEntries(
				taskTypes.map(type => {
					const subset = data.filter(({taskType}) => taskType === type);
					const submissionCount = subset.length;
					const averageAccuracy = subset
						.map(entry => entry.singleAccuracyMetric)
						.reduce((a, b) => a + b, 0)
						/ (submissionCount || 1); // prevent divide by 0
					
					return [type, {
						submissionCount,
						averageAccuracy
					}];
				})
			); // e.g. {'reid': {submissionCount: 0, averageAccuracy: 0}}

			Vue.set(state.agentAccuracies, email, organisedData);
		}
	},

	actions: {
		/**
		 * RefreshAgents
		 * Retrieves agent data from database. 
		 */
		async RefreshAgents({commit})
		{
			try
			{
				console.log('Refreshing agents');
				const response = await dataAPI.get(`/getAgents`);
				commit('SetAgents', response.data.agents);
				return true;
			} catch(error)
			{
				console.error('Could not update agents:', error);
				return false;
			}
		},

		/**
		 * CreateAgent
		 * Pushes a request to create a new agent.
		 */
		async CreateAgent({dispatch}, agent)
		{
			try {
				await dataAPI.post('/users/register', agent);
				await dispatch('RefreshAgents');
				return true;
			} catch(error)
			{
				console.error('Could not create new agent:', error);
				return false;
			}
		},

		/**
		 * GenerateAgent
		 * Pushes a request to create (one) new random agent.
		 */
		async GenerateAgent({commit, dispatch}, {accountType})
		{
			try {
				const {data} = await dataAPI.post('/users/generateUser', {accountType});
				await dispatch('RefreshAgents');
				commit('SetAgentPassword', data.user)
				return true;
			} catch(error)
			{
				console.error('Could not generate new agent:', error);
				return false;
			}
		},

		/**
		 * GenerateAgents
		 * Pushes a request to create (multiple) new random agents.
		 */
		async GenerateAgents({commit, dispatch}, {count, accountType})
		{
			try {
				const {data} = await dataAPI.post('/users/generateUsers', {count, accountType});
				await dispatch('RefreshAgents');

				// Make all newly created users' passwords visible 
				data.users.forEach(user => {
					commit('SetAgentPassword', user);
				});
				console.log(`Generated ${count} new ${accountType} users.`);
				return true;
			} catch(error)
			{
				console.error('Could not generate new agents:', error);
				return false;
			}
		},

		/**
		 * DeleteAgent
		 * Removes an existing agent.
		 */
		async DeleteAgent({dispatch}, {email})
		{
			try {
				await dataAPI.post(`/users/unregister`, {email});
				await dispatch('RefreshAgents');
				return true;
			} catch(error)
			{
				console.error('Could not remove agent:', error);
				return false;
			}
		},

		/**
		 * UpdateAgentAccess
		 * Changes is_new status on user in order to allow/disallow production access.
		 */
		async UpdateAgentAccess({dispatch}, {email, isAllowed})
		{
			try {
				await dataAPI.post(`/users/updateAgentAccess`, {email, isAllowed});
				await dispatch('RefreshAgents');
				return true;
			} catch(error)
			{
				console.error('Could not update agent acess:', error);
				return false;
			}
		},
		/**
		 * ResetAgentPassword
		 * Resets an agent's password.
		 */
		async ResetAgentPassword({commit, dispatch}, {email})
		{
			try {
				const {data} = await dataAPI.post(`/users/resetPassword`, {email});
				await dispatch('RefreshAgents');
				commit('SetAgentPassword', data.user)
				return true;
			} catch(error)
			{
				console.error('Could not reset agent password:', error);
				return false;
			}
		},

		/**
		 * RefreshAgentAccuracies
		 * Refreshes agent accuracy metrics.
		 * TODO: change endpoint to use email instead of agent name?
		 */
		async RefreshAgentAccuracies({commit}, {email})
		{
			try {
				const user = email.split('@')[0];
				const {data} = await dataAPI.post(`accuracy/getSubmissionsForUser`, {user});
				commit('SetAgentAccuracies', {email, data});
				return true;
			} catch(error)
			{
				console.error('Could not refresh agent accuracy metrics:', error);
				return false;
			}
		}
	}
}