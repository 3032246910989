
<div style="display: contents;">
	<b-modal
		id="dataset-sampler-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		@ok="retrieveDatasetSample(datasetNameForSample, databaseNameForSample, samplingAmount, minMergeLength)"
		>	
		<template #modal-title>
			Dataset Sampler
		</template>
		
		<div>
			<h3>
				Sample Size
			</h3>
			<p>
				How many merge tracks would you like to retrieve?
			</p>
			<b-input type="number" v-model="samplingAmount" class="dataset-sampler-number-input"/>
		</div>

		<div>
			<h3>Length Filter (optional)</h3>
			<p>
				What is the minimum image length required for the sample? Leave blank if no filter needed
			</p>
			<b-input type="number" v-model="minMergeLength" class="dataset-sampler-number-input"/>
		</div>

		<template #modal-ok>
			Download as CSV
		</template>
	</b-modal>
	
	<b-modal
		id="qaPass-modal"
		centered
		no-close-on-backdrop
		no-stacking
		size="xl"
		ok-variant="info"
		@ok="updateQApass(selectedDatasetUuid, selectedQApass)"
		>	
		<template #modal-title>
			QA Pass
		</template>

		<div class="my-4">
			<b-form-select v-model="selectedQApass" :options="qaOptions"/>
		</div>

		<template #modal-ok>
			Accept
		</template>
	</b-modal>
	
	<div v-if="!minimised" style="display:flex; flex-direction: row; ">
		<b-card class="card mb-0" style="background-color:transparent;">
			<div>
				<ul class="nav nav-tabs" style="width:fit-content">
					<li class="nav-item" @click="focusedTab='activeDatasets'">
						<a class="nav-link" :class="{active: focusedTab === 'activeDatasets'}">Active</a>
					</li>
					<li class="nav-item" @click="focusedTab='allDatasets'">
						<a class="nav-link" :class="{active: focusedTab === 'allDatasets'}">All Datasets</a>
					</li>
					<li v-if="qaDatasets.length" class="nav-item" @click="focusedTab='qaDatasets'">
						<a class="nav-link" :class="{active: focusedTab === 'qaDatasets'}">QA Datasets</a>
					</li>
				</ul>
				<div v-if="focusedTab === 'activeDatasets' || focusedTab === 'allDatasets'" class="dataset-contents-container">
					<div v-for="dataset in filteredDatasets" v-bind:key="dataset.uuid" class="list-group" style="width:100%">
						<div @click="selectedDataset=dataset;selectedDatasetUuid=dataset.uuid" class="list-group-item">
							{{dataset.datasetDateString}}
							<div style="float:right">
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{dataset.datasetType}}</span>
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{dataset.datasetName}}</span>
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{(dataset.vendor.split('_')[1]) ?dataset.vendor.split('_')[1]:'test_db' }}</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-power-off fa-1x" :style="dataset.inJobQueue ? 'color: #93E9BE' : 'color: white'" @click="toggleDataset(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Job queue"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-download fa-1x" @click="openDatasetSampleModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Download sample"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-check-double fa-1x" @click="updateMarkedAsFinished(dataset)"
										:style="{
											color: dataset.markedAsFinished === 1 ? '#93E9BE' : 'grey'
										}"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Mark as complete"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-eye fa-1x"
										:style="{
										color: dataset.qaPass === 1 ? '#93E9BE' : (dataset.qaPass === 0 ? 'Crimson' : 'grey')
										}"
										:disabled="!dataset.markedAsFinished"
										@click="openQApassModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="QA"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-external-link-alt"
										@click="openDatasetViewer(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="View Dataset"
									>
									</i>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="focusedTab === 'qaDatasets'" class="dataset-contents-container">
					<div v-for="dataset in qaDatasets" v-bind:key="dataset.uuid" class="list-group" style="width:100%">
						<div @click="selectedDataset=dataset; selectedDatasetUuid=dataset.uuid" class="list-group-item">
							{{dataset.datasetDateString}}
							<div style="float:right">
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}" >{{dataset.datasetType}}</span>
								<span class="badge badge-pill" :class="{'active-dataset': dataset.inJobQueue, 'inactive-dataset': !dataset.inJobQueue}">{{dataset.datasetName}}</span>
								
								
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-download fa-1x" @click="openDatasetSampleModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Download sample"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white;margin-left:10px">
									<i class="fas fa-check-double fa-1x" @click="updateMarkedAsFinished(dataset)"
										:style="{
											color: dataset.markedAsFinished === 1 ? '#93E9BE' : 'grey'
										}"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="Mark as complete"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-eye fa-1x"
										:style="{
										color: dataset.qaPass === 1 ? '#93E9BE' : (dataset.qaPass === 0 ? 'Crimson' : 'grey')
										}"
										:disabled="!dataset.markedAsFinished"
										@click="openQApassModal(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="QA"
									>
									</i>
								</span>
								<span class="badge badge-pill" style="color: white; margin-left: 10px">
									<i class="fas fa-external-link-alt"
										@click="openDatasetViewer(dataset)"
										v-b-tooltip="{hover: true, boundary: 'viewport'}"
										title="View Dataset"
									>
									</i>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="list-group" style="width:300px">
					<div v-if="(datasets.filter(r=>(r.inJobQueue===1)).length===0)  && focusedTab==='activeDatasets'" class="list-group-item list-group-item-action">
						<div style="float:right">
							No datasets in visible to labellers
						</div>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</div>
