<template>
<!-- Use v-b-modal directive and assign an ID to this component to open it -->
<b-modal
	ref="modal"
	centered
	hide-footer
	no-close-on-backdrop
	no-stacking
	size="lg"
	:id="id"
	v-bind="$attrs"
	@shown="GetSuggestedMergeFeatures"
	@hidden="CancelPendingRequests"
>
	<!-- Title -->
	<template #modal-title>
		Move Track to Correct Merge
	</template>

	<!-- Given local track -->
	<div class="track-highlight mr-4" v-if="localTrack">
		<div class="frame-item">
			<img :src="localTrackThumbnail" :style="ClipCSS(localTrackSubframe)"/>
		</div>
	</div>

	<div class="mr-4">
		<i class="fas fa-arrow-alt-circle-right fa-3x"></i>
	</div>

	<!-- Tiles -->
	<b-overlay :show="loading" variant="transparent" :opacity="1" blur="1px" class="merge-options">
		<div
			v-for="feature of paginatedFeatures"
			:key="feature.mergeUuid"
			class="track-highlight"
		>
			<!-- Track image -->
			<div class="frame-item">
				<img
					:src="framePaths[feature.detectionUuid]"
					:style="ClipCSS(subFrames[feature.detectionUuid])"
				/>
			</div>

			<!-- Reassign button -->
			<b-button size="sm" variant="info" class="block-centered reassign" @click="Reassign(feature)">
				<i class="fas fa-plus"></i>
			</b-button>
		</div>

		<!-- New Merge button -->
		<div v-if="!loading" class="track-highlight new-merge">
			<div class="frame-item"/>
			<b-button
				v-b-tooltip.hover="`Assign to new merge`"
				size="sm" variant="info" class="block-centered reassign" @click="Reassign(/*to a new merge*/)"
			>
				<i class="fas fa-plus"></i>
			</b-button>
		</div>

		<b-pagination
			v-show="numPages > 1"
			v-model="currentPage"
			:per-page="entriesPerPage"
			:total-rows="suggestedFeatures.length + 1"
			align="center"
			class="my-1"
		/>
	</b-overlay>

</b-modal>
</template>


<script>
import { BButton, BModal, BOverlay, BPagination, VBTooltip } from 'bootstrap-vue';
import { CancelablePromise } from '@/utils/promises';
import { dataAPI } from '@/http-common';
import setClipCss from '@/utils/images.js';

export default {
	name: 'review-reassignment-modal',

	props: {
		id: {required: true},
		localTrack: {required: true},
		localTrackDetections: {required: true}, // Array< detectionUuid >
		subFrames: {type: Object, required: true},
		framePaths: {type: Object, required: true},
		databaseName: {required: true},
		datasetName: {required: true}
	},

	components: {
		BModal,
		BButton,
		BOverlay,
		BPagination
	},

	directives: {
		'b-tooltip': VBTooltip
	},

	data: () => ({
		isVisible: false,
		loading: false,
		suggestedFeatures: [],
		requestPromise: null,

		currentPage: 1,
		entriesPerPage: 20
	}),

	computed: {
		localTrackThumbnail()
		{
			return this.framePaths[this.localTrack?.detectionUuid ?? ''];
		},

		localTrackSubframe()
		{
			return this.subFrames[this.localTrack?.detectionUuid ?? ''];
		},

		paginatedFeatures()
		{
			const {entriesPerPage, currentPage} = this;
			const start = entriesPerPage * (currentPage - 1);
			const end = start + entriesPerPage;
			return this.suggestedFeatures.slice(start, end);
		},

		numPages()
		{
			// Add 1 to include "new" button
			return Math.ceil((this.suggestedFeatures.length + 1) / this.entriesPerPage);
		}
	},

	methods: {
		/**
		 * ClipCSS
		 * Produces image CSS clipping styles for provided subframe coords.
		 * @param coords  Subframe coordinates.
		 */
		ClipCSS(coords)
		{
			if (!coords)
				return '';
			else
				return setClipCss(coords, 1920); // TODO: move image size 1920 to config?
		},

		/**
		 * LoadFramesAndSubFrames
		 * Requests missing frames and adds to map.
		 * NOTE: this roughly combines `retrieveDetectionSubFramesForMerge`, `createSubFramesMap`
		 *  `retrieveFullFramesForMergeUuid`, and `createFramePathMap`.
		 * TODO: refactor this awkward data flow into shared Vuex state.
		 */
		async LoadFramesAndSubFrames(features)
		{
			const filteredDetectionUUIDs = features
				.filter(({detectionUuid, mergeUuid}) => !this.framePaths[detectionUuid] || !this.subFrames[detectionUuid])
				.map(({detectionUuid}) => detectionUuid); // keep only the one property

			if (filteredDetectionUUIDs.length === 0)
				return;

			const queryFormattedUUIDs = filteredDetectionUUIDs.map(UUID => `'${UUID}'`).toString();

			try
			{
				// Get detection framePaths
				const framePaths = (await dataAPI.post(`/${this.databaseName}/frame/get/framePaths`, {
					detectionUuids: filteredDetectionUUIDs,
					dataset: this.datasetName
				})).data;
				Object.entries(framePaths).forEach( ([detectionUuid, framePath]) =>
					this.$set(this.framePaths, detectionUuid, server.defaults.baseURL + framePath) );

				const subFrames = (await dataAPI.post(`/${this.databaseName}/preset/1`, {
					detections: queryFormattedUUIDs,
					reidInput: `reidInput_${this.datasetName}`
				})).data;
				subFrames
					.filter( subFrame => subFrame.label === 'person' && subFrame.textureLabel === 'rgb' )
					.forEach( subFrame => this.$set(this.subFrames, 
						subFrame.detectionUuid,
						{
							top: subFrame.minimumY,
							right: subFrame.maximumX,
							bottom: subFrame.maximumY,
							left: subFrame.minimumX
						}
					) );
			} catch(error)
			{
				console.error('Failed to update frames and subframes:', error);
			}
		},

		/**
		 * GetSuggestedMergeFeatures
		 * Requests nearest mergeTrack feature matches for the given localTrack.
		 * @returns Array< {detectionUuid:String, mergeUuid:String, distance:Number} >
		 */
		async GetSuggestedMergeFeatures()
		{
			if (this.loading)
			{
				console.warn('Attempted to request suggested merges while a request is running.');
				return;
			}

			this.loading = true;
			this.suggestedFeatures = [];

			try
			{
				this.requestPromise = new CancelablePromise(async (resolve) => {
					const functionName = `getSimilarMergeTracks${process.env.NODE_ENV === 'development' ? '' : ''}`;
					const url = `${this.databaseName}/feature/${this.datasetName}/${functionName}`
					const response = await dataAPI.post(url, {
						localTrackUuid: this.localTrack.localTrackUuid,
						detections: this.localTrackDetections
					});
					resolve(response.data);
				});
				const features = await this.requestPromise; // allows us to cancel
				this.suggestedFeatures = features.filter( ({mergeUuid}) => this.localTrack.mergeUuid !== mergeUuid );

				await this.LoadFramesAndSubFrames(features);
			}catch(error)
			{
				console.warn(error);
				this.requestPromise = null;
			}

			this.loading = false;
		},

		/**
		 * CancelPendingRequests
		 * Forces pending requests to reject, discarding their results.
		 */
		async CancelPendingRequests()
		{
			this.requestPromise?.cancel();
		},

		/**
		 * Reassign
		 * Moves localTrack to selected mergeTrack by merge UUID.
		 * Emits a 'reassign' event with object {oldMergeUuid, newMergeUuid} so other parts of the UI can be updated.
		 * Note: We likely wouldn't need to emit events if we handled track state via Vuex.
		 * @param {Object} {mergeUuid}  Destructures mergeUuid from a mergeTrack or feature object.
		 */
		async Reassign({mergeUuid}={})
		{
			this.loading = true;
			try 
			{
				const url = `${this.databaseName}/updateLocalTrackMergeUuid/`;
				const {data} = await dataAPI.post(url, {
					localTrackUuid: this.localTrack.localTrackUuid,
					mergeUuid
				});
				this.$emit('reassign', {oldMergeUuid: this.localTrack.mergeUuid, newMergeUuid: data.mergeUuid});
			} catch(error)
			{
				console.error('Failed to move localTrack to a different merge:', error);
			}

			this.loading = false;
			this.$refs.modal.hide();
		}
	}
}
</script>


<style scoped>
::v-deep .modal-body {
	display: flex;
	align-items: center;
}

.merge-options {
	flex: 1 0 0px;
	border: 2px dashed #fff3;
	height: 100%;
	min-height: 150px;
}

.track-highlight {
	display: inline-block;
	position: relative;
	margin-top: 2px;
	margin-bottom: 2px;

	border-style: solid;
	border-top-width: 3px;
	border-bottom-width: 3px;
	border-color: gray;

	background-color: lightgrey;
}
.track-highlight.new-merge {
	background-color: rgba(127,127,127,0.5);
}

.reassign {
	z-index: 9999;
	visibility: hidden;
}
.track-highlight:hover .reassign, .new-merge .reassign  {
	visibility: visible;
}

::v-deep .pagination:not(.disabled) .page-link {
	background: rgba(255,255,255,0.05)
}
</style>