
<div class="dataset-viewer-container">
	<b-modal
			id="gallery-modal"
			no-close-on-backdrop
			no-stacking
			size="xl"
			ok-variant="info"
			hide-footer
		>
			<template #modal-title>
				Similar Merge Tracks
			</template>

			<div class="search-results-container">
				<div class="search-results-pane-1">
					<div class="gallery-container">
						<p class="card-header">Target</p>
						<DetectionGallery
							v-if="mergeTrack && mergeTrack.length"
							:key="selectedMergeUuid"
							:imagesLoaded="true"
							:points="mergeTrack"
							:hasCroppedFrames="true"
							pointType="detectionUuid"
							:sortPoints="false"
							:pageSize="60"
							:mergeable="false"
							ref="targetGallery"
							size="xs"
							width="50vw"
							:selectable="true"
							:multiSelect="false"
							displayMode="fullframeGallery"
						/>
					</div>
					<div class="gallery-container">
						<p class="card-header" v-if="selectedSearchMergeTrack && selectedSearchMergeTrack.length">
							Viewing Merge: {{ selectedSearchMergeTrack[0].mergeUuid }}
						</p> 
						<DetectionGallery
							v-if="selectedSearchMergeTrack && selectedSearchMergeTrack.length"
							:imagesLoaded="true"
							:points="selectedSearchMergeTrack"
							:hasCroppedFrames="true"
							pointType="detectionUuid"
							:sortPoints="false"
							:pageSize="60"
							:mergeable="false"
							ref="targetGallery"
							size="xs"
							width="50vw"
							:selectable="true"
							:multiSelect="false"
							displayMode="fullframeGallery"
						/>
					</div>
				</div>
				<div class="search-results-pane-2">
					<p class="card-header">Similar</p>
					<DetectionGallery
						v-if="searchResults && searchResults.length"
						:imagesLoaded="true"
						:points="searchResults"
						:hasCroppedFrames="true"
						pointType="mergeUuid"
						:sortPoints="false"
						:pageSize="60"
						:mergeable="false"
						ref="resultsGallery"
						size="xs"
						width="100vw"
						:selectable="true"
						:multiSelect="false"
						@imageSelected="retrieveSearchMergeTrack($event.mergeUuid)"
					/>
				</div>
			</div>
	</b-modal>

	<div class="datasets-header-container">
		<div class="datasets-header">
			Datasets / {{ datasetDetails?.datasetName || 'Loading...' }}
		</div>
	</div>
	
	<div class="empty-datasets" v-if="mergeTracks.length==0 && !loadingData" >
		No merges available in this dataset
	</div>

	<div v-else>
		<div class="selected-merge-container">
			<b-form-input
				class="merge-search-input"
				placeholder="Search for a merge UUID"
				v-model="selectedMergeUuid"
			/>
			<div class="selected-merge-details-container">
				<div class="merge-track-information" v-if="selectedMergeUuid">
					<code class="code-white">
						Merge UUID: {{ selectedMergeUuid }}
					</code>
					<code class="code-white">
						Images: {{ mergeTrack ? mergeTrack.length : 0 }}
					</code>
					<code class="code-white" v-if="mergeTrack && mergeTrack[0] && mergeTrack[0].isStaff" >
						Type: Staff
					</code>
				</div>
				<div v-else class="no-merge-selected" >
					<code class="code-white">
						Search dataset for a mergeUUID to get started...
					</code>
				</div>
				<div class="controls-container">
						<b-button 
							@click="retrieveSimilarMerges(selectedMergeUuid)" 
							size="sm" 
							variant="outline-info"
							:disabled="!selectedMergeUuid"
						>
							<i class="fas fa-search fa-1x"/> Find Similar Tracks
						</b-button>
				</div>
			</div>
		</div>

		<div class="detection-gallery-container">
			<DetectionGallery
				v-if="mergeTrack && mergeTrack.length"
				:key="selectedMergeUuid"
				:imagesLoaded="true"
				:points="mergeTrack"
				:hasCroppedFrames="true"
				pointType="detectionUuid"
				:sortPoints="false"
				:pageSize="60"
				:mergeable="false"
				ref="gallery"
				size="xl"
				width="100%"
				:selectable="true"
				:multiSelect="false"
				displayMode="fullframeGallery"
				:datasetUUID="datasetUUID"
			/>
		</div>
	</div>
</div>
